<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <span class="font-bold text-lg">Erstelle Preis</span>
                <div class="mt-4">
                    <div>
                        <div>
                            <label
                                class="font-bold"
                                for="active_from"
                            >Gültig ab:</label>
                            <input
                                id="active_from"
                                v-model="form.active_from"
                                :class="[(!validations.active_from) ? 'border-red' : 'border-gray-200']"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                type="date"
                            >
                        </div>
                        <div v-if="!['equipment', 'rentEquipment'].includes(type)">
                            <div
                                v-if="extendedPrices"
                                class="mt-2"
                            >
                                <label
                                    class="font-bold"
                                    for="light_use"
                                >Transporttarif:</label>
                                <input
                                    id="light_use"
                                    v-model.number="form.light_use"
                                    :class="[(!validations.light_use) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                    step="0.01"
                                    type="number"
                                >
                            </div>
                            <div class="mt-2">
                                <div class="flex justify-between">
                                    <label
                                        class="font-bold"
                                        for="heavy_use"
                                    >Einsatztarif:</label>
                                    <button
                                        v-if="!extendedPrices"
                                        class="bg-red text-white rounded-md text-xs px-1 py-0.5"
                                        @click="form.light_use = form.heavy_use; extendedPrices = true"
                                    >
                                        Abweichender Transporttarif
                                    </button>
                                </div>
                                <input
                                    id="heavy_use"
                                    v-model.number="form.heavy_use"
                                    :class="[(!validations.heavy_use) ? 'border-red' : 'border-gray-200']"
                                    class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                    step="0.01"
                                    type="number"
                                >
                            </div>
                        </div>
                        <div v-else>
                            <label
                                class="font-bold"
                                for="price"
                            >Tarif:</label>
                            <input
                                id="price"
                                v-model.number="form.price"
                                :class="[(!validations.price) ? 'border-red' : 'border-gray-200']"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                step="0.01"
                                type="number"
                            >
                        </div>
                        <div class="text-sm italic">
                            <span class="font-bold">Hinweis:</span> Die Versicherung ist nicht im
                            Preis enthalten.
                        </div>
                    </div>
                    <div class="flex justify-center mt-4">
                        <spin-button
                            :loading="sendingRequest"
                            class="bg-red text-white w-52 h-12"
                            text="Preis erstellen"
                            @btnClick="sendRequest"
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { createRentEquipmentPrice, createRentPrice } from '@/js/services/rent.service';
import { createVehiclePrice } from '@/js/services/vehicle.service';
import { createEquipmentPrice } from '@/js/services/equipment.service';
import { isValidDate } from '@/js/services/helper.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';

const { DateTime } = require('luxon');

export default {
    components: {
        SpinButton,
    },
    props: {
        id: { // Used for vehicles, rents, equipment
            type: Number,
            required: true,
            default: () => -1,
        },
        rentEquipmentId: { // Additional id if rentEquipments are used
            type: Number,
            required: false,
            default: () => -1,
        },
        show: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        type: {
            type: String,
            required: true,
            validator: (value) => ['rent', 'vehicle', 'equipment', 'rentEquipment'].includes(value),
        },
    },
    data() {
        return {
            form: {
                light_use: 0,
                heavy_use: 0,
                price: 0,
                active_from: null,
            },
            validations: {
                light_use: true,
                heavy_use: true,
                active_from: true,
                price: true,
            },
            sendingRequest: false,
            extendedPrices: false,
        };
    },
    methods: {
        sendRequest() {
            if (!this.extendedPrices) this.form.light_use = this.form.heavy_use;

            this.validations.light_use = this.form.light_use !== 0;
            this.validations.heavy_use = this.form.heavy_use !== 0;
            this.validations.price = this.form.price !== 0;

            // Check if active_from is in the future (in case of a vehicle, the price might already be valid on the same day)
            this.validations.active_from = isValidDate(this.form.active_from, this.type !== 'vehicle');
            if ((this.type === 'vehicle') && this.validations.active_from) {
                const date = new Date(this.form.active_from);
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday.setHours(23, 59, 59, 599);

                this.validations.active_from = date.valueOf() > (yesterday.valueOf());
            }

            if (((!this.validations.light_use || !this.validations.heavy_use) && !['equipment', 'rentEquipment'].includes(this.type)) || (!this.validations.price && ['equipment', 'rentEquipment'].includes(this.type)) || !this.validations.active_from || this.sendingRequest) return;
            this.sendingRequest = true;

            const fnc = {
                rent: createRentPrice,
                vehicle: createVehiclePrice,
                equipment: createEquipmentPrice,
                rentEquipment: createRentEquipmentPrice,
            }[this.type];

            // Add additional rentEquipmentId if rentEquipment is the case
            const formattedForm = {
                light_use: this.form.light_use,
                heavy_use: this.form.heavy_use,
                price: this.form.price,
                active_from: DateTime.fromISO(this.form.active_from)
                    .toISO(), // add timezone
            };

            const args = (this.type === 'rentEquipment') ? [this.id, this.rentEquipmentId, formattedForm] : [this.id, formattedForm];

            fnc(...args)
                .then((result) => {
                    this.$emit('create', result);
                    this.$emit('close');
                    this.form = {
                        light_use: 0,
                        heavy_use: 0,
                        price: 0,
                        active_from: null,
                    };
                    this.sendingRequest = false;
                    this.extendedPrices = false;
                });
        },
    },
};
</script>
