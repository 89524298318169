<template>
    <div class="w-full">
        <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
            Lieferungen
        </p>
        <button
            v-show="isAdmin"
            class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
            @click="selectedDelivery = {}; showDeliveryCreationForm = true"
        >
            <div class="bg-red rounded">
                <img
                    class="w-8"
                    src="/img/icons/add.png"
                >
            </div>
            <span class="mx-4 font-bold text-sm">Lieferung hinzufügen</span>
        </button>
        <table
            v-show="data.length > 0"
            class="w-full xl:w-4/5 mx-auto text-center"
        >
            <tr>
                <th class="hidden sm:table-cell">
                    ID
                </th>
                <th class="w-1/2">
                    Datum
                </th>
                <th>Status</th>
                <th class="hidden sm:table-cell">
                    Preis
                </th>
                <th />
            </tr>
            <tr
                v-for="delivery in data"
                :key="delivery.id"
            >
                <td class="hidden sm:table-cell">
                    {{ delivery.id }}
                </td>
                <td>
                    {{
                        delivery.delivery_at === null ? 'Ungeplant' : formatDate(delivery.delivery_at)
                    }}
                </td>
                <td
                    :class="colorizeStatus(delivery.status, 'text')"
                    class="font-bold"
                >
                    <span class="flex h-3 w-3 sm:hidden m-auto">
                        <span
                            :class="colorizeStatus(delivery.status, 'bg')"
                            class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
                        />
                        <span
                            :class="colorizeStatus(delivery.status, 'bg')"
                            class="relative inline-flex rounded-full h-3 w-3"
                        />
                    </span>
                    <span class="ml-2 hidden sm:block">{{ localizeStatus(delivery.status) }}</span>
                </td>
                <td
                    class="hidden sm:table-cell"
                    v-html="formatPrice(delivery.price)"
                />
                <td class="flex flex-row justify-end w-32">
                    <button
                        class="ml-1 bg-red p-1 rounded focus:outline-none w-9 h-9 mr-2"
                        @click="selectedDelivery = delivery; showRentDeliveryForm = true"
                    >
                        <span class="text-xl font-bold text-white w-full">i</span>
                    </button>
                    <router-link
                        v-show="isAdmin"
                        :to="{ name: 'adminDeliveryDetail', params: {id: delivery.id}}"
                        class="ml-2"
                    >
                        <button class="bg-red p-1 rounded focus:outline-none">
                            <img
                                class="w-8"
                                src="/img/icons/pen.png"
                            >
                        </button>
                    </router-link>
                </td>
            </tr>
        </table>
        <div
            v-show="data.length === 0"
            class="font-bold text-center lg:text-left"
        >
            Derzeit sind keine Lieferungen vorhanden
        </div>
        <delivery-details
            :delivery="selectedDelivery"
            :show="showRentDeliveryForm"
            @close="selectedDelivery={}; showRentDeliveryForm = false"
        />
        <creation-form
            :rent-id="rentId"
            :show="showDeliveryCreationForm"
            :user="user"
            @close="showDeliveryCreationForm = false"
            @create="$emit('create', $event)"
        />
    </div>
</template>
<script>
import { formatDate, formatPrice } from '@/js/services/helper.service';
import { colorizeStatus, localizeStatus } from '@/js/services/delivery.service';
import DeliveryDetails from '@/js/components/forms/delivery/DeliveryDetails.vue';
import CreationForm from '@/js/components/forms/delivery/CreationForm.vue';

export default {
    name: 'DeliveryTable',
    components: {
        DeliveryDetails,
        CreationForm,
    },
    props: {
        rentId: {
            type: Number,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
        isAdmin: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            showRentDeliveryForm: false,
            showDeliveryCreationForm: false,
            selectedDelivery: {},
        };
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        formatPrice(value) {
            return formatPrice(value);
        },
        colorizeStatus(status, prefix) {
            return colorizeStatus(status, prefix);
        },
        localizeStatus(string) {
            return localizeStatus(string);
        },
    },
};
</script>
