import axios from 'axios';
import { refresh } from '@/js/services/auth.service';
import store from './store';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/oauth2/auth`;

const redirect = async (error) => {
    await store.dispatch('auth/forgetToken');

    // Forward to login
    window.location.href = BASE_URL;

    return new Promise((resolve, reject) => {
        reject(error);
    });
};

export default () => {
    axios.interceptors.response.use((response) => response, // Return response if successful
        async (error) => {
            // Token invalid
            if (error.config.url.includes('/oauth2/token') && (error.response.status === 400)) redirect(error);

            // Other error than 401
            if (error.response.status !== 401) return Promise.reject(error);

            // Get new token and try again
            return refresh()
                .then(() => {
                    const { config } = error;
                    config.headers.Authorization = `Bearer ${store.getters['auth/getAccessToken']}`;

                    return new Promise((resolve, reject) => {
                        axios.request(config)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((iError) => {
                                reject(iError);
                            });
                    });
                })
                .catch(() => {
                    redirect(error);
                });
        });

    // Set initial token
    axios.defaults.headers.common.Authorization = `Bearer ${store.getters['auth/getAccessToken']}`;
};
