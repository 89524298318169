<template>
    <div>
        <loading-spinner v-if="loading" />
        <transition name="fade">
            <div
                v-if="!loading"
                class="flex flex-col"
            >
                <Vehicle
                    :end="endDate"
                    :hours="hours"
                    :price-per-hour="pricePerHour"
                    :price-per-week="pricePerWeek"
                    :start="startDate"
                    :vehicle="vehicle"
                    class="mx-8 lg:mx-32"
                />
                <EquipmentSelection
                    :available-equipment="availableEquipment"
                    :hours="hours"
                    class="bg-gray-100 px-8 md:px-32 py-14 flex flex-col"
                    @update="updateSelEquipment"
                />
                <InsuranceSelection
                    :hours="hours"
                    :insurances="insurances"
                    class="px-8 md:px-32 py-14"
                    @update="updateSelInsurance"
                />
                <DeliveryTypeSelection
                    class="bg-gray-100 px-8 md:px-32 py-14"
                    @update="updateSelDeliveryType"
                />
                <div
                    v-if="coupon !== null"
                    class="px-8 md:px-32 pt-14"
                >
                    <p class="text-red font-bold text-xl text-center lg:text-left">
                        Gutschein ({{ coupon.code }}) <button
                            class="text-bold"
                            @click="coupon = null"
                            v-html="'&times'"
                        />
                    </p>
                    <div class="flex flex-col sm:flex-row justify-between text-center sm:text-left">
                        <div>
                            <p>
                                Ihr Gutschein beträgt <b v-html="coupon.type === 'HOURS' ? `${coupon.value} Stunden` : formatPrice(coupon.value)" />.
                            </p>
                            <p
                                v-show="coupon.valid_until !== null"
                                class="italic"
                            >
                                Gültig bis: {{ formatDate(coupon.valid_until) }}
                            </p>
                        </div>
                        <p
                            class="text-center sm:text-right sm:mr-10 font-bold text-xl"
                            v-html="formatPrice(couponValue * -1)"
                        />
                    </div>
                </div>
                <div class="px-8 md:px-32 pt-14">
                    <p class="text-red font-bold text-xl text-center lg:text-left">
                        Gesamtpreis
                    </p>
                    <div class="flex flex-col sm:flex-row justify-between">
                        <div class="flex flex-col justify-between">
                            <p class="hidden sm:block mb-1">
                                Ihr <span class="font-bold">geschätzter</span> Mietpreis beträgt:
                            </p>
                            <div
                                v-show="coupon === null"
                                class="flex flex-col items-center sm:items-start"
                            >
                                <p>Haben Sie einen <span class="font-bold">Gutschein?</span></p>
                                <coupon-input
                                    class="mt-1"
                                    :coupon="coupon"
                                    @fetch="couponFetched"
                                />
                            </div>
                            <p class="sm:hidden text-center mb-1 mt-4">
                                Ihr <span class="font-bold">geschätzter</span> Mietpreis beträgt:
                            </p>
                        </div>
                        <p class="text-center sm:text-right sm:mr-10">
                            <span
                                class="text-xl font-bold"
                                v-html="formatPrice(estimatedNeededBudget)"
                            /><br>
                            <span>exkl. MwSt.</span><br><br>
                            <span
                                class="text-red text-2xl font-bold"
                                v-html="formatPrice(estimatedNeededBudgetTax)"
                            /><br>
                            <span>inkl. MwSt.</span>
                        </p>
                    </div>
                    <div class="lg:w-1/2 m-auto 2xl:w-2/5">
                        <hr class="mt-4">
                        <p class="italic text-center text-sm">
                            Bitte beachten Sie, dass dieser Preis auf Basis von <span
                                class="font-bold"
                            >{{ hours }}</span> Stunden pro Woche berechnet wurde. Der tatsächliche
                            Preis hängt von der individuellen Nutzung ab.
                        </p>
                    </div>
                </div>
                <div class="py-14">
                    <div class="px-0 lg:px-32 flex justify-center lg:justify-end">
                        <router-link
                            class="py-3 px-5 bg-red text-white text-base font-roboto"
                            to="/"
                        >
                            zurück
                        </router-link>
                        <spin-button
                            :loading="sendingRequest"
                            class="ml-3 py-3 px-5 bg-red text-white text-base w-52 h-12"
                            text="Anfrage senden"
                            @btnClick="sendRequest"
                        />
                    </div>
                    <div
                        v-if="limitReached"
                        class="font-bold text-red mt-4 px-0 lg:px-32 text-right"
                    >
                        Maximale Anzahl an Mietanfragen erreicht.
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { getVehicle, getVehicleEquipment } from '@/js/services/vehicle.service';
import { createRent } from '@/js/services/rent.service';
import Vehicle from '@/js/components/page/selection/Vehicle.vue';
import InsuranceSelection from '@/js/components/page/selection/InsuranceSelection.vue';
import DeliveryTypeSelection from '@/js/components/page/selection/DeliveryTypeSelection.vue';
import { formatPrice, isValidDate, formatDate } from '@/js/services/helper.service';
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import { getInsurances } from '@/js/services/insurance.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import EquipmentSelection from '@/js/components/page/selection/EquipmentSelection.vue';
import CouponInput from '@/js/components/forms/CouponInput.vue';

const { DateTime } = require('luxon');

export default {
    name: 'Selection',
    components: {
        CouponInput,
        Vehicle,
        InsuranceSelection,
        LoadingSpinner,
        SpinButton,
        EquipmentSelection,
        DeliveryTypeSelection,
    },
    data() {
        return {
            insurances: [],
            vehicle: {
                rent_price: [{
                    heavy_use: 0,
                }],
            },
            availableEquipment: [],
            selectedEquipment: [],
            selectedInsurance: {
                price: 0,
            },
            selectedDeliveryType: {
                price: 0,
            },
            loading: true,
            sendingRequest: false,
            limitReached: false,
            coupon: null,
        };
    },
    computed: {
        startDate() {
            return new Date(this.$route.query.start);
        },
        endDate() {
            return new Date(this.$route.query.end);
        },
        hours() {
            return parseInt(this.$route.query.hours, 10);
        },
        pricePerHour() {
            return this.vehicle.rent_price[0].heavy_use;
        },
        pricePerWeek() {
            return this.pricePerHour * this.hours;
        },
        estimatedNeededBudget() {
            const durationInDays = this.durationInDays(this.startDate, this.endDate);
            const durationInWeeks = Math.ceil(durationInDays + 1) / 7;

            let totalPrice = 0;
            for (let week = 0; week < durationInWeeks; week++) {
                totalPrice += this.requiredWeekBudget(week);
            }

            totalPrice += this.selectedDeliveryType.price * 2;

            totalPrice -= this.couponValue;

            return Math.max(totalPrice, 0);
        },
        estimatedNeededBudgetTax() {
            return this.estimatedNeededBudget * 1.2;
        },
        couponValue() {
            if (this.coupon === null) return 0;
            if (this.coupon.type === 'BUDGET') return this.coupon.value;

            return this.coupon.value * (this.pricePerHour + this.selectedInsurance.price);
        },
    },
    mounted() {
        // Check if needed params are passed
        const vehicleId = parseInt(this.$route.params.id, 10);
        let error = false;

        if (vehicleId != this.$route.params.id) error = true; // eslint-disable-line eqeqeq

        const keys = Object.keys(this.$route.query);
        if (!keys.includes('start') || !keys.includes('end') || !keys.includes('hours')) error = true;

        // Redirect
        if (error) this.$router.push({ name: 'search' });

        // Get vehicle data
        const vehiclePromise = getVehicle(vehicleId);
        const insurancePromise = getInsurances();
        const equipmentPromise = getVehicleEquipment(vehicleId, {
            'rent[start]': this.startDate,
            'rent[end]': this.endDate,
        });

        Promise.all([vehiclePromise, insurancePromise, equipmentPromise])
            .then((result) => {
                [this.vehicle, this.insurances, this.availableEquipment] = result;
                this.loading = false;
            })
            .catch(() => {
                this.$router.push({ name: 'search' });
            });
    },
    methods: {
        durationInDays(date1, date2) {
            const MS_PER_DAY = 1000 * 3600 * 24;
            return (date2.getTime() - date1.getTime()) / MS_PER_DAY;
        },
        sendRequest() {
            if (this.loading || this.sendingRequest) return;

            if (this.$store.getters['user/isLoggedIn']) {
                this.sendingRequest = true;

                const body = {
                    vehicle_id: this.vehicle.id,
                    active_from: DateTime.fromJSDate(this.startDate)
                        .startOf('day')
                        .toISO(), // add timezone and startOfDay
                    active_to: DateTime.fromJSDate(this.endDate)
                        .endOf('day')
                        .toISO(), // add timezone and endOfDay
                    estimated_hours: this.hours,
                    insurance_id: this.selectedInsurance.id,
                    delivery_type: this.selectedDeliveryType.id,
                    total_price: this.estimatedNeededBudgetTax,
                };
                if (this.coupon !== null) {
                    body.code = this.coupon.code;
                }

                // User is logged in, send request
                createRent(body, this.selectedEquipment)
                    .then(() => {
                        this.$store.dispatch('rents/fetchRents')
                            .then(() => {
                                this.$router.push({
                                    name: 'dashboard',
                                });
                            });
                    })
                    .catch((error) => {
                        if (error.response.data.error.includes('You may have max')) {
                            this.limitReached = true;
                        } else {
                            this.sendingRequest = false;
                        }
                    });
            } else {
                // User is not logged in. Store request
                const request = {
                    rent: {
                        vehicle_id: this.vehicle.id,
                        estimated_hours: this.hours,
                        start_date: this.startDate,
                        end_date: this.endDate,
                        total_price: this.estimatedNeededBudgetTax,
                        delivery_type: this.selectedDeliveryType,
                        insurance_id: this.selectedInsurance.id,
                    },
                    equipment: this.selectedEquipment,
                    code: null,
                };

                if (this.coupon !== null) {
                    request.coupon = {
                        code: this.coupon.code,
                        value: this.coupon.value,
                        type: this.coupon.type,
                    };
                } else {
                    request.coupon = null;
                }

                localStorage.setItem('unsent_request', JSON.stringify(request));

                // Redirect to dashboard (user is auto redirected to SSO)
                this.$router.push({
                    name: 'dashboard',
                })
                    .catch(() => {
                    });
            }
        },
        updateSelEquipment(equipment) {
            this.selectedEquipment = equipment;
        },
        updateSelInsurance(insurance) {
            this.selectedInsurance = insurance;
        },
        updateSelDeliveryType(deliveryType) {
            this.selectedDeliveryType = deliveryType;
        },
        formatPrice(price) {
            return formatPrice(price);
        },
        formatDate(date) {
            return formatDate(date);
        },
        isValidDate(date) {
            return isValidDate(date, true);
        },
        requiredWeekBudget(week) {
            const startDateCopy = new Date(this.startDate);
            let weekStart = new Date(startDateCopy.setDate(startDateCopy.getDate() + (7 * week)));
            weekStart = new Date(weekStart.setHours(0, 0, 0, 0));

            if (this.endDate < weekStart) return 0;

            const durationInDays = this.durationInDays(weekStart, this.endDate) + 1;
            let { pricePerHour } = this;

            // if rent is long enough, check if equipments are assigned
            if (durationInDays > 7) {
                this.selectedEquipment.forEach((equipment) => {
                    pricePerHour += equipment.rent_price.price;
                });
            }

            pricePerHour += this.selectedInsurance.price;

            return (durationInDays <= 7 ? 10 : this.hours) * pricePerHour;
        },
        couponFetched(coupon) {
            this.coupon = coupon;
        },
    },
};
</script>
