<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <span
                    class="font-bold text-lg"
                >{{
                    isCreationForm ? 'Erstelle Anbaugerät' : `Bearbeite Anbaugerät ${equipment.id}`
                }}</span>
                <div class="mt-4">
                    <div>
                        <div>
                            <label
                                class="font-bold"
                                for="equipmentname"
                            >Name:</label>
                            <input
                                id="equipmentname"
                                v-model="form.name"
                                :class="[(!validations.name) ? 'border-red' : 'border-gray-200']"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                placeholder="Seitenschneepflug SRS-2L"
                                type="text"
                            >
                            <span
                                v-show="!validations.name"
                                class="font-bold text-red"
                            >Der Name darf nicht leer sein!</span>
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="equipmentbrand"
                            >Marke:</label>
                            <input
                                id="equipmentbrand"
                                v-model="form.details.brand"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white border-gray-200"
                                placeholder="Hauer"
                                type="text"
                            >
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="equipmentproduction_year"
                            >Produktionsjahr:</label>
                            <input
                                id="equipmentproduction_year"
                                v-model.number="form.production_year"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white border-gray-200"
                                placeholder="2000"
                                type="number"
                            >
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="equipmentserial_id"
                            >Seriennr.:</label>
                            <input
                                id="equipmentserial_id"
                                v-model="form.serial_id"
                                :class="[(!validations.serial_id) ? 'border-red' : 'border-gray-200']"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                placeholder="2000-001-100"
                                type="text"
                            >
                            <span
                                v-show="form.serial_id.length === 0"
                                class="font-bold text-red"
                            >Die Seriennummer darf nicht leer sein!</span>
                            <span
                                v-show="!validations.serial_id"
                                class="font-bold text-red"
                            >Die Seriennummer ist bereits vergeben.</span>
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="equipmentimage"
                            >Bild:</label>
                            <input
                                id="equipmentimage"
                                v-model="form.details.image"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white border-gray-200"
                                placeholder="Bild"
                                type="text"
                            >
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="equipmentproductpage"
                            >Produktseite:</label>
                            <input
                                id="equipmentproductpage"
                                v-model="form.details.product_page"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white border-gray-200"
                                placeholder="Produktseite"
                                type="text"
                            >
                        </div>
                    </div>
                    <div class="flex justify-center mt-4">
                        <spin-button
                            :loading="sendingRequest"
                            :text="isCreationForm ? 'Anbaugerät erstellen' : 'Änderung speichern'"
                            class="bg-red text-white w-52 h-12"
                            @btnClick="sendRequest"
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { createEquipment, updateEquipment } from '@/js/services/equipment.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';

export default {
    components: {
        SpinButton,
    },
    props: {
        equipment: {
            type: Object,
            default: () => {
            },
        },
        show: {
            type: Boolean,
            required: true,
            default: () => false,
        },
    },
    data() {
        return {
            form: {
                name: '',
                production_year: 2000,
                serial_id: '',
                details: {
                    product_page: '',
                    image: '',
                    brand: '',
                },
            },
            oldForm: {},
            validations: {
                name: true,
                serial_id: true,
            },
            sendingRequest: false,
        };
    },
    computed: {
        isCreationForm() {
            return Object.keys(this.equipment).length === 0;
        },
    },
    watch: {
        equipment: {
            immediate: false,
            handler(value) {
                if (!this.isCreationForm) {
                    this.form = {
                        name: value.name,
                        production_year: value.production_year,
                        serial_id: value.serial_id,
                        details: {
                            product_page: value.details.product_page,
                            image: value.details.image,
                            brand: value.details.brand,
                        },
                    };
                    this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy
                }
            },
        },
    },
    methods: {
        sendRequest() {
            this.validations.name = this.form.name !== '';
            this.validations.serial_id = true;

            if (!this.validations.name || !this.validations.serial_id || this.form.serial_id === '') return;

            if (this.sendingRequest) return;
            this.sendingRequest = true;

            let fnc;

            if (this.isCreationForm) {
                this.form.brand = this.form.details.brand; // POST request is flat
                fnc = createEquipment(this.form)
                    .then((result) => {
                        this.$emit('create', result);
                        this.$emit('close');
                    });
            } else {
                if (JSON.stringify(this.form) === JSON.stringify(this.oldForm)) {
                    this.$emit('close');
                    return;
                }
                this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy

                fnc = updateEquipment(this.equipment.id, this.form)
                    .then((result) => {
                        Object.keys(this.validations)
                            .forEach((key) => {
                                this.validations[key] = true;
                            });
                        this.sendingRequest = false;
                        this.$emit('update', result);
                        this.$emit('close');
                    });
            }
            fnc.catch((error) => {
                const errors = error.response.data.error;
                if (errors) {
                    if (errors.serial_id) {
                        this.validations.serial_id = false;
                    }
                }
                this.sendingRequest = false;
            });
        },
    },
};
</script>
