<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <span
                    class="font-bold text-lg"
                >Gutschein einlösen</span>
                <div class="mt-4">
                    <div>
                        <p class="mb-2">
                            Ihr Gutschein wird bei der nächsten Abrechnung berücksichtigt.
                        </p>
                        <div class="flex justify-center items-center">
                            <coupon-input
                                input-classes="w-48"
                                :test="false"
                                :user-id="userId"
                                :rent-id="rentId"
                                @fetch="fetchedCoupon"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import CouponInput from '@/js/components/forms/CouponInput.vue';

export default {
    components: {
        CouponInput,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        userId: {
            type: Number,
            default: -1,
        },
        rentId: {
            type: Number,
            default: -1,
        },
    },
    methods: {
        fetchedCoupon(coupon) {
            this.$emit('fetch', coupon);
            this.$emit('close');
        },
    },
};
</script>
