<template>
    <div
        class="flex flex-row cursor-pointer"
    >
        <div
            :class="{'bg-opacity-50': page === 1}"
            class="bg-red text-white w-10 h-10 flex items-center justify-center rounded-l-md"
            @click="updateValue(page-1)"
        >
            <svg
                class="bi bi-caret-left-fill"
                fill="currentColor"
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"
                />
            </svg>
        </div>
        <div
            v-show="((page - 5) * showPerPage) >= 0"
            class="border border-red w-10 h-10 flex items-center justify-center"
            @click="updateValue(page-4)"
        >
            {{ page - 4 }}
        </div>
        <div
            v-show="((page - 4) * showPerPage) >= 0"
            class="border border-red w-10 h-10 flex items-center justify-center"
            @click="updateValue(page-3)"
        >
            {{ page - 3 }}
        </div>
        <div
            v-show="((page - 3) * showPerPage) >= 0"
            class="border border-red w-10 h-10 flex items-center justify-center"
            @click="updateValue(page-2)"
        >
            {{ page - 2 }}
        </div>
        <div
            v-show="((page - 2) * showPerPage) >= 0"
            class="border border-red w-10 h-10 flex items-center justify-center"
            @click="updateValue(page-1)"
        >
            {{ page - 1 }}
        </div>
        <div
            checked
            class="bg-red text-white w-10 h-10 flex items-center justify-center"
        >
            {{ page }}
        </div>
        <div
            v-show="(page * showPerPage) < data.length"
            class="border border-red w-10 h-10 flex items-center justify-center"
            @click="updateValue(page+1)"
        >
            {{ page + 1 }}
        </div>
        <div
            v-show="((page + 1) * showPerPage) < data.length"
            class="border border-red w-10 h-10 flex items-center justify-center"
            @click="updateValue(page+2)"
        >
            {{ page + 2 }}
        </div>
        <div
            v-show="((page + 2) * showPerPage) < data.length"
            class="border border-red w-10 h-10 flex items-center justify-center"
            @click="updateValue(page+3)"
        >
            {{ page + 3 }}
        </div>
        <div
            v-show="((page + 3) * showPerPage) < data.length"
            class="border border-red w-10 h-10 flex items-center justify-center"
            @click="updateValue(page+4)"
        >
            {{ page + 4 }}
        </div>
        <div
            :class="{'bg-opacity-50': (page * showPerPage) >= data.length}"
            class="bg-red text-white w-10 h-10 flex items-center justify-center rounded-r-md"
            @click="updateValue(page+1)"
        >
            <svg
                class="bi bi-caret-right-fill"
                fill="currentColor"
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
                />
            </svg>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => [],
            required: true,
        },
        showPerPage: {
            type: Number,
            default: 0,
            required: true,
        },
    },
    data() {
        return {
            page: 1,
        };
    },
    watch: {
        data: {
            handler() {
                // Update pagination if either data changes or component initializes
                this.updateValue(1);
            },
            immediate: true,
        },
    },
    methods: {
        updateValue(newPage) {
            if ((newPage < 1)) return; // underflow
            if (((newPage - 1) * this.showPerPage) >= this.data.length) return; // overflow

            // Update page for local usage
            this.page = newPage;

            this.$emit('update', {
                page: this.page,
                lowerIndex: (this.page - 1) * this.showPerPage,
                upperIndex: Math.min(this.page * this.showPerPage, this.data.length),
            });
        },
    },
};
</script>
