<template>
    <div class="mt-4">
        <div class="mb-4">
            <label
                class="font-bold"
                for="delivery_type"
            >Art der Lieferung</label>
            <div
                class="border-gray-200 dropdown inline-block relative h-full border-2 w-full rounded-md"
            >
                <button
                    class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center justify-between w-full"
                    type="button"
                >
                    <span class="mr-1">{{ delivery_types[delivery_type_copy].type }}</span>
                    <svg
                        class="fill-current h-4 w-4"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                    </svg>
                </button>
                <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 w-full">
                    <li
                        v-for="(delivery_type,index) in delivery_types"
                        :key="index"
                    >
                        <a
                            :class="{'rounded-t': (index === 0), 'rounded-b': (index === (delivery_types.length-1))}"
                            class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap w-full"
                            @click="delivery_type_copy = index"
                        >{{ delivery_types[index].type }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="mb-4">
            <div class="w-full mb-4">
                <div class="flex justify-between mb-2">
                    <span class="text-red font-bold">Startadresse</span>
                    <button
                        v-show="!delivery_types[delivery_type_copy].from_lindner && !isDeliveryFromUserAddress"
                        class="text-white bg-red py-0.5 px-2 text-xs font-roboto"
                        @click="setUserAddress('delivery_from')"
                    >
                        Kundenadresse einfügen
                    </button>
                </div>
                <lindner-address-selection
                    v-if="delivery_types[delivery_type_copy].from_lindner"
                    @update="setAddress('delivery-from', $event)"
                />
                <div v-show="!delivery_types[delivery_type_copy].from_lindner">
                    <div>
                        <label
                            class="font-bold"
                            for="delivery_from_street"
                        >Straße</label>
                        <input
                            id="delivery_from_street"
                            v-model="delivery_from_copy.street"
                            :class="[delivery_from_copy.street.length === 0 ? 'border-red' : 'border-gray-200']"
                            class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                            placeholder="Ingenieur-Hermann-Lindner-Straße 4"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="delivery_from_city"
                        >Stadt</label>
                        <input
                            id="delivery_from_city"
                            v-model="delivery_from_copy.city"
                            :class="[delivery_from_copy.city.length === 0 ? 'border-red' : 'border-gray-200']"
                            class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                            placeholder="Kundl"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="delivery_from_zip"
                        >PLZ</label>
                        <input
                            id="delivery_from_zip"
                            v-model="delivery_from_copy.zip"
                            :class="[delivery_from_copy.zip.length === 0 ? 'border-red' : 'border-gray-200']"
                            class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                            placeholder="6250"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="delivery_from_country"
                        >Land</label>
                        <input
                            id="delivery_from_country"
                            v-model="delivery_from_copy.country"
                            :class="[delivery_from_copy.country.length === 0 ? 'border-red' : 'border-gray-200']"
                            class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                            placeholder="AT"
                            readonly
                            type="text"
                        >
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex justify-between mb-2">
                    <span class="text-red font-bold">Zieladresse</span>
                    <button
                        v-show="!delivery_types[delivery_type_copy].to_lindner && !isDeliveryToUserAddress"
                        class="text-white bg-red py-0.5 px-2 text-xs font-roboto"
                        @click="setUserAddress('delivery_to')"
                    >
                        Kundenadresse einfügen
                    </button>
                </div>
                <lindner-address-selection
                    v-if="delivery_types[delivery_type_copy].to_lindner"
                    @update="setAddress('delivery-to', $event)"
                />
                <div v-show="!delivery_types[delivery_type_copy].to_lindner">
                    <div>
                        <label
                            class="font-bold"
                            for="delivery_to_street"
                        >Straße</label>
                        <input
                            id="delivery_to_street"
                            v-model="delivery_to_copy.street"
                            :class="[delivery_to_copy.street.length === 0 ? 'border-red' : 'border-gray-200']"
                            class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                            placeholder="Ingenieur-Hermann-Lindner-Straße 4"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="delivery_to_city"
                        >Stadt</label>
                        <input
                            id="delivery_to_city"
                            v-model="delivery_to_copy.city"
                            :class="[delivery_to_copy.city.length === 0 ? 'border-red' : 'border-gray-200']"
                            class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                            placeholder="Kundl"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="delivery_to_zip"
                        >PLZ</label>
                        <input
                            id="delivery_to_zip"
                            v-model="delivery_to_copy.zip"
                            :class="[delivery_to_copy.zip.length === 0 ? 'border-red' : 'border-gray-200']"
                            class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                            placeholder="6250"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="delivery_to_country"
                        >Land</label>
                        <input
                            id="delivery_to_country"
                            v-model="delivery_to_copy.country"
                            :class="[delivery_to_copy.country.length === 0 ? 'border-red' : 'border-gray-200']"
                            class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                            placeholder="AT"
                            readonly
                            type="text"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LindnerAddressSelection from './LindnerAddressSelection.vue';

export default {
    components: {
        LindnerAddressSelection,
    },
    props: {
        deliveryFrom: {
            type: Object,
            required: true,
            default: () => {
            },
        },
        deliveryTo: {
            type: Object,
            required: true,
            default: () => {
            },
        },
        user: {
            type: Object,
            required: true,
            deafult: () => {
            },
        },
        deliveryType: {
            type: Number,
            required: true,
            default: () => {
            },
        },
    },
    data() {
        return {
            delivery_from_copy: {
                city: '',
                country: 'AT',
                street: '',
                zip: '',
            },
            delivery_to_copy: {
                city: '',
                country: 'AT',
                street: '',
                zip: '',
            },
            delivery_type_copy: 0,
            delivery_types: [
                {
                    type: 'Abholung',
                    from_lindner: true,
                    to_lindner: true,
                },
                {
                    type: 'Abholung (Retour)',
                    from_lindner: true,
                    to_lindner: true,
                },
                {
                    type: 'Lindner -> Händler',
                    from_lindner: true,
                    to_lindner: false,
                },
                {
                    type: 'Händler -> Lindner',
                    from_lindner: false,
                    to_lindner: true,
                },
                {
                    type: 'Lindner -> Kunde',
                    from_lindner: true,
                    to_lindner: false,
                },
                {
                    type: 'Kunde -> Lindner',
                    from_lindner: false,
                    to_lindner: true,
                },
            ],
        };
    },
    computed: {
        isDeliveryToUserAddress() {
            return this.delivery_to_copy.city === this.user.city && this.delivery_to_copy.country === this.user.country && this.delivery_to_copy.street === this.user.street && this.delivery_to_copy.zip === this.user.zip;
        },
        isDeliveryFromUserAddress() {
            return this.delivery_from_copy.city === this.user.city && this.delivery_from_copy.country === this.user.country && this.delivery_from_copy.street === this.user.street && this.delivery_from_copy.zip === this.user.zip;
        },
    },
    watch: {
        deliveryFrom: {
            deep: true,
            immediate: true,
            handler(val) {
                this.delivery_from_copy = val;
                this.$emit('delivery-from-update', val);
            },
        },
        deliveryTo: {
            deep: true,
            immediate: true,
            handler(val) {
                this.delivery_to_copy = val;
                this.$emit('delivery-to-update', val);
            },
        },
        deliveryType: {
            immediate: true,
            handler(val) {
                this.delivery_type_copy = val;
                this.$emit('delivery-type-update', val);
            },
        },
        delivery_from_copy: {
            deep: true,
            handler(val) {
                this.$emit('delivery-from-update', val);
            },
        },
        delivery_to_copy: {
            deep: true,
            handler(val) {
                this.$emit('delivery-to-update', val);
            },
        },
        delivery_type_copy: {
            deep: true,
            handler(val) {
                this.$emit('delivery-type-update', val);
            },
        },
    },
    methods: {
        setAddress(type, address) {
            this.$emit(`${type}-update`, address);
        },
        setUserAddress(type) {
            this[`${type}_copy`] = {
                city: this.user.city,
                country: this.user.country,
                street: this.user.street,
                zip: this.user.zip,
            };
        },
    },
};
</script>
