<template>
    <div>
        <div class="px-2 lg:px-32 py-14">
            <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                Kompatible Anbaugeräte
            </p>
            <div
                v-show="data.length === 0"
                class="font-bold text-center lg:text-left mb-4"
            >
                Derzeit sind keine Anbaugeräte kompatibel
            </div>
            <div class="flex justify-center lg:justify-start">
                <button
                    class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
                    @click="showForm = true"
                >
                    <div class="bg-red rounded">
                        <img
                            class="w-8"
                            src="/img/icons/add.png"
                        >
                    </div>
                    <span class="mx-4 font-bold text-sm">Anbaugerät hinzufügen</span>
                </button>
            </div>
            <table
                v-show="data.length > 0"
                class="w-full text-center"
            >
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th class="hidden lg:table-cell">
                        Seriennr.
                    </th>
                    <th class="hidden sm:table-cell">
                        Aktiv
                    </th>
                    <th />
                </tr>
                <tr
                    v-for="equipment in data"
                    :key="equipment.id"
                    class="hover:bg-red hover:bg-opacity-10"
                >
                    <td>{{ equipment.id }}</td>
                    <td>{{ equipment.name }}</td>
                    <td class="hidden lg:table-cell">
                        {{ equipment.serial_id }}
                    </td>
                    <td class="hidden sm:table-cell">
                        <span class="flex h-3 w-3 m-auto">
                            <span
                                :class="(equipment.deleted_at === null) ? 'bg-green' : 'bg-red'"
                                class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
                            />
                            <span
                                :class="(equipment.deleted_at === null) ? 'bg-green' : 'bg-red'"
                                class="relative inline-flex rounded-full h-3 w-3"
                            />
                        </span>
                    </td>
                    <td>
                        <router-link
                            :to="{ name: 'adminEquipmentDetail', params: {id: equipment.id}}"
                        >
                            <button class="bg-red p-1 rounded focus:outline-none mr-1">
                                <img
                                    class="w-7 h-7"
                                    src="/img/icons/pen.png"
                                >
                            </button>
                        </router-link>

                        <button
                            class="ml-1 bg-red p-1 rounded focus:outline-none"
                            @click="removeVehicleEquipment(equipment.id)"
                        >
                            <svg
                                class="fill-current text-white w-7 h-7"
                                version="1.0"
                                viewBox="0 0 64 64"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    stroke="none"
                                    transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                                >
                                    <path
                                        d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                    />
                                </g>
                            </svg>
                        </button>
                    </td>
                </tr>
            </table>
            <vehicle-equipment-form
                :show="showForm"
                :vehicle-id="vehicleId"
                @close="showForm = false"
                @create="$emit('create',$event)"
            />
        </div>
    </div>
</template>

<script>
import VehicleEquipmentForm from '@/js/components/forms/equipment/VehicleEquipmentForm.vue';
import { deleteVehicleEquipment } from '@/js/services/vehicle.service';

export default {
    name: 'VehicleEquipmentTable',
    components: {
        VehicleEquipmentForm,
    },
    props: {
        vehicleId: {
            type: Number,
            required: true,
            default: () => -1,
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showForm: false,
        };
    },
    methods: {
        removeVehicleEquipment(equipmentId) {
            deleteVehicleEquipment(this.vehicleId, equipmentId)
                .then(() => {
                    this.$emit('delete', equipmentId);
                });
        },
    },
};
</script>
