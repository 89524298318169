export const user = {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        /**
         * Returns stored user
         * @param {object} state
         * @returns {object} user
         */
        getUser(state) {
            return state.user;
        },
        /**
         * Returns if user is logged in
         * @param {object} state
         * @returns {boolean} isLoggedIn
         */
        isLoggedIn(state) {
            return state.user !== null;
        },
        isAdmin(state, getters) {
            if (!getters.isLoggedIn) return false;
            return state.user.role === 'Admin';
        },
    },
    actions: {
        /**
         * Updates stored user and initiates re-fetch
         * @param commit
         * @param dispatch
         * @param {object} userData
         */
        async setUser({
            commit,
            dispatch,
        }, userData) {
            // Set user
            commit('setUser', userData);

            // Fetch rents
            await dispatch('rents/fetchRents', null, { root: true });
        },
        /**
         * Removes user and associated data
         */
        forgetUser({ commit }) {
            commit('setUser', null);
            commit('rents/setRents', [], { root: true });
        },
    },
    mutations: {
        /**
         * Updates stored user
         * @param state
         * @param {object} userData
         */
        setUser(state, userData) {
            state.user = userData;
        },
        /**
         * Adds coupon to user
         * @param state
         * @param coupon
         */
        addCoupon(state, coupon) {
            state.user.active_coupons.push(coupon);
        },
    },
};
