<template>
    <div class="h-screen">
        <!-- TODO: ADD loading text -->
        <loading-spinner />
    </div>
</template>
<script>
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import { getCurrentUser } from '@/js/services/auth.service';

export default {
    name: 'Auth',
    components: {
        LoadingSpinner,
    },
    mounted() {
        const url = localStorage.getItem('entry_url');
        if (this.$store.getters['user/isLoggedIn']) {
            this.$router.push((url === null) ? { name: 'dashboard' } : { path: url })
                .catch(() => {
                });
        } else {
            getCurrentUser()
                .then((user) => {
                    this.$store.dispatch('user/setUser', user)
                        .then(() => {
                            this.$router.push((url === null) ? { name: 'dashboard' } : { path: url })
                                .catch(() => {
                                });
                        });
                });
        }
    },
};
</script>
