<template>
    <div>
        <request-confirmation v-if="unsent_request !== null" />
        <div class="flex flex-col mt-0 lg:mt-16">
            <div
                class="px-8 md:px-32 py-14 flex lg:justify-between lg:mr-40 flex-col lg:flex-row m-auto lg:m-0"
            >
                <p class="font-bold text-xl xl:text-3xl text-center lg:text-left">
                    Willkommen, {{ user.firstname + ' ' + user.lastname }}!<br>
                </p>
            </div>
            <div class="bg-gray-100 px-2 sm:px-8 md:px-32 py-14 flex flex-col">
                <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                    Ihre Mietflotte im Überblick:
                </p>
                <div class="m-auto lg:m-0 mb-4">
                    <router-link to="/">
                        <button
                            class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded"
                        >
                            <div class="bg-red rounded">
                                <img
                                    class="w-8"
                                    src="/img/icons/add.png"
                                >
                            </div>
                            <span class="mx-4 font-bold text-sm">Neue Mietanfrage erstellen</span>
                        </button>
                    </router-link>
                </div>
                <rent-table
                    v-show="rents.length > 0"
                    :data="rents"
                    class="mt-4"
                />
            </div>
        </div>
    </div>
</template>
<script>
import RequestConfirmation from '@/js/components/page/RequestConfirmation.vue';
import RentTable from '@/js/components/page/RentTable.vue';

export default {
    name: 'Dashboard',
    components: {
        RequestConfirmation,
        RentTable,
    },
    data() {
        return {
            unsent_request: localStorage.getItem('unsent_request'),
        };
    },
    computed: {
        rents() {
            return this.$store.getters['rents/getRents'];
        },
        user() {
            return this.$store.getters['user/getUser'];
        },
    },
};
</script>
