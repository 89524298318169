<template>
    <div>
        <div class="bg-red flex justify-around sm:justify-between items-center sm:flex-row flex-col">
            <router-link to="/">
                <img
                    class="w-auto h-12 px-3 my-2 sm:my-0"
                    src="/img/lindner_logo.png"
                >
            </router-link>
            <div class="items-center flex flex-row mb-2 sm:mb-0">
                <a
                    v-show="isLoggedIn"
                    :href="logoutUrl"
                    class="text-white font-bold px-3 sm:px-0 sm:mx-8 items-center inline-flex"
                >Abmelden <svg
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml-2"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 2.625H32.25V15.375H30V4.875H2.25V43.125H30V32.625H32.25V45.375H0V2.625ZM37.5102 14.9207L47.4149 24L37.5102 33.0793L35.9898 31.4207L42.8578 25.125H11.25V22.875H42.8578L35.9898 16.5793L37.5102 14.9207Z"
                        fill="#fff"
                    />
                </svg>
                </a>
                <div class="bg-gray-500 float-right sm:block hidden">
                    <router-link to="/dashboard">
                        <img
                            class="w-auto h-16"
                            src="/img/flotte_logo.png"
                        >
                    </router-link>
                </div>
            </div>
        </div>
        <div
            v-if="isAdmin"
            class="bg-gray-200 px-4"
        >
            <router-link :to="{name: 'adminUsers'}">
                Nutzerverwaltung
            </router-link>
            <router-link :to="{name: 'adminRents'}">
                Mietverwaltung
            </router-link>
            <router-link :to="{name: 'adminVehicles'}">
                Fahrzeugverwaltung
            </router-link>
            <router-link :to="{name: 'adminEquipment'}">
                Anbaugerätverwaltung
            </router-link>
            <router-link :to="{name: 'adminDeliveries'}">
                Lieferungverwaltung
            </router-link>
            <router-link :to="{name: 'adminCoupons'}">
                Gutscheinverwaltung
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NavBar',
    computed: {
        isAdmin() {
            return this.$store.getters['user/isAdmin'];
        },
        isLoggedIn() {
            return this.$store.getters['user/isLoggedIn'];
        },
        logoutUrl() {
            return `${process.env.MIX_VUE_API_BASE}/oauth2/logout`;
        },
    },
};
</script>
