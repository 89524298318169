<template>
    <div class="h-screen">
        <!-- TODO: ADD redirect text -->
        <loading-spinner />
    </div>
</template>
<script>
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';

export default {
    name: 'AuthCallback',
    components: {
        LoadingSpinner,
    },
    mounted() {
        this.$store.dispatch('auth/forgetToken');

        this.$router.push({ name: 'search' });
    },
};
</script>
