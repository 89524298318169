<template>
    <div class="h-screen">
        <!-- TODO: ADD redirect text -->
        <loading-spinner />
    </div>
</template>
<script>
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import { callback } from '@/js/services/auth.service';

export default {
    name: 'AuthCallback',
    components: {
        LoadingSpinner,
    },
    mounted() {
        if (!Object.keys(this.$route.query)
            .includes('code')) {
            this.$router.push({ name: 'search' });
        }

        callback(this.$route.query.code)
            .then(() => {
                this.$router.push({ name: 'auth' })
                    .catch(() => {
                    });
            });
    },
};
</script>
