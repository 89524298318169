<template>
    <div>
        <loading-spinner v-if="loading" />
        <transition name="fade">
            <div
                v-if="!loading"
                class="striped"
            >
                <div class="px-8 lg:px-32 py-14 flex justify-center">
                    <table>
                        <tr>
                            <td class="font-bold">
                                Liefernummer:
                            </td>
                            <td class="text-right">
                                {{ delivery.id }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Mietnummer:
                            </td>
                            <td class="text-right">
                                {{ delivery.rent_id }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Status:
                            </td>
                            <td class="text-right">
                                {{ localizeStatus(delivery.status) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Lieferung am:
                            </td>
                            <td class="text-right">
                                {{
                                    delivery.delivery_at ? formatDate(delivery.delivery_at) : 'Ungeplant'
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Effektives Lieferdatum:
                            </td>
                            <td class="text-right">
                                {{
                                    delivery.deliveried_at ? formatDate(delivery.deliveried_at) : 'Ausstehend'
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Preis:
                            </td>
                            <td
                                class="text-right"
                                v-html="formatPrice(delivery.price)"
                            />
                        </tr>
                        <tr v-show="delivery.description !== null">
                            <td class="max-w-2xl">
                                <span class="font-bold">Beschreibung:</span><br>
                                {{ delivery.description }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="px-8 lg:px-32 py-14 flex flex-col">
                    <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                        Kunde
                    </p>
                    <div class="flex justify-between items-center w-full xl:w-4/5 mx-auto">
                        <div class="text-center hidden sm:block">
                            <span class="font-bold">ID</span><br>
                            {{ user.id }}
                        </div>
                        <div class="text-center hidden sm:block">
                            <span class="font-bold">M3 Kundennummer</span><br>
                            {{ user.m3_user_id || 'Nicht zugewiesen' }}
                        </div>
                        <div class="text-center font-bold sm:font-normal">
                            <span class="font-bold hidden sm:block">Name<br></span>
                            {{ user.firstname + ' ' + user.lastname }}
                        </div>
                        <div class="text-center hidden md:block">
                            <span class="font-bold">E-Mail</span><br>
                            {{ user.email }}
                        </div>
                        <router-link :to="{ name: 'adminUserDetail', params: {id: user.id}}">
                            <button class="bg-red p-1 rounded focus:outline-none">
                                <img
                                    class="w-8"
                                    src="/img/icons/pen.png"
                                >
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="px-8 lg:px-32 py-14">
                    <div
                        class="flex flex-col sm:flex-row justify-between items-center w-full sm:w-2/3 xl:w-1/2 3xl:w-1/3 mx-auto mb-8 text-center"
                    >
                        <div class="mb-8 sm:mb-0">
                            <p class="text-red font-bold text-xl mb-4">
                                Startadresse
                            </p>
                            {{ delivery.delivery_from.street }}<br>
                            {{ delivery.delivery_from.zip }} {{ delivery.delivery_from.city }}<br>
                            {{ localizeCountryCode(delivery.delivery_from.country) }}
                        </div>
                        <div>
                            <p class="text-red font-bold text-xl mb-4">
                                Zieladresse
                            </p>
                            {{ delivery.delivery_to.street }}<br>
                            {{ delivery.delivery_to.zip }} {{ delivery.delivery_to.city }}<br>
                            {{ localizeCountryCode(delivery.delivery_to.country) }}
                        </div>
                    </div>
                    <div
                        v-show="delivery.status < 2"
                        class="w-full flex justify-center"
                    >
                        <button
                            class="bg-red text-white px-6 py-2 text-lg"
                            @click="showAddressForm = true"
                        >
                            Adressen bearbeiten
                        </button>
                    </div>
                </div>
                <div class="px-8 lg:px-32 py-14">
                    <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                        Lieferpositionen
                    </p>
                    <delivery-position-table
                        :delivery-id="delivery.id"
                        :equipment="equipment"
                        :rent-id="delivery.rent_id"
                        :vehicles="vehicles"
                        @equipmentCreate="equipmentCreated"
                        @equipmentDelete="equipmentDeleted"
                        @vehicleCreate="vehicleCreated"
                        @vehicleDelete="vehicleDeleted"
                    />
                </div>
                <div class="px-8 lg:px-32 py-14 flex justify-center">
                    <button
                        class="bg-red text-white px-6 py-2 text-lg"
                        @click="showForm = true"
                    >
                        Lieferung bearbeiten
                    </button>
                    <spin-button
                        v-show="delivery.status === 0"
                        :loading="sendingDeleteRequest"
                        class="bg-gray-400 text-white w-52 h-12 sm:ml-2"
                        text="Lieferung löschen"
                        @btnClick="setDeleted"
                    />
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div
                v-if="showForm"
                class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
                @click="showForm = false"
            >
                <div
                    class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                    @click.stop=""
                >
                    <div class="relative">
                        <svg
                            class="absolute right-0 top-0 cursor-pointer"
                            height="24"
                            version="1.0"
                            viewBox="0 0 64 64"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                            @click="showForm = false"
                        >
                            <g
                                fill="#000000"
                                stroke="none"
                                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            >
                                <path
                                    d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                />
                            </g>
                        </svg>
                    </div>
                    <span class="font-bold text-lg">Bearbeite Lieferung {{ delivery.id }}</span>
                    <div class="mt-4">
                        <div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="status"
                                >Status</label>
                                <div
                                    v-show="availableStatus.length === 0"
                                    class="h-full border-2 w-full rounded-md text-gray-700 py-2 px-4 bg-gray-100"
                                >
                                    {{ localizeStatus(delivery.status) }}
                                </div>
                                <div
                                    v-show="availableStatus.length > 0"
                                    class="dropdown inline-block relative h-full border-2 w-full rounded-md"
                                >
                                    <button
                                        class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center justify-between w-full"
                                        type="button"
                                    >
                                        <span class="mr-1">{{ localizeStatus(form.status) }}</span>
                                        <svg
                                            class="fill-current h-4 w-4"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 w-full">
                                        <li
                                            v-for="(status,index) in availableStatus"
                                            :key="index"
                                        >
                                            <a
                                                :class="{'rounded-t': (index === 0), 'rounded-b': (index === (availableStatus.length-1))}"
                                                class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap w-full"
                                                @click="form.status = status"
                                            >{{ localizeStatus(status) }}</a>
                                        </li>
                                    </ul>
                                </div>
                                <span
                                    v-show="(delivery.status === 0) && (delivery.delivery_at === null)"
                                    class="text-red font-bold"
                                >Derzeit ist kein Lieferdatum zugewiesen</span>
                            </div>
                            <div v-show="delivery.status === 0">
                                <label
                                    class="font-bold"
                                    for="delivery_at"
                                >Lieferung am</label>
                                <v-date-picker
                                    v-model="form.delivery_at"
                                    :min-date="today.plus({ days: 1})"
                                    class="inline-block h-full w-full"
                                >
                                    <template #default="{ inputValue, togglePopover }">
                                        <div class="flex items-center">
                                            <input
                                                :placeholder="today.setLocale('de-DE').toFormat('D')"
                                                :value="inputValue"
                                                class="w-full p-3 border-2 rounded-md mt-1/2 my-1 bg-white"
                                                readonly
                                                @click="togglePopover"
                                            >
                                        </div>
                                    </template>
                                </v-date-picker>
                            </div>
                            <div>
                                <label
                                    class="font-bold"
                                    for="description"
                                >Beschreibung</label>
                                <input
                                    id="description"
                                    v-model="form.description"
                                    class="w-full p-2 border-2 rounded-md my-1"
                                    type="text"
                                >
                            </div>
                            <div v-show="delivery.status === 0">
                                <label
                                    class="font-bold"
                                    for="price"
                                >Preis</label>
                                <input
                                    id="price"
                                    v-model="form.price"
                                    class="w-full p-2 border-2 rounded-md my-1 border-gray-200"
                                    min="0"
                                    placeholder="0"
                                    type="number"
                                >
                            </div>
                        </div>
                        <div class="flex justify-center mt-4">
                            <spin-button
                                :loading="sendingRequest"
                                class="bg-red text-white w-52 h-12"
                                text="Änderung speichern"
                                @btnClick="sendRequest"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <update-address-form
            :delivery="delivery"
            :show="showAddressForm"
            :user="user"
            @close="showAddressForm = false"
            @update="delivery = $event"
        />
    </div>
</template>
<script>
import {
    colorizeStatus,
    deleteDelivery,
    deleteDeliveryEquipment,
    deleteDeliveryVehicle,
    getDelivery,
    getDeliveryEquipment,
    getDeliveryVehicles,
    localizeStatus,
    updateDelivery,
} from '@/js/services/delivery.service';
import { getUser } from '@/js/services/user.service';
import { formatDate, formatPrice } from '@/js/services/helper.service';
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import DeliveryPositionTable from '@/js/components/page/delivery/DeliveryPositionTable.vue';
import countries from 'i18n-iso-countries';
import UpdateAddressForm from '@/js/components/forms/delivery/UpdateAddressForm.vue';
import { DateTime } from 'luxon';

export default {
    name: 'DeliveryDetail',
    components: {
        LoadingSpinner,
        SpinButton,
        DeliveryPositionTable,
        UpdateAddressForm,
    },
    data() {
        return {
            loading: true,
            sendingRequest: false,
            sendingDeleteRequest: false,
            showForm: false,
            showAddressForm: false,
            delivery: {},
            user: {},
            vehicles: [],
            equipment: [],
            oldForm: {},
            form: {
                status: 0,
                delivery_at: null,
                description: null,
                price: 0,
            },
        };
    },
    computed: {
        availableStatus() {
            if (this.delivery.status === 0) {
                if (this.delivery.delivery_at === null) return [];
                return [0, 1];
            }
            if (this.delivery.status === 1) return [1, 2, 4];
            if (this.delivery.status === 2) return [2, 3];
            if (this.delivery.status === 3) return [];
            return [];
        },
        deliveryId() {
            return parseInt(this.$route.params.id, 10);
        },
        today() {
            return DateTime.now();
        },
    },
    mounted() {
        // Check if needed params are passed
        if (this.deliveryId != this.$route.params.id) this.$router.push({ name: 'dashboard' }); // eslint-disable-line eqeqeq

        const deliveryPromise = getDelivery(this.deliveryId, { opt_fields: ['user'] })
            .then((delivery) => {
                this.form = {
                    status: delivery.status,
                    description: delivery.description,
                    delivery_at: DateTime.fromISO(delivery.delivery_at)
                        .toISODate(), // apply timezone
                    price: delivery.price,
                };
                this.oldForm = { ...this.form };

                const userPromise = getUser(delivery.user.id);
                const vehiclePromise = getDeliveryVehicles(delivery.id);
                const equipmentPromise = getDeliveryEquipment(delivery.id);

                return Promise.all([userPromise, vehiclePromise, equipmentPromise])
                    .then((result) => {
                        [this.user, this.vehicles, this.equipment] = result;

                        return delivery;
                    });
            });

        Promise.all([deliveryPromise])
            .then((result) => {
                [this.delivery] = result;
                this.loading = false;
            })
            .catch(() => {
                this.$router.push({ name: 'dashboard' });
            });
    },
    methods: {
        formatPrice(value) {
            return formatPrice(value);
        },
        localizeStatus(status) {
            return localizeStatus(status);
        },
        formatDate(date) {
            return formatDate(date);
        },
        colorizeStatus(status, prefix) {
            return colorizeStatus(status, prefix);
        },
        sendRequest() {
            if (JSON.stringify(this.form) === JSON.stringify(this.oldForm)) {
                this.showForm = false;
                return;
            }

            if (!this.showForm || this.sendingRequest) return;

            this.oldForm = { ...this.form };
            this.sendingRequest = true;

            updateDelivery(this.delivery.id, this.oldForm)
                .then((result) => {
                    this.delivery = result;

                    this.showForm = false;
                    this.sendingRequest = false;
                })
                .catch(() => {
                    this.sendingRequest = false;
                });
        },
        vehicleDeleted(id) {
            deleteDeliveryVehicle(this.delivery.id, id);
            this.vehicles = this.vehicles.filter((entry) => entry.id !== id);
        },
        equipmentDeleted(id) {
            deleteDeliveryEquipment(this.delivery.id, id);
            this.equipment = this.equipment.filter((entry) => entry.id !== id);
        },
        setDeleted() {
            if (this.sendingDeleteRequest) return;
            this.sendingDeleteRequest = true;
            deleteDelivery(this.delivery.id)
                .then(() => {
                    this.$router.push({ name: 'dashboard' });
                });
        },
        localizeCountryCode(code) {
            return countries.getName(code, 'de', { select: 'official' });
        },
        vehicleCreated(vehicle) {
            this.vehicles.push(vehicle);
        },
        equipmentCreated(equipment) {
            this.equipment.push(equipment);
        },
    },
};
</script>
