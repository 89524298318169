import axios from 'axios';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/insurances`;

/**
 * Fetches insurances from api
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getInsurances() {
    return axios.get(`${BASE_URL}`)
        .then((response) => response.data.data);
}

/**
 * Fetches insurance by id
 * @param {number} insuranceId
 * @returns {Promise} API Request
 */
export function getInsurance(insuranceId) {
    return axios.get(`${BASE_URL}/${insuranceId}`)
        .then((response) => response.data.data);
}
