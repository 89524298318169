<template>
    <div>
        <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
            Mietrabatte
        </p>
        <button
            v-if="rent.status >= 3 && rent.status <= 5"
            class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
            @click="showForm = true"
        >
            <div class="bg-red rounded">
                <img
                    class="w-8"
                    src="/img/icons/add.png"
                >
            </div>
            <span class="mx-4 font-bold text-sm">Rabatt hinzufügen</span>
        </button>
        <table
            v-show="data.length > 0"
            class="w-full xl:w-4/5 mx-auto text-center"
        >
            <tr>
                <th>ID</th>
                <th class="hidden md:table-cell">
                    Grund
                </th>
                <th>Betrag</th>
                <th>Datum</th>
            </tr>
            <tr
                v-for="discount in data"
                :key="discount.id"
            >
                <td>
                    {{ discount.id }}
                </td>
                <td class="hidden md:table-cell">
                    {{ discount.reason || 'Kein Grund angegeben' }}
                </td>
                <td v-html="formatPrice(discount.value)" />
                <td>
                    {{ formatDate(discount.created_at) }}
                </td>
            </tr>
        </table>
        <div
            v-show="data.length === 0"
            class="font-bold text-center lg:text-left"
        >
            Derzeit sind keine Rabatte / Gutschriften vorhanden
        </div>
        <rent-discount-form
            :rent-id="rent.id"
            :show="showForm"
            :tax-rate="rent.tax_rate"
            @close="showForm = false"
            @create="$emit('create',$event)"
        />
    </div>
</template>
<script>
import { formatDate, formatPrice } from '@/js/services/helper.service';
import RentDiscountForm from '@/js/components/forms/RentDiscountForm.vue';

export default {
    name: 'DiscountTable',
    components: {
        RentDiscountForm,
    },
    props: {
        rent: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showForm: false,
        };
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        formatPrice(value) {
            return formatPrice(value);
        },
    },
};
</script>
