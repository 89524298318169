import axios from 'axios';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/coupons`;

/**
 * Fetches coupons from database
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getAllCoupons(params = {}) {
    return axios.get(`${BASE_URL}`, { params })
        .then((response) => response.data.data);
}

/**
 * Fetches coupon by id
 * @param {number} couponId
 * @returns {Promise} API Request
 */
export function getCoupon(couponId) {
    return axios.get(`${BASE_URL}/${couponId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes a coupon
 * @param {number} couponId
 * @returns {Promise} API Request
 */
export function deleteCoupon(couponId) {
    return axios.delete(`${BASE_URL}/${couponId}`)
        .then((response) => response.data.data);
}

/**
 * Updates a coupon by id
 * @param {number} couponId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function updateCoupon(couponId, body = {}) {
    return axios.patch(`${BASE_URL}/${couponId}`, body)
        .then((response) => response.data.data);
}

/**
 * Creates a coupon by id
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function createCoupon(body) {
    return axios.post(`${BASE_URL}`, body)
        .then((response) => response.data.data);
}

/**
 * Checks if coupon is redeemable
 * @param code
 * @returns {Promise} API Request
 */
export function checkCouponAvailability(code) {
    return axios.get(`${BASE_URL}/redeem?code=${code}`)
        .then((response) => response.data.data);
}

/**
 * Redeems a coupon by code
 * @param {String} code
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function redeemCoupon(code, body = {}) {
    return axios.post(`${BASE_URL}/redeem?code=${code}`, body)
        .then((response) => response.data.data);
}
