<template>
    <table class="w-full text-center">
        <tr>
            <th>FIN</th>
            <th>Fahrzeug</th>
            <th class="hidden lg:table-cell">
                Kennzeichen
            </th>
            <th class="hidden lg:table-cell">
                Projektnummer
            </th>
            <th class="hidden lg:table-cell">
                Aktiv
            </th>
            <th />
        </tr>
        <tr
            v-for="vehicle in data"
            :key="vehicle.id"
            class="hover:bg-red hover:bg-opacity-10"
        >
            <td>{{ vehicle.vin }}</td>
            <td>{{ vehicle.name }}</td>
            <td class="hidden lg:table-cell">
                {{ vehicle.plate || 'Nicht zugewiesen' }}
            </td>
            <td class="hidden lg:table-cell">
                {{ vehicle.project_id }}
            </td>
            <td class="hidden lg:table-cell">
                <span class="flex h-3 w-3 m-auto">
                    <span
                        :class="(vehicle.deleted_at === null) ? 'bg-green' : 'bg-red'"
                        class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
                    />
                    <span
                        :class="(vehicle.deleted_at === null) ? 'bg-green' : 'bg-red'"
                        class="relative inline-flex rounded-full h-3 w-3"
                    />
                </span>
            </td>
            <td>
                <router-link
                    :to="{ name: ( type === 'admin' ? 'adminVehicleDetail' : 'vehicleDetail'), params: {id: vehicle.id}}"
                >
                    <button class="bg-red p-1 rounded focus:outline-none">
                        <img
                            class="w-8"
                            src="/img/icons/pen.png"
                        >
                    </button>
                </router-link>
            </td>
        </tr>
    </table>
</template>
<script>
import { formatDate } from '@/js/services/helper.service';

export default {
    name: 'VehicleTable',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: () => 'user',
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
    },
};
</script>
