const { DateTime } = require('luxon');

/**
 * Formats number
 * @param {number} value
 * @param digits
 * @returns {string} formatted number
 */
export function formatNumber(value, digits = 2) {
    return value.toLocaleString('de-DE', {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
    });
}

/**
 * Formats number to price
 * @param {number} value
 * @returns {string} formatted price
 */
export function formatPrice(value) {
    if (!value && (value !== 0)) {
        return '';
    }

    return `€&nbsp;${formatNumber(value)}`;
}

/**
 * Formats date
 * @param {date} date
 * @param {boolean} includeTime whether to include the time in the format
 * @returns {string} formatted date
 */
export function formatDate(date, includeTime = false) {
    const luxonDate = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);

    if (includeTime) {
        return luxonDate.setLocale('de-DE').toFormat('DDD TT');
    }
    return luxonDate.setLocale('de-DE').toFormat('DDD');
}

/**
 * Checks if date is valid
 * @param {date} date
 * @param {boolean} biggerToday
 * @returns {boolean} isValid
 */
export function isValidDate(date, biggerToday = false) {
    if (date == null) return false;

    // eslint-disable-next-line no-param-reassign
    if (!(date instanceof Date)) date = new Date(date);

    // https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
    const result = !Number.isNaN(date.getTime());
    if (biggerToday) {
        return result && (date.valueOf() > (new Date().valueOf()));
    }
    return result;
}
