<template>
    <div>
        <div class="px-2 lg:px-32 py-14">
            <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                Fahrzeugaustattung
            </p>
            <div
                v-show="data.length === 0"
                class="font-bold text-center lg:text-left mb-4"
            >
                Das Fahrzeug besitzt keine Ausstattung.
            </div>
            <div class="flex justify-center lg:justify-start">
                <button
                    class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
                    @click="showForm = true"
                >
                    <div class="bg-red rounded">
                        <img
                            class="w-8"
                            src="/img/icons/add.png"
                        >
                    </div>
                    <span class="mx-4 font-bold text-sm">Austattung hinzufügen</span>
                </button>
            </div>
            <table
                v-show="data.length > 0"
                class="w-full text-center"
            >
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th />
                </tr>
                <tr
                    v-for="property in data"
                    :key="property.id"
                    class="hover:bg-red hover:bg-opacity-10"
                >
                    <td>{{ property.id }}</td>
                    <td>{{ property.name }}</td>
                    <td>
                        <button
                            class="ml-1 bg-red p-1 rounded focus:outline-none"
                            @click="removeVehicleProperty(property.id)"
                        >
                            <svg
                                class="fill-current text-white w-7 h-7"
                                version="1.0"
                                viewBox="0 0 64 64"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g
                                    stroke="none"
                                    transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                                >
                                    <path
                                        d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                    />
                                </g>
                            </svg>
                        </button>
                    </td>
                </tr>
            </table>
            <vehicle-property-form
                :data="availableProperties"
                :show="showForm"
                :vehicle-id="vehicleId"
                @close="showForm = false"
                @create="$emit('create',$event)"
            />
        </div>
    </div>
</template>

<script>
import VehiclePropertyForm from '@/js/components/forms/VehiclePropertyForm.vue';
import { deleteVehicleProperty } from '@/js/services/vehicle.service';
import { getVehicleProperties } from '@/js/services/vehicleProperty.service';

export default {
    name: 'VehiclePropertyTable',
    components: {
        VehiclePropertyForm,
    },
    props: {
        vehicleId: {
            type: Number,
            required: true,
            default: () => -1,
        },
        data: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            showForm: false,
            allProperties: [],
        };
    },
    computed: {
        availableProperties() {
            const ids = this.data.map((e) => e.id);
            return this.allProperties.filter((e) => !ids.includes(e.id));
        },
    },
    mounted() {
        getVehicleProperties()
            .then((result) => {
                this.allProperties = result;
            });
    },
    methods: {
        removeVehicleProperty(propertyId) {
            deleteVehicleProperty(this.vehicleId, propertyId)
                .then(() => {
                    this.$emit('delete', propertyId);
                });
        },
    },
};
</script>
