import Vue from 'vue';
import Vuex from 'vuex';
import { rents } from './rent.module';
import { user } from './user.module';
import { auth } from './auth.module';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        rents,
        auth,
    },
});
