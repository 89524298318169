<template>
    <div class="bg-gray-500 w-full text-white p-8 text-center mb-0">
        <span class="font-bold">Traktorenwerk Lindner GmbH | Ing.-H.-Lindner-Str. 4, A 6250 Kundl/Tirol</span><br>
        Tel.: +43 (0) 5338 74 20-180 | <a href="mailto:innovation@lindner-traktoren.at">innovation@lindner-traktoren.at</a>
        | Ansprechperson: Daniela Rinnergschwentner
    </div>
</template>
<script>
export default {
    name: 'Footer',
};
</script>
