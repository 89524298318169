import axios from 'axios';

export const auth = {
    namespaced: true,
    state: {
        auth: {
            access_token: localStorage.getItem('access_token') || '',
            refresh_token: localStorage.getItem('refresh_token') || '',
            expires_at: localStorage.getItem('expires_at') || -1,
        },
        blocked: false,
    },
    getters: {
        /**
         * Returns stored access token
         * @param {object} state
         * @returns {object} accessToken
         */
        getAccessToken(state) {
            return state.auth.access_token;
        },
        /**
         * Returns stored refresh token
         * @param {object} state
         * @returns {object} refreshToken
         */
        getRefreshToken(state) {
            return state.auth.refresh_token;
        },
        /**
         * Returns true if access token is expired
         * @param {object} state
         * @returns {boolean} isExpired
         */
        isExpired(state) {
            const timeInMS = state.auth.expires_at * 1000;
            return timeInMS < Date.now();
        },
        /**
         * Returns true if user is blocked
         * @param {object} state
         * @returns {boolean} isBlocked
         */
        isBlocked(state) {
            return state.blocked;
        },
    },
    actions: {
        /**
         * Updates stored token
         * @param commit
         * @param getters
         * @param {object} token
         */
        async setToken({
            commit,
            getters,
        }, token) {
            commit('setToken', token);

            localStorage.setItem('access_token', token.access_token);
            localStorage.setItem('refresh_token', token.refresh_token);
            localStorage.setItem('expires_at', token.expires_at);

            axios.defaults.headers.common.Authorization = `Bearer ${getters.getAccessToken}`;
        },
        /**
         * Removes token
         */
        async forgetToken({
            commit,
            dispatch,
        }) {
            commit('setToken', {
                access_token: '',
                refresh_token: '',
                expires_at: -1,
            });

            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('expires_at');

            axios.defaults.headers.common.Authorization = 'Bearer';

            await dispatch('user/forgetUser', null, { root: true });
        },
    },
    mutations: {
        /**
         * Updates stored tokens
         * @param state
         * @param {object} token
         */
        setToken(state, token) {
            state.auth = token;
        },
        /**
         * Updates blocked state
         * @param {object} state
         */
        setBlocked(state) {
            state.blocked = true;
        },
    },
};
