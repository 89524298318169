<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <span class="font-bold text-lg">Pauschalpreis hinzufügen</span>
                <div class="mt-4">
                    <div>
                        <div>
                            <label
                                class="font-bold"
                                for="description"
                            >Beschreibung (Optional):</label>
                            <input
                                id="description"
                                v-model="form.description"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white border-gray-200"
                            >
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="value"
                            >Betrag:</label>
                            <input
                                id="value"
                                v-model.number="form.value"
                                :class="[(!validations.value) ? 'border-red' : 'border-gray-200']"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                placeholder="1000.00"
                                step="0.01"
                                type="number"
                            >
                        </div>
                    </div>
                    <div class="flex justify-center mt-4">
                        <spin-button
                            :loading="sendingRequest"
                            class="bg-red text-white w-52 h-12"
                            text="Pauschalpreis hinzufügen"
                            @btnClick="sendRequest"
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { createRentSingleCost } from '@/js/services/rent.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';

export default {
    components: {
        SpinButton,
    },
    props: {
        rentId: {
            type: Number,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
            default: () => false,
        },
    },
    data() {
        return {
            form: {
                value: 0,
                description: '',
            },
            oldForm: {},
            validations: {
                value: true,
            },
            sendingRequest: false,
        };
    },
    methods: {
        sendRequest() {
            if (this.sendingRequest) return;

            if (JSON.stringify(this.form) === JSON.stringify(this.oldForm)) {
                this.$emit('close');
                return;
            }
            this.sendingRequest = true;
            this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy
            this.validations.value = true;

            createRentSingleCost(this.rentId, {
                value: this.form.value,
                description: this.form.description,
            })
                .then((result) => {
                    this.form = {
                        value: 0,
                        description: '',
                    };
                    this.oldForm = JSON.parse(JSON.stringify(this.form));

                    this.$emit('create', result);
                    this.$emit('close');
                })
                .catch((error) => {
                    const errors = error.response.data.error;
                    if (errors) {
                        if (errors.value) {
                            this.validations.value = false;
                        }
                    }
                })
                .finally(() => {
                    this.sendingRequest = false;
                });
        },
    },
};
</script>
