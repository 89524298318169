<template>
    <div>
        <div>
            <p class="text-red font-bold text-xl text-center lg:text-left mb-4 flex items-center justify-center lg:justify-start">
                <span class="mr-2">Preis</span>
                <button
                    v-if="monthlyRate === null"
                    class="ml-1 bg-red rounded focus:outline-none w-7 h-7 mr-2"
                    @click="showPriceExplanation = true"
                >
                    <span class="text-md font-bold text-white w-full">i</span>
                </button>
            </p>
            <div
                v-if="isAdmin && monthlyRate === null"
                class="flex justify-start"
            >
                <button
                    class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
                    @click="showPriceForm = true"
                >
                    <div class="bg-red rounded">
                        <img
                            class="w-8"
                            src="/img/icons/add.png"
                        >
                    </div>
                    <span class="mx-4 font-bold text-sm">Preis erstellen</span>
                </button>
            </div>
            <div
                v-if="monthlyRate !== null"
                class="text-center lg:text-left mb-4"
            >
                Bei dieser Miete wurde ein monatlicher Pauschalpreis in Höhe von <span
                    class="font-bold"
                    v-html="formatPrice(monthlyRate)"
                /> vereinbart.
            </div>
            <div v-else>
                <div
                    v-show="data.length === 0"
                    class="font-bold text-center lg:text-left mb-4"
                >
                    Derzeit sind keine Preise vorhanden
                </div>
                <table
                    v-show="data.length > 0"
                    class="w-full md:w-4/5 mx-auto text-center"
                >
                    <tr>
                        <th class="hidden sm:table-cell">
                            ID
                        </th>
                        <th>Gültig ab</th>
                        <th v-show="!['equipment', 'rentEquipment'].includes(type) && extendedPrices">
                            <span class="hidden sm:block">Transporttarif</span><span class="sm:hidden">Transport</span>
                        </th>
                        <th v-show="!['equipment', 'rentEquipment'].includes(type) && extendedPrices">
                            <span class="hidden sm:block">Einsatztarif</span><span class="sm:hidden">Einsatz</span>
                        </th>
                        <th v-show="['equipment', 'rentEquipment'].includes(type) || !extendedPrices">
                            <span>Tarif</span>
                        </th>
                        <th
                            v-show="isAdmin"
                            class="hidden sm:table-cell"
                        >
                            Erstellt am
                        </th>
                    </tr>
                    <tr
                        v-for="(price, index) in data"
                        :key="index"
                    >
                        <td class="hidden sm:table-cell">
                            {{ index + 1 }}
                        </td>
                        <td>{{ formatDate(price.active_from) }}</td>
                        <td
                            v-show="!['equipment', 'rentEquipment'].includes(type) && extendedPrices"
                            v-html="formatPrice(price.light_use)"
                        />
                        <td
                            v-show="!['equipment', 'rentEquipment'].includes(type) && extendedPrices"
                            v-html="formatPrice(price.heavy_use)"
                        />
                        <td
                            v-show="!['equipment', 'rentEquipment'].includes(type) && !extendedPrices"
                            v-html="formatPrice(price.heavy_use)"
                        />
                        <td
                            v-show="['equipment', 'rentEquipment'].includes(type)"
                            v-html="formatPrice(price.price)"
                        />
                        <td
                            v-show="isAdmin"
                            class="hidden sm:table-cell"
                        >
                            {{ formatDate(price.created_at) }}
                        </td>
                        <td>
                            <button
                                v-show="new Date(price.active_from) > new Date()"
                                class="bg-red p-1 rounded focus:outline-none"
                                @click="removePrice(price.id)"
                            >
                                <svg
                                    class="fill-current text-white"
                                    height="24"
                                    version="1.0"
                                    viewBox="0 0 64 64"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="$emit('close')"
                                >
                                    <g
                                        stroke="none"
                                        transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                                    >
                                        <path
                                            d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                        />
                                    </g>
                                </svg>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <price-creation-form
            :id="id"
            :rent-equipment-id="rentEquipmentId"
            :show="showPriceForm"
            :type="type"
            @close="showPriceForm = false"
            @create="$emit('create',$event)"
        />
        <vehicle-price-explanation-form
            :show="showPriceExplanation"
            @close="showPriceExplanation = false"
        />
    </div>
</template>
<script>
import { formatDate, formatPrice } from '@/js/services/helper.service';
import PriceCreationForm from '@/js/components/forms/price/CreationForm.vue';
import VehiclePriceExplanationForm
    from '@/js/components/forms/price/VehiclePriceExplanationForm.vue';
import { deleteRentEquipmentPrice, deleteRentPrice } from '@/js/services/rent.service';
import { deleteVehiclePrice } from '@/js/services/vehicle.service';
import { deleteEquipmentPrice } from '@/js/services/equipment.service';

export default {
    name: 'PriceTable',
    components: {
        PriceCreationForm,
        VehiclePriceExplanationForm,
    },
    props: {
        isAdmin: {
            type: Boolean,
            default: () => false,
        },
        data: {
            type: Array,
            default: () => [],
        },
        id: { // Used for vehicles, rents, equipment
            type: Number,
            required: true,
            default: () => -1,
        },
        rentEquipmentId: { // Additional id if rentEquipments are used
            type: Number,
            required: false,
            default: () => -1,
        },
        type: {
            type: String,
            required: true,
            validator: (value) => ['rent', 'vehicle', 'equipment', 'rentEquipment'].includes(value),
        },
        monthlyRate: {
            type: Number,
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            showPriceForm: false,
            showPriceExplanation: false,
            extendedPrices: false,
        };
    },
    watch: {
        data: {
            immediate: true,
            handler(val) {
                this.extendedPrices = false;
                val.forEach((price) => {
                    if (Math.abs(price.light_use - price.heavy_use) >= 0.01) {
                        this.extendedPrices = true;
                    }
                });
            },
        },
    },
    methods: {
        formatPrice(value) {
            return formatPrice(value);
        },
        formatDate(date) {
            return formatDate(date);
        },
        removePrice(priceId) {
            const fnc = {
                rent: deleteRentPrice,
                vehicle: deleteVehiclePrice,
                equipment: deleteEquipmentPrice,
                rentEquipment: deleteRentEquipmentPrice,
            }[this.type];

            // Add additional rentEquipmentId if rentEquipment is the case
            const args = (this.type === 'rentEquipment') ? [this.id, this.rentEquipmentId, priceId] : [this.id, priceId];

            fnc(...args)
                .then(() => {
                    this.$emit('delete', priceId);
                });
        },
    },
};
</script>
