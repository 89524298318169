<template>
    <div>
        <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
            Rechnungen
        </p>
        <table
            v-show="data.length > 0"
            class="w-full md:w-4/5 xl:2/3 mx-auto text-center"
        >
            <tr>
                <th>ID</th>
                <th>Zeitraum</th>
            </tr>
            <tr
                v-for="invoice in data"
                :key="invoice.id"
            >
                <td>{{ invoice.id }}</td>
                <td>
                    {{ formatDate(invoice.starts_at) }} - {{ formatDate(invoice.ends_at) }}
                </td>
            </tr>
        </table>
        <div
            v-show="data.length === 0"
            class="font-bold text-center lg:text-left"
        >
            Derzeit sind keine Rechnungen vorhanden
        </div>
    </div>
</template>
<script>
import { formatDate } from '@/js/services/helper.service';

export default {
    name: 'InvoiceTable',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
    },
};
</script>
