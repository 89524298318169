<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <p class="font-bold text-lg mb-4">
                    Lieferdetails
                </p>
                <div class="mb-4">
                    <p class="font-bold mb-2">
                        Lieferart
                    </p>
                    <p v-show="delivery.delivery_type === 0">
                        Das Fahrzeug wird Ihnen bei Lindner zur Verfügung gestellt.
                    </p>
                    <p v-show="delivery.delivery_type === 1">
                        Das Fahrzeug wird von Ihnen zu Lindner transportiert.
                    </p>
                    <p v-show="delivery.delivery_Type === 2">
                        Das Fahrzeug wird Ihnen bei einem Händler in Ihrer Nähe zur Verfügung
                        gestellt.
                    </p>
                    <p v-show="delivery.delivery_type === 3">
                        Das Fahrzeug wird von Ihnen zu einem Händler in Ihrer Nähe transportiert.
                    </p>
                    <p v-show="delivery.delivery_type === 4">
                        Das Fahrzeug wird an Ihre individuelle Adresse geliefert.
                    </p>
                    <p v-show="delivery.delivery_type === 5">
                        Das Fahrzeug wird von Ihrer individuellen Adresse abgeholt.
                    </p>
                </div>
                <div class="mb-4">
                    <p class="font-bold mb-2">
                        Addresse
                    </p>
                    <div>
                        <p>{{ delivery.delivery_to.street }}</p>
                        <p>{{ delivery.delivery_to.zip }} {{ delivery.delivery_to.city }}</p>
                        <p>{{ delivery.delivery_to.country }}</p>
                    </div>
                </div>
                <p class="font-bold mb-2">
                    Lieferpositionen
                </p>
                <div>
                    <table
                        v-show="delivery.vehicles.length > 0 || delivery.equipment.length > 0"
                        class="w-full sm:w-1/2 xl:w-1/3 3xl:w-1/4 mx-auto"
                    >
                        <tr
                            v-for="vehicle in delivery.vehicles"
                            :key="vehicle.id"
                        >
                            <td class="hidden sm:block">
                                <img
                                    :src="vehicle.details.image"
                                    class="w-20"
                                >
                            </td>
                            <td class="font-bold">
                                {{ vehicle.name }}
                            </td>
                        </tr>
                        <tr
                            v-for="eq in delivery.equipment"
                            :key="eq.id"
                        >
                            <td class="hidden sm:block">
                                <img
                                    :src="eq.equipment.details.image"
                                    class="w-20"
                                >
                            </td>
                            <td class="font-bold">
                                {{ eq.equipment.name }}
                            </td>
                        </tr>
                    </table>
                    <div
                        v-show="delivery.vehicles.length === 0 && delivery.equipment.length === 0"
                        class="text-left"
                    >
                        Es sind derzeit keine Lieferpositionen zugewiesen.
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'DeliveryDetails',
    props: {
        delivery: {
            type: Object,
            default: () => {
            },
            required: true,
        },
        show: {
            type: Boolean,
            default: () => false,
        },
    },
};
</script>
