<template>
    <div>
        <div class="flex flex-row">
            <input
                id="coupon"
                v-model="input"
                class="shadow appearance-none border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-1 w-36 border-r-0"
                :class="invalid.length > 0 ? 'border-red focus:ring-red ' + inputClasses : 'border-gray-200 focus:ring-gray-200 ' + inputClasses"
                type="text"
                placeholder="Code"
                @keyup.enter="checkCoupon"
            >
            <spin-button
                :loading="sendingRequest"
                class="pt-2 pb-1.5 px-3 bg-red text-white text-small font-roboto w-24"
                text="Einlösen"
                @btnClick="checkCoupon"
            />
        </div>
        <p
            v-show="invalid.length > 0"
            class="italic text-sm text-red"
        >
            {{ invalid }}
        </p>
    </div>
</template>
<script>
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import { checkCouponAvailability, redeemCoupon } from '@/js/services/coupon.service';

export default {
    name: 'CouponInput',
    components: {
        SpinButton,
    },
    props: {
        coupon: {
            type: Object,
            default: () => null,
        },
        inputClasses: {
            type: String,
            default: '',
        },
        test: {
            type: Boolean,
            default: true,
        },
        userId: {
            type: Number,
            default: -1,
        },
        rentId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            input: '',
            sendingRequest: false,
            invalid: '',
        };
    },
    methods: {
        checkCoupon() {
            if (this.sendingRequest) return;
            this.sendingRequest = true;

            let promise;

            if (this.test) {
                promise = checkCouponAvailability(this.input);
            } else {
                promise = redeemCoupon(this.input, {
                    user_id: this.userId,
                    rent_id: this.rentId,
                });
            }

            promise.then((coupon) => {
                this.$emit('fetch', coupon);
                this.invalid = '';
            }).catch((error) => {
                this.invalid = error.response.status === 429 ? 'Bitte warten Sie einen Moment' : 'Der Code ist ungültig.';
            }).finally(() => { this.sendingRequest = false; });
        },
    },
};

</script>
