import { getUserRents } from '@/js/services/user.service';

export const rents = {
    namespaced: true,
    state: {
        rents: [],
    },
    getters: {
        /**
         * Returns stored rents
         * @param {object} state
         * @returns {object[]} rents
         */
        getRents(state) {
            return state.rents;
        },
    },
    actions: {
        /**
         * Initiates re-fetch
         */
        async fetchRents({
            commit,
            rootGetters,
        }) {
            // Fetch rents
            await getUserRents(rootGetters['user/getUser'].id)
                .then((allRents) => {
                    commit('rents/setRents', allRents, { root: true });
                });
        },
    },
    mutations: {
        /**
         * Updates stored rents
         * @param state
         * @param {object[]} allRents
         */
        setRents(state, allRents) {
            state.rents = allRents;
        },
    },
};
