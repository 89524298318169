<template>
    <div>
        <p class="text-red font-bold text-xl text-center lg:text-left">
            Versicherung
        </p>
        <p class="mb-4 text-center lg:text-left">
            Bitte wählen Sie Ihre gewünschte Versicherungsart.
        </p>
        <div class="mx-10 hidden md:block">
            <table class="w-full table-fixed">
                <tr class="hidden lg:table-row">
                    <th style="width: 30px" />
                    <th />
                    <th class="w-1/5 hidden lg:table-cell" />
                    <th
                        class="font-bold text-right w-1/5"
                    >
                        Preis / Woche <span class="inline-block">für <span class="text-red">{{
                            hours
                        }}</span> Stunden</span>
                    </th>
                </tr>
                <tr
                    v-for="(insurance, index) in insurances"
                    :key="index"
                    @click="updateInsurance(insurance)"
                >
                    <td style="width: 30px">
                        <input
                            v-model="selectedInsurance"
                            :value="insurance"
                            type="radio"
                        >
                    </td>
                    <td>
                        <!-- eslint-disable vue/no-v-html -->
                        <span
                            class="font-bold"
                            v-html="insurance.name"
                        /><br>
                        <!--eslint-enable-->
                        <span>{{ insurance.description }}</span>
                    </td>
                    <td class="text-red hidden lg:table-cell text-right w-1/5">
                        <span
                            class="font-bold"
                            v-html="formatPrice(insurance.price)"
                        /> pro Stunde
                    </td>
                    <td
                        class="text-right w-1/5 font-bold"
                        v-html="formatPrice(insurance.price * hours)"
                    />
                </tr>
            </table>
        </div>
        <div class="mx-2 block md:hidden">
            <button
                v-for="(insurance, index) in insurances"
                :key="index"
                :class="[selectedInsurance.id === insurance.id ? 'border-red border-opacity-30 bg-red bg-opacity-10' : 'border-gray-200 bg-gray-100 bg-opacity-50']"
                class="w-full rounded-lg border p-6 mb-6 focus:outline-none"
                @click="updateInsurance(insurance)"
            >
                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="text-center font-bold mb-2"
                    v-html="insurance.name"
                />
                <!--eslint-enable-->
                <div class="text-justify">
                    {{ insurance.description }}
                </div>
                <hr class="my-2">
                <div class="text-center">
                    Preis:
                    <p class="font-bold text-xl">
                        <span v-html="formatPrice(insurance.price)" /> pro Stunde
                    </p>
                </div>
            </button>
        </div>
    </div>
</template>
<script>
import { formatPrice } from '@/js/services/helper.service';

export default {
    name: 'InsuranceSelection',
    props: {
        // Fetch insurances in parent (to include in initial loading)
        insurances: {
            type: Array,
            default: () => {
            },
            required: true,
        },
        hours: {
            type: Number,
            default: () => 0,
            required: true,
        },
    },
    data() {
        return {
            selectedInsurance: {},
        };
    },
    created() {
        // Set default value
        this.updateInsurance(this.insurances[0]);
    },
    methods: {
        updateInsurance(insurance) {
            this.selectedInsurance = insurance;
            this.$emit('update', this.selectedInsurance);
        },
        formatPrice(price) {
            return formatPrice(price);
        },
    },
};
</script>
