import axios from 'axios';
import { DateTime } from 'luxon';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/vehicles`;

/**
 * Fetches vehicles from database
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getVehicles(params = {}) {
    return axios.get(`${BASE_URL}`, { params })
        .then((response) => {
            response.data.data.forEach((vehicle) => {
                vehicle.rent_price.sort((a, b) => DateTime.fromISO(a.active_from) - DateTime.fromISO(b.active_from));
            });

            return response.data.data;
        });
}

/**
 * Fetches vehicle by id
 * @param {number} vehicleId
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getVehicle(vehicleId, params = {}) {
    return axios.get(`${BASE_URL}/${vehicleId}`, { params })
        .then((response) => {
            response.data.data.rent_price.sort((a, b) => DateTime.fromISO(a.active_from) - DateTime.fromISO(b.active_from));

            return response.data.data;
        });
}

/**
 * Fetches vehicle prices by id
 * @param {number} vehicleId
 * @returns {Promise} API Request
 */
export function getVehiclePrices(vehicleId) {
    return axios.get(`${BASE_URL}/${vehicleId}/prices`)
        .then((response) => response.data.data);
}

/**
 * Fetches vehicle rents by id
 * @param {number} vehicleId
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getVehicleRents(vehicleId, params = {}) {
    return axios.get(`${BASE_URL}/${vehicleId}/rents`, { params })
        .then((response) => response.data.data);
}

/**
 * Fetches vehicle equipment by id
 * @param {number} vehicleId
 * @returns {Promise} API Request
 */
export function getVehicleEquipment(vehicleId, params = {}) {
    return axios.get(`${BASE_URL}/${vehicleId}/equipment`, { params })
        .then((response) => response.data.data);
}

/**
 * Creates a vehicle equipment
 * @param {number} vehicleId
 * @param {number} equipmentId
 * @returns {Promise} API Request
 */
export function createVehicleEquipment(vehicleId, equipmentId) {
    return axios.put(`${BASE_URL}/${vehicleId}/equipment/${equipmentId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes a vehicle equipment
 * @param {number} vehicleId
 * @param {number} equipmentId
 * @returns {Promise} API Request
 */
export function deleteVehicleEquipment(vehicleId, equipmentId) {
    return axios.delete(`${BASE_URL}/${vehicleId}/equipment/${equipmentId}`)
        .then((response) => response.data.data);
}

/**
 * Fetches public data of rents by vehicle id
 * @param {*} vehicleId
 * @returns {Promise} API Request
 */
export function getPublicVehicleRents(vehicleId) {
    return axios.get(`${BASE_URL}/${vehicleId}/rent`)
        .then((response) => response.data.data);
}

/**
 * Fetches list of vehicles and their rents
 * @returns {Promise} API Request
 */
export function getVehiclesRents() {
    return axios.get(`${BASE_URL}/rents/list`)
        .then((response) => response.data.data);
}

/**
 * Fetches vehicle properties by id
 * @param {number} vehicleId
 * @returns {Promise} API Request
 */
export function getVehicleProperties(vehicleId) {
    return axios.get(`${BASE_URL}/${vehicleId}/properties`)
        .then((response) => response.data.data);
}

/**
 * Updates a vehicle by id
 * @param {number} vehicleId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function updateVehicle(vehicleId, body = {}) {
    return axios.patch(`${BASE_URL}/${vehicleId}`, body)
        .then((response) => response.data.data);
}

/**
 * Creates a new vehicle price
 * @param {number} vehicleId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function createVehiclePrice(vehicleId, body = {}) {
    return axios.post(`${BASE_URL}/${vehicleId}/prices`, body)
        .then((response) => response.data.data);
}

/**
 * Creates a new vehicle proprty
 * @param {number} vehicleId
 * @param {number} propertyId
 * @returns {Promise} API Request
 */
export function createVehicleProperty(vehicleId, propertyId) {
    return axios.put(`${BASE_URL}/${vehicleId}/properties/${propertyId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes a vehicle property
 * @param {number} vehicleId
 * @param {number} propertyId
 * @returns {Promise} API Request
 */
export function deleteVehicleProperty(vehicleId, propertyId) {
    return axios.delete(`${BASE_URL}/${vehicleId}/properties/${propertyId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes a vehicle price
 * @param {number} vehicleId
 * @param {number} priceId
 * @returns {Promise} API Request
 */
export function deleteVehiclePrice(vehicleId, priceId) {
    return axios.delete(`${BASE_URL}/${vehicleId}/prices/${priceId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes a vehicle
 * @param {number} vehicleId
 * @returns {Promise} API Request
 */
export function deleteVehicle(vehicleId) {
    return axios.delete(`${BASE_URL}/${vehicleId}`)
        .then((response) => response.data.data);
}
