<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <span class="font-bold text-lg">Lieferung erstellen</span>
                <address-form
                    :delivery-from="form.delivery_from"
                    :delivery-to="form.delivery_from"
                    :delivery-type="form.delivery_type"
                    :user="user"
                    @delivery-type-update="form.delivery_type = $event"
                    @delivery-from-update="form.delivery_from = $event"
                    @delivery-to-update="form.delivery_to = $event"
                />
                <div class="mt-4">
                    <label
                        class="font-bold"
                        for="delivery_at"
                    >Lieferung am</label>
                    <v-date-picker
                        v-model="form.delivery_at"
                        :min-date="today.plus({ days: 1})"
                        class="inline-block h-full w-full"
                    >
                        <template #default="{ inputValue, togglePopover }">
                            <div class="flex items-center">
                                <input
                                    :placeholder="today.setLocale('de-DE').toFormat('D')"
                                    :value="inputValue"
                                    class="w-full p-3 border-2 rounded-md mt-1/2 my-1"
                                    readonly
                                    @click="togglePopover"
                                >
                            </div>
                        </template>
                    </v-date-picker>
                </div>
                <div>
                    <label
                        class="font-bold"
                        for="description"
                    >Beschreibung</label>
                    <input
                        id="description"
                        v-model="form.description"
                        class="w-full p-2 border-2 rounded-md my-1"
                        type="text"
                    >
                </div>
                <div class="flex justify-center mt-4">
                    <spin-button
                        :loading="sendingRequest"
                        class="bg-red text-white w-52 h-12"
                        text="Lieferung erstellen"
                        @btnClick="sendRequest"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { createDelivery } from '@/js/services/delivery.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import { DateTime } from 'luxon';
import AddressForm from './AddressForm.vue';

export default {
    components: {
        SpinButton,
        AddressForm,
    },
    props: {
        rentId: {
            type: Number,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
            default: () => false,
        },
    },
    data() {
        return {
            form: {
                delivery_from: {
                    city: '',
                    country: 'AT',
                    street: '',
                    zip: '',
                },
                delivery_to: {
                    city: '',
                    country: 'AT',
                    street: '',
                    zip: '',
                },
                delivery_type: 0,
                delivery_at: null,
                description: '',
            },
            oldForm: {},
            sendingRequest: false,
        };
    },
    computed: {
        today() {
            return DateTime.now();
        },
    },
    methods: {
        sendRequest() {
            if (this.sendingRequest) return;

            if (JSON.stringify(this.form) === JSON.stringify(this.oldForm)) {
                this.$emit('close');
                return;
            }
            this.sendingRequest = true;
            this.form.rent_id = this.rentId;
            this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy

            createDelivery(this.oldForm)
                .then((result) => {
                    this.$emit('create', result);
                    this.$emit('close');
                })
                .finally(() => {
                    this.sendingRequest = false;
                });
        },
    },
};
</script>
