<template>
    <div
        class="border-gray-200 dropdown inline-block relative h-full border-2 w-full rounded-md"
    >
        <button
            class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center justify-between w-full"
            type="button"
        >
            <span class="mr-1">{{ lindner_addresses[selectedAddress].name }}</span>
            <svg
                class="fill-current h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
            </svg>
        </button>
        <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 w-full">
            <li
                v-for="(address,index) in lindner_addresses"
                :key="index"
            >
                <a
                    :class="{'rounded-t': (index === 0), 'rounded-b': (index === (lindner_addresses.length-1))}"
                    class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap w-full"
                    @click="selectedAddress = index; $emit('update', lindner_addresses[index]);"
                >{{ lindner_addresses[index].name }}</a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            selectedAddress: 0,
            lindner_addresses: [
                {
                    name: 'Technologiezentrum',
                    id: 1,
                    street: 'Ingenieur-Hermann-Lindner-Straße 4',
                    city: 'Kundl',
                    zip: '6250',
                    country: 'AT',
                },
                {
                    name: 'Innovationszentrum',
                    id: 2,
                    street: 'Weinberg 25',
                    city: 'Kundl',
                    zip: '6250',
                    country: 'AT',
                },
            ],
        };
    },
    mounted() {
        this.$emit('update', this.lindner_addresses[this.selectedAddress]);
    },
};
</script>
