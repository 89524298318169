<template>
    <div class="px-8 lg:px-32 py-14 mb-auto">
        <p class="text-red text-3xl font-bold mb-8">
            Lieferungverwaltung
        </p>
        <form
            class="flex items-center flex-col w-full mb-14"
            @submit.prevent="fetchDeliveries"
        >
            <div class="mb-8 flex w-full flex-col md:flex-row">
                <div class="md:pr-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="rentid"
                        >ID</label>
                        <div class="flex flex-row items-center">
                            <div>
                                <div
                                    class="dropdown inline-block relative h-full border-2 border-r-0 rounded-l-md"
                                >
                                    <button
                                        class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
                                        type="button"
                                    >
                                        <span class="mr-1">{{ filter.id.operator }}</span>
                                        <svg
                                            class="fill-current h-4 w-4"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                                        <li>
                                            <a
                                                class="rounded-t bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.id.operator = '<'"
                                            >&#60;</a>
                                        </li>
                                        <li>
                                            <a
                                                class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.id.operator = '>'"
                                            >&#62;</a>
                                        </li>
                                        <li>
                                            <a
                                                class="rounded-b bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.id.operator = '='"
                                            >=</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <input
                                id="rentid"
                                v-model.number="filter.id.value"
                                class="w-full p-2 border-2 rounded-r-md my-1"
                                placeholder="ID"
                                type="number"
                            >
                        </div>
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="price"
                        >Preis</label>
                        <div class="flex flex-row items-center">
                            <div>
                                <div
                                    class="dropdown inline-block relative h-full border-2 border-r-0 rounded-l-md"
                                >
                                    <button
                                        class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
                                        type="button"
                                    >
                                        <span class="mr-1">{{ filter.price.operator }}</span>
                                        <svg
                                            class="fill-current h-4 w-4"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                                        <li>
                                            <a
                                                class="rounded-t bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.price.operator = '<='"
                                            >&#60;=</a>
                                        </li>
                                        <li>
                                            <a
                                                class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.price.operator = '>='"
                                            >&#62;=</a>
                                        </li>
                                        <li>
                                            <a
                                                class="rounded-b bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.price.operator = '='"
                                            >=</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <input
                                id="price"
                                v-model.number="filter.price.value"
                                class="w-full p-2 border-2 rounded-r-md my-1"
                                placeholder="0"
                                type="number"
                            >
                        </div>
                    </div>
                </div>
                <div class="md:pl-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="delivery_at"
                        >Lieferung am</label>
                        <div class="flex flex-row items-center">
                            <div>
                                <div
                                    class="dropdown inline-block relative h-full border-2 border-r-0 rounded-l-md"
                                >
                                    <button
                                        class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
                                        type="button"
                                    >
                                        <span class="mr-1">{{ filter.delivery_at.operator }}</span>
                                        <svg
                                            class="fill-current h-4 w-4"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                                        <li>
                                            <a
                                                class="rounded-t bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.delivery_at.operator = '<='"
                                            >&#60;=</a>
                                        </li>
                                        <li>
                                            <a
                                                class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.delivery_at.operator = '>='"
                                            >&#62;=</a>
                                        </li>
                                        <li>
                                            <a
                                                class="rounded-b bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.delivery_at.operator = '='"
                                            >=</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <v-date-picker
                                v-model="filter.delivery_at.value"
                                class="inline-block h-full w-full"
                            >
                                <template #default="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                        <input
                                            :value="inputValue"
                                            class="w-full p-2 border-2 rounded-r-md my-1"
                                            readonly
                                            @click="togglePopover"
                                        >
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="status"
                        >Status</label>
                        <div class="flex flex-row items-center">
                            <div
                                class="dropdown inline-block relative h-full border-2 border-r-0 rounded-l-md"
                            >
                                <button
                                    class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
                                    type="button"
                                >
                                    <span class="mr-1">{{ filter.status.operator }}</span>
                                    <svg
                                        class="fill-current h-4 w-4"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                        />
                                    </svg>
                                </button>
                                <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                                    <li>
                                        <a
                                            class="rounded-t bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                            @click="filter.status.operator = '<='"
                                        >&#60;=</a>
                                    </li>
                                    <li>
                                        <a
                                            class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                            @click="filter.status.operator = '>='"
                                        >&#62;=</a>
                                    </li>
                                    <li>
                                        <a
                                            class="rounded-b bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                            @click="filter.status.operator = '='"
                                        >=</a>
                                    </li>
                                </ul>
                            </div>
                            <div
                                class="dropdown inline-block relative h-full border-2 rounded-r-md w-full"
                            >
                                <button
                                    class="bg-white text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center justify-between w-full"
                                    type="button"
                                >
                                    <span class="mr-1">{{
                                        localizeStatus(filter.status.value)
                                    }}</span>
                                    <svg
                                        class="fill-current h-4 w-4"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                        />
                                    </svg>
                                </button>
                                <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                                    <li
                                        v-for="(_,index) in 5"
                                        :key="index"
                                    >
                                        <a
                                            :class="{'rounded-t': index === 0, 'rounded-b': index === 5}"
                                            class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                            @click="filter.status.value = index"
                                        >{{ localizeStatus(index) }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="bg-red text-white px-20 py-2 text-lg">
                Suchen
            </button>
        </form>
        <loading-spinner
            v-show="loading"
            class="h-40"
        />
        <div
            v-show="!loading"
            class="flex items-center flex-col"
        >
            <delivery-table
                :data="paginatedDeliveries"
                type="admin"
            />
            <pagination
                :data="deliveries"
                :show-per-page="20"
                class="mt-12"
                @update="pageChanged"
            />
        </div>
    </div>
</template>
<script>
import { getDeliveries, localizeStatus } from '@/js/services/delivery.service';
import Pagination from '@/js/components/generic/Pagination.vue';
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';

import DeliveryTable from '@/js/components/page/delivery/DeliveryTable.vue';
import { DateTime } from 'luxon';

export default {
    name: 'AdminDelivery',
    components: {
        Pagination,
        LoadingSpinner,
        DeliveryTable,
    },
    data() {
        return {
            deliveries: [],
            pagination: {
                page: 1,
                lowerIndex: 0,
                upperIndex: 0,
            },
            filter: {
                id: {
                    operator: '>',
                    value: 0,
                },
                status: {
                    operator: '>=',
                    value: 0,
                },
                price: {
                    operator: '>=',
                    value: 0,
                },
                delivery_at: {
                    operator: '>=',
                    value: DateTime.now(),
                },
            },
            loading: false,
        };
    },
    computed: {
        paginatedDeliveries() {
            return this.deliveries.slice(
                this.pagination.lowerIndex,
                this.pagination.upperIndex,
            );
        },
    },
    mounted() {
        this.fetchDeliveries();
    },
    methods: {
        fetchDeliveries() {
            if (this.loading) return;
            this.loading = true;

            const params = {
                ...((this.filter.id.value !== 0) && {
                    'id[value]': this.filter.id.value,
                    'id[operator]': this.filter.id.operator,
                }),
                'status[operator]': this.filter.status.operator,
                'status[value]': this.filter.status.value,
                'price[operator]': this.filter.price.operator,
                'price[value]': this.filter.price.value,
                'delivery_at[operator]': this.filter.delivery_at.operator,
                'delivery_at[value]': this.filter.delivery_at.value,
                opt_fields: ['user'],
            };
            getDeliveries(params)
                .then((result) => {
                    this.deliveries = result;
                    this.loading = false;
                });
        },
        pageChanged(obj) {
            this.pagination = obj;
        },
        localizeStatus(status) {
            return localizeStatus(status);
        },
    },
};
</script>
