import axios from 'axios';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/users`;

/**
 * Fetches users from database
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getUsers(params = {}) {
    return axios.get(`${BASE_URL}`, { params })
        .then((response) => response.data.data);
}

/**
 * Fetches user by id
 * @param {number} userId
 * @returns {Promise} API Request
 */
export function getUser(userId) {
    return axios.get(`${BASE_URL}/${userId}`)
        .then((response) => response.data.data);
}

/**
 * Fetches user rents by id
 * @param {number} userId
 * @returns {Promise} API Request
 */
export function getUserRents(userId) {
    return axios.get(`${BASE_URL}/${userId}/rents`)
        .then((response) => response.data.data);
}

/**
 * Updates user by id
 * @param {number} userId
 * @param {object} body
 * @returns {Promise} API Request
 */
export function updateUser(userId, body = {}) {
    return axios.patch(`${BASE_URL}/${userId}`, body)
        .then((response) => response.data.data);
}
