<template>
    <div>
        <loading-spinner v-if="loading" />
        <transition name="fade">
            <div
                v-if="!loading"
                class="striped"
            >
                <div class="mx-8 lg:mx-32">
                    <div
                        class="flex mb-16 mt-16 flex-col lg:flex-row lg:items-center mx-0 xl:mx-20 2xl:mx-40"
                    >
                        <div class="flex flex-col items-center">
                            <img
                                :src="rent.vehicle.details.image"
                                class="w-40 lg:w-80"
                            >
                            <span class="flex flex-row">
                                <span class="flex h-3 w-3 m-auto">
                                    <span
                                        :class="colorizeStatus(rent.status, 'bg')"
                                        class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
                                    />
                                    <span
                                        :class="colorizeStatus(rent.status, 'bg')"
                                        class="relative inline-flex rounded-full h-3 w-3"
                                    />
                                </span>
                                <span class="ml-4 font-bold">{{
                                    localizeStatus(rent.status)
                                }}</span>
                            </span>
                        </div>
                        <div class="lg:pl-32 flex flex-col items-center w-full space-y-4">
                            <div
                                class="flex items-center flex-col lg:flex-row lg:items-baseline mb-2 text-2xl text-center font-bold"
                            >
                                {{ rent.vehicle.name }}
                            </div>
                            <div
                                class="flex justify-between w-full sm:w-3/4 md:3/5 xl:w-2/3 2xl:w-1/2"
                            >
                                <div class="text-center">
                                    <span class="font-bold">Mietnr.</span><br>
                                    {{ rent.id }}
                                </div>
                                <div class="text-center hidden sm:block">
                                    <span class="font-bold">Fahrzeugnr.</span><br>
                                    {{ rent.vehicle.vin }}
                                </div>
                                <div class="text-center">
                                    <span class="font-bold">Kennzeichen</span><br>
                                    {{ rent.vehicle.plate || 'Nicht zugewiesen' }}
                                </div>
                            </div>
                            <div
                                class="flex w-full sm:w-3/4 md:3/5 xl:w-2/3 2xl:w-1/2 mt-4 flex-col sm:flex-row justify-center"
                            >
                                <div class="text-center">
                                    <span class="font-bold">Zeitraum</span><br>
                                    <span v-tooltip.bottom="formatDate(rent.active_from, true)">{{ formatDate(rent.active_from) }}</span> -
                                    <span v-tooltip.bottom="formatDate(rent.active_to, true)">{{ formatDate(rent.active_to) }}</span>
                                </div>
                            </div>
                            <div v-show="user.active_coupons.length === 0 && rent.status < 4">
                                <button
                                    class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
                                    @click="showCouponForm = true"
                                >
                                    <div class="bg-red rounded">
                                        <img
                                            class="w-8"
                                            src="/img/icons/add.png"
                                        >
                                    </div>
                                    <span class="mx-4 font-bold text-sm">Gutschein einlösen</span>
                                </button>
                                <coupon-redeem-form
                                    :show="showCouponForm"
                                    :user-id="rent.user_id"
                                    :rent-id="rent.id"
                                    @close="showCouponForm = false"
                                    @fetch="fetchedCoupon"
                                />
                            </div>
                            <coupon-badge
                                v-if="rent.active_coupons.length > 0"
                                class="mt-2"
                                :coupon="rent.active_coupons[0]"
                            />
                        </div>
                    </div>
                </div>
                <delivery-table
                    :data="deliveries"
                    :is-admin="false"
                    :rent-id="rentId"
                    :user="user"
                    class="px-8 lg:px-32 py-14"
                />
                <price-table
                    :id="rent.id"
                    :data="prices"
                    :is-admin="false"
                    class="px-8 lg:px-32 py-14"
                    type="rent"
                    :monthly-rate="rent.monthly_rate"
                />
                <single-cost-table
                    v-if="singleCosts.length > 0"
                    :rent="rent"
                    :data="singleCosts"
                    :is-admin="false"
                    class="px-8 lg:px-32 py-14"
                />
                <div v-if="rent.status < 6 & rent.status > 1">
                    <invoice-table
                        :data="invoices"
                        class="px-8 lg:px-32 py-14"
                    />
                    <usage-component
                        :rent-id="rentId"
                        class="px-8 lg:px-32 py-14"
                    />
                    <pause-table
                        v-if="pauses.length !== 0"
                        :data="pauses"
                        class="px-8 lg:px-32 py-14"
                    />
                </div>
                <div
                    v-if="rent.status < 6"
                    class="px-8 lg:px-32 py-14"
                >
                    <div
                        v-show="rent.status === 1"
                        class="flex flex-row justify-center items-center"
                    >
                        <spin-button
                            :loading="sendingRequest"
                            class="bg-red text-white w-52 h-12 mr-2"
                            text="Angebot akzeptieren"
                            @btnClick="changeStatus(2)"
                        />
                        <spin-button
                            :loading="sendingRequest"
                            class="bg-gray-400 text-white w-52 h-12 ml-2"
                            text="Angebot ablehnen"
                            @btnClick="changeStatus(6)"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import {
    colorizeStatus,
    getRent,
    getRentDeliveries,
    getRentInvoices,
    getRentPauses,
    getRentPrices,
    getRentSingleCosts,
    localizeStatus,
    updateRent,
} from '@/js/services/rent.service';
import { formatDate, formatNumber } from '@/js/services/helper.service';

import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import PriceTable from '@/js/components/page/price/PriceTable.vue';
import InvoiceTable from '@/js/components/page/rentDetail/InvoiceTable.vue';
import UsageComponent from '@/js/components/page/rentDetail/UsageComponent.vue';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import DeliveryTable from '@/js/components/page/rentDetail/DeliveryTable.vue';
import PauseTable from '@/js/components/page/rentDetail/PauseTable.vue';
import CouponRedeemForm from '@/js/components/forms/CouponRedeemForm.vue';
import CouponBadge from '@/js/components/page/rentDetail/CouponBadge.vue';
import SingleCostTable from '@/js/components/page/rentDetail/SingleCostTable.vue';

export default {
    name: 'RentDetail',
    components: {
        CouponBadge,
        CouponRedeemForm,
        LoadingSpinner,
        PriceTable,
        InvoiceTable,
        UsageComponent,
        SpinButton,
        DeliveryTable,
        PauseTable,
        SingleCostTable,
    },
    data() {
        return {
            loading: true,
            rent: {},
            invoices: [],
            deliveries: [],
            prices: [],
            pauses: [],
            singleCosts: [],
            sendingRequest: false,
            showCouponForm: false,
        };
    },
    computed: {
        user() {
            return this.$store.getters['user/getUser'];
        },
        rentId() {
            return parseInt(this.$route.params.id, 10);
        },
    },
    mounted() {
        // Check if needed params are passed
        if (this.rentId != this.$route.params.id) this.$router.push({ name: 'dashboard' }); // eslint-disable-line eqeqeq

        // Get rent data
        const rentPromise = getRent(this.rentId);
        const invoicePromise = getRentInvoices(this.rentId);
        const pricesPromise = getRentPrices(this.rentId)
            .then((prices) => prices.sort((a, b) => new Date(a.active_from) - new Date(b.active_from)));
        const deliveryPromise = getRentDeliveries(this.rentId, { opt_fields: ['vehicles', 'equipment'] });
        const pausesPromise = getRentPauses(this.rentId);
        const singleCostPromise = getRentSingleCosts(this.rentId);

        Promise.all([rentPromise, invoicePromise, pricesPromise, deliveryPromise, pausesPromise, singleCostPromise])
            .then((result) => {
                [this.rent, this.invoices, this.prices, this.deliveries, this.pauses, this.singleCosts] = result;
                this.loading = false;
            })
            .catch(() => {
                // Rent not found
                this.$router.push({ name: 'dashboard' });
            });
    },
    methods: {
        formatNumber,
        localizeStatus(status) {
            return localizeStatus(status);
        },
        colorizeStatus(status, prefix) {
            return colorizeStatus(status, prefix);
        },
        formatDate(date, includeTime) {
            return formatDate(date, includeTime);
        },
        changeStatus(status) {
            if (this.sendingRequest) return;
            this.sendingRequest = true;
            updateRent(this.rent.id, {
                status,
            })
                .then((result) => {
                    this.rent = result;
                    this.sendingRequest = false;
                });
        },
        fetchedCoupon(coupon) {
            this.rent.active_coupons.push(coupon);
            this.$store.commit('user/addCoupon', coupon);
        },
    },
};
</script>
