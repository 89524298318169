<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <span class="font-bold text-lg">Beende Mietpause</span>
                <div class="mt-4">
                    <div>
                        <p><span class="font-bold">Gültig von:</span> {{ formatDate(rentPause.active_from) }}</p>
                        <label
                            class="font-bold"
                            for="active_to"
                        >Gültig bis:</label>
                        <input
                            id="active_to"
                            v-model="form.active_to"
                            :class="[(!validations.active_to) ? 'border-red' : 'border-gray-200']"
                            class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                            type="date"
                        >

                        <div>
                            <span
                                v-show="!validations.not_calculated"
                                class="font-bold text-red"
                            >Dieser Zeitraum ist bereits abgerechnet.</span>
                            <span
                                v-show="!validations.active_to && validations.not_calculated"
                                class="font-bold text-red"
                            >Das Ende ist vor dem Beginn.</span>
                        </div>
                    </div>
                    <div class="flex justify-center mt-4">
                        <spin-button
                            :loading="sendingRequest"
                            class="bg-gray-400 text-white w-52 h-12"
                            text="Mietpause beenden"
                            @btnClick="sendUpdateRequest"
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import { updateRentPause } from '@/js/services/rent.service';
import { formatDate, isValidDate } from '@/js/services/helper.service';

export default {
    components: {
        SpinButton,
    },
    props: {
        rent: {
            type: Object,
            required: true,
        },
        rentPause: {
            type: Object,
            default: () => ({}),
        },
        show: {
            type: Boolean,
            required: true,
            default: () => false,
        },
    },
    data() {
        return {
            form: {
                active_to: null,
            },
            sendingRequest: false,
            validations: {
                active_to: true,
                not_calculated: true,
            },
        };
    },
    watch: {
        show: {
            immediate: false,
            handler() {
                this.extendedPause = false;
                this.form = {
                    reason: '',
                };
            },
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        sendUpdateRequest() {
            const lastCalculation = new Date(this.rent.last_calculation_at);
            const activeFrom = new Date(this.rentPause.active_from);
            const activeTo = new Date(this.form.active_to);

            this.validations.active_to = isValidDate(this.form.active_to) && (activeFrom.valueOf() <= activeTo.valueOf());

            // Check if pause end is valid
            if (this.validations.active_to && this.rent.last_calculation_at !== null) {
                this.validations.not_calculated = (lastCalculation.valueOf() < (activeTo.valueOf()));
                this.validations.active_to = this.validations.not_calculated;
            } else {
                this.validations.not_calculated = true;
            }

            if (this.sendingRequest || !(this.validations.active_to && this.validations.not_calculated)) return;
            this.sendingRequest = true;
            updateRentPause(this.rent.id, this.rentPause.id, this.form)
                .then((result) => {
                    this.showForm = false;
                    this.$emit('update', result);
                    this.$emit('close');
                    this.sendingRequest = false;
                });
        },
    },
};
</script>
