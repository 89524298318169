<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <span class="font-bold text-lg">Anbaugerät hinzufügen</span>
                <div class="mt-4">
                    <div>
                        <div v-show="availableEquipment.length > 0">
                            <label
                                class="font-bold"
                                for="equipment_id"
                            >Wähle ein Anbaugerät:</label>
                            <div
                                :class="[(!validations.equipment_id) ? 'border-red' : 'border-gray-200']"
                                class="dropdown inline-block relative h-full border-2 w-full rounded-md"
                            >
                                <button
                                    class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center justify-between w-full"
                                    type="button"
                                >
                                    <span
                                        class="mr-1"
                                    >{{
                                        Object.keys(form.equipment).length === 0 ? 'Anbaugerät auswählen' : form.equipment.equipment.name
                                    }}</span>
                                    <svg
                                        class="fill-current h-4 w-4"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                        />
                                    </svg>
                                </button>
                                <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 w-full">
                                    <li>
                                        <a
                                            :class="{'rounded-b': availableEquipment.length === 0}"
                                            class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap w-full rounded-t"
                                            @click="form.equipment = {}"
                                        >Anbaugerät auswählen</a>
                                    </li>
                                    <li
                                        v-for="(equipment,index) in availableEquipment"
                                        :key="index"
                                    >
                                        <a
                                            :class="{'rounded-b': (index === (availableEquipment.length-1))}"
                                            class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap w-full"
                                            @click="form.equipment = equipment"
                                        >{{ equipment.equipment.name }}</a>
                                    </li>
                                </ul>
                            </div>
                            <span
                                v-show="!validations.equipment_id"
                                class="font-bold text-red"
                            >Die ID konnte nicht hinzugefügt werden!</span>
                        </div>
                        <div v-show="availableEquipment.length === 0">
                            Diese Miete hat derzeit keine Anbaugeräte, welche noch nicht in der
                            Lieferung enthalten sind.
                        </div>
                    </div>
                    <div
                        v-show="availableEquipment.length > 0"
                        class="flex justify-center mt-4"
                    >
                        <spin-button
                            :loading="sendingRequest"
                            class="bg-red text-white w-52 h-12"
                            text="Anbaugerät hinzufügen"
                            @btnClick="sendRequest"
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { createDeliveryEquipment } from '@/js/services/delivery.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';

export default {
    components: {
        SpinButton,
    },
    props: {
        deliveryId: {
            type: Number,
            required: true,
        },
        rentId: {
            type: Number,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        availableEquipment: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            form: {
                equipment: {},
            },
            oldForm: {},
            validations: {
                equipment_id: true,
            },
            sendingRequest: false,
        };
    },
    methods: {
        sendRequest() {
            if (this.sendingRequest) return;

            if (JSON.stringify(this.form) === JSON.stringify(this.oldForm)) {
                this.$emit('close');
                return;
            }
            this.sendingRequest = true;
            this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy
            this.validations.equipment_id = true;

            createDeliveryEquipment(this.deliveryId, this.form.equipment.id)
                .then((result) => {
                    this.form = {
                        equipment: {},
                    };
                    this.oldForm = JSON.parse(JSON.stringify(this.form));

                    this.$emit('equipmentCreate', result);
                    this.$emit('close');
                })
                .catch((error) => {
                    const errors = error.response.data.error;
                    if (errors) {
                        if (errors.equipment_id) {
                            this.validations.equipment_id = false;
                        }
                    }
                })
                .finally(() => {
                    this.sendingRequest = false;
                });
        },
    },
};
</script>
