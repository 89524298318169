import axios from 'axios';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/deliveries`;

/**
 * Fetches deliveries from database
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getDeliveries(params = {}) {
    return axios.get(`${BASE_URL}`, { params })
        .then((response) => response.data.data);
}

/**
 * Fetches delivery by id
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getDelivery(deliveryId, params = {}) {
    return axios.get(`${BASE_URL}/${deliveryId}`, { params })
        .then((response) => response.data.data);
}

/**
 * Creates a new delivery
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function createDelivery(body = {}) {
    return axios.post(BASE_URL, body)
        .then((response) => response.data.data);
}

/**
 * Updates a delivery by id
 * @param {number} deliveryId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function updateDelivery(deliveryId, body = {}) {
    return axios.patch(`${BASE_URL}/${deliveryId}`, body)
        .then((response) => response.data.data);
}

/**
 * Fetches delivery equipment by id
 * @param {number} deliveryId
 * @returns {Promise} API Request
 */
export function getDeliveryEquipment(deliveryId) {
    return axios.get(`${BASE_URL}/${deliveryId}/equipment`)
        .then((response) => response.data.data);
}

/**
 * Fetches delivery vehicles by id
 * @param {number} deliveryId
 * @returns {Promise} API Request
 */
export function getDeliveryVehicles(deliveryId) {
    return axios.get(`${BASE_URL}/${deliveryId}/vehicles`)
        .then((response) => response.data.data);
}

/**
 * Creates a new delivery vehicle
 * @param {number} deliveryId
 * @param {number} vehicleId
 * @returns {Promise} API Request
 */
export function createDeliveryVehicle(deliveryId, vehicleId) {
    return axios.put(`${BASE_URL}/${deliveryId}/vehicles/${vehicleId}`)
        .then((response) => response.data.data);
}

/**
 * Creates a new delivery equipment
 * @param {number} deliveryId
 * @param {number} equipmentId
 * @returns {Promise} API Request
 */
export function createDeliveryEquipment(vehicleId, equipmentId) {
    return axios.put(`${BASE_URL}/${vehicleId}/equipment/${equipmentId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes delivery vehicle by id
 * @param {number} deliveryId
 * @param {number} vehicleId
 * @returns {Promise} API Request
 */
export function deleteDeliveryVehicle(deliveryId, vehicleId) {
    return axios.delete(`${BASE_URL}/${deliveryId}/vehicles/${vehicleId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes delivery equipment by id
 * @param {number} deliveryId
 * @param {number} rentEquipmentId
 * @returns {Promise} API Request
 */
export function deleteDeliveryEquipment(deliveryId, rentEquipmentId) {
    return axios.delete(`${BASE_URL}/${deliveryId}/equipment/${rentEquipmentId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes delivery by id
 * @param {number} deliveryId
 * @returns {Promise} API Request
 */
export function deleteDelivery(deliveryId) {
    return axios.delete(`${BASE_URL}/${deliveryId}`)
        .then((response) => response.data.data);
}

/**
 * Localizes status enum
 * @param {number} status
 * @returns {string} localized status
 */
export function localizeStatus(status) {
    return ['Erstellt', 'Geplant', 'Zustellung', 'Zugestellt', 'Storniert'][status];
}

/**
 * Colorizes status enum
 * @param {number} status
 * @returns {string} colorized status
 */
export function colorizeStatus(status, prefix) {
    if (!(['bg', 'text'].includes(prefix))) throw new Error('Invalid color prefix provided');

    // HINT: This syntax is used to prevent postcss from purging conditional classes

    if (status === 0) {
        return {
            text: 'text-orange',
            bg: 'bg-orange',
        }[prefix];
    }

    if ([1, 2, 3].includes(status)) {
        return {
            text: 'text-green',
            bg: 'bg-green',
        }[prefix];
    }
    return {
        text: 'text-red',
        bg: 'bg-red',
    }[prefix];
}
