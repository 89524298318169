<template>
    <div>
        <p class="text-red font-bold text-xl mt-14 mb-8 text-center lg:text-left">
            Ihre Auswahl
        </p>
        <div class="sm:mx-10 mb-16 mt-4">
            <div class="flex flex-col lg:flex-row lg:justify-between items-center">
                <img
                    :src="vehicle.details.image"
                    class="w-40 lg:w-60"
                >
                <div class="lg:ml-10">
                    <div class="flex items-center mb-4 justify-center lg:justify-start mt-4 lg:mt-0">
                        <b>{{ vehicle.name }}</b>
                        <a
                            :href="vehicle.details.product_page"
                            class="text-white bg-red py-0.5 px-2 text-xs font-roboto mx-3 focus:outline-none"
                        >
                            Details
                        </a>
                    </div>
                    <table class="w-full table-fixed text-left">
                        <tr class="table-row">
                            <td
                                class="font-bold align-top w-1/2 lg:w-15/100"
                            >
                                Zeitraum:
                            </td>
                            <td
                                class="align-top w-1/2 lg:w-1/4"
                            >
                                <span class="whitespace-nowrap">{{ formatDate(start) }}</span> -<br class="md:hidden"> <span class="whitespace-nowrap">{{ formatDate(end) }}</span>
                            </td>
                            <td class="w-1/5 hidden 2xl:table-cell" />
                            <td
                                class="font-bold text-right w-1/5 hidden lg:table-cell"
                            >
                                Preis / Woche <span class="inline-block">für <span class="text-red">{{
                                    hours
                                }}</span> Stunden</span>
                            </td>
                        </tr>
                        <tr class="table-row">
                            <td
                                class="font-bold align-top"
                            >
                                Geschätzte Nutzung:
                            </td>
                            <td
                                class="align-top"
                            >
                                {{ hours }}h/Woche
                            </td>
                            <td class="text-red hidden 2xl:table-cell text-right w-1/5">
                                <span
                                    class="font-bold"
                                    v-html="formatPrice(pricePerHour)"
                                /> pro Stunde
                            </td>
                            <td class="text-right w-1/5 font-bold hidden lg:table-cell">
                                <p v-html="formatPrice(pricePerWeek)" />
                            </td>
                        </tr>
                        <tr class="table-row lg:hidden">
                            <td class="font-bold align-top">
                                Preis / Stunde:
                            </td>
                            <td class="align-top">
                                <p
                                    v-html="formatPrice(pricePerHour)"
                                />
                            </td>
                        </tr>
                        <tr class="table-row lg:hidden">
                            <td class="font-bold align-top">
                                Preis / Woche <span class="inline-block">für <span class="text-red">{{
                                    hours
                                }}</span> Stunden</span>:
                            </td>
                            <td class="align-top">
                                <p v-html="formatPrice(pricePerWeek)" />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { formatDate, formatPrice } from '@/js/services/helper.service';

export default {
    name: 'Vehicle',
    props: {
        vehicle: {
            type: Object,
            required: true,
        },
        start: {
            type: Date,
            required: true,
        },
        end: {
            type: Date,
            required: true,
        },
        hours: {
            type: Number,
            required: true,
        },
        pricePerHour: {
            type: Number,
            required: true,
        },
        pricePerWeek: {
            type: Number,
            required: true,
        },
    },
    methods: {
        formatDate,
        formatPrice,
    },
};
</script>
