<template>
    <div
        class="bg-cover bg-no-repeat bg-center h-full min-h-screen bg-fixed flex py-10 items-center"
        style="background-image: url('img/summer_background.png')"
    >
        <img
            class="absolute top-2 right-2 md:block hidden"
            src="/img/logo_stacked.png"
            style="height: 98px"
        >
        <div class="flex w-screen flex-col">
            <div
                v-show="isTestEnv"
                class="bg-white w-11/12 my-auto mb-10 px-5 mx-auto sm:mx-10 sm:px-10 md:mx-auto md:w-2/3 lg:mx-auto lg:w-1/2 xl:ml-20 py-10 rounded xl:w-2/5 2xl:w-1/3 flex flex-col"
            >
                <p class="text-3xl text-red italic font-poppins uppercase font-black text-left 2xl:text-center">
                    TracRent Testumgebung
                </p>
                <span class="text-justify italic">Das ist eine experimentelle Testumgebung - Änderungen werden nicht permanent gespeichert. Funktionalität wird nicht garantiert.</span>
            </div>
            <div
                :class="{'mt-0': isTestEnv}"
                class="bg-white w-11/12 my-auto px-5 mx-auto sm:mx-10 sm:px-10 md:mx-auto md:w-2/3 lg:mx-auto lg:w-1/2 xl:ml-20 py-10 md:px-20 rounded xl:w-2/5 2xl:w-1/3 flex flex-col"
            >
                <p class="text-3xl sm:text-5xl text-red italic font-poppins uppercase font-black mb-9 text-left 2xl:text-center">
                    Miete anfragen
                </p>
                <div
                    v-show="rentableVehicles.length === 0"
                    class="flex flex-col"
                >
                    <div
                        v-show="vehicleProperties.length > 0"
                        class="text-sm mb-4"
                    >
                        <label class="font-bold text-base inline-block mb-1.5">Fahrzeugausstattung
                            wählen</label>
                        <div class="flex flex-col">
                            <div
                                v-for="(vehicleProperty,index) in vehicleProperties.slice(0,vehiclePropertyLimit)"
                                :key="index"
                                class="flex items-center border-"
                            >
                                <input
                                    v-model="vehicleProperty.active"
                                    class="text-red-200 w-4 h-4 mr-4"
                                    type="checkbox"
                                >
                                <span class="text-sm">{{ vehicleProperty.name }}</span>
                            </div>
                            <button
                                v-show="vehiclePropertyLimit !== vehicleProperties.length"
                                class="text-left italic"
                                @click="vehiclePropertyLimit = vehicleProperties.length"
                            >
                                Mehr anzeigen
                            </button>
                        </div>
                    </div>
                    <div class="text-sm my-4">
                        <div>
                            <label class="font-bold text-base inline-block mb-1.5">Modell
                                wählen</label>
                            <select
                                v-model="selectedVehicle"
                                :class="[(isInvalid && (selectedVehicle === 'undefined')) ? 'border-red' : 'border-gray-200']"
                                class="w-full p-3 border mt-1/2 rounded-md"
                                @change="vehicleChanged($event)"
                            >
                                <option value="undefined">
                                    Bitte wählen
                                </option>
                                <option
                                    v-for="(vehicle, index) in filteredVehicles"
                                    :key="index"
                                    :value="vehicle[1]"
                                >
                                    {{ vehicle[0] }}
                                </option>
                            </select>
                        </div>
                        <span
                            v-show="isInvalid && (selectedVehicle === 'undefined')"
                            class="font-bold text-red"
                        >Bitte wählen Sie ein Fahrzeug.</span>
                        <div>
                            <label class="font-bold text-base inline-block mb-1.5 mt-4">Zeitraum der
                                Miete</label>
                            <div>
                                <v-date-picker
                                    v-model="rentRange"
                                    :disabled-dates="vehicleRents"
                                    :masks="rentRange.mask"
                                    :min-date="minStartDate"
                                    :value="rentRange"
                                    color="green"
                                    is-range
                                    mode="date"
                                >
                                    <template #default="{ inputValue, inputEvents }">
                                        <div
                                            class="flex flex-row justify-start items-center w-full"
                                        >
                                            <div class="relative flex-grow">
                                                <svg
                                                    class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                    />
                                                </svg>
                                                <input
                                                    :class="[(isInvalid && (!isStartDateValid) || isRentDurationShort || isRentDurationQuick || rentableVehiclesEmpty) ? 'border-red' : 'border-gray-200']"
                                                    :value="inputValue.start"
                                                    class="w-full p-3 border mt-1/2 rounded-md pl-8"
                                                    v-on="inputEvents.start"
                                                >
                                            </div>
                                            <span class="flex-shrink-0 m-2">
                                                <svg
                                                    class="w-4 h-4 stroke-current text-gray-600"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                    />
                                                </svg>
                                            </span>
                                            <div class="relative flex-grow">
                                                <svg
                                                    class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                    />
                                                </svg>
                                                <input
                                                    :class="[(isInvalid && (!isEndDateValid) || isRentDurationShort || isRentDurationQuick || rentableVehiclesEmpty) ? 'border-red' : 'border-gray-200']"
                                                    :value="inputValue.end"
                                                    class="w-full p-3 border mt-1/2 rounded-md pl-8"
                                                    v-on="inputEvents.end"
                                                >
                                            </div>
                                        </div>
                                    </template>
                                </v-date-picker>
                                <span
                                    v-show="isInvalid && !(isStartDateValid && isEndDateValid)"
                                    class="font-bold text-red"
                                >Das Start- oder Enddatum ist ungültig.</span>
                                <span
                                    v-show="isRentDurationQuick"
                                    class="font-bold text-red"
                                >Die Miete ist zu kurzfristig.</span>
                                <span
                                    v-show="isRentDurationShort && !isRentDurationQuick"
                                    class="font-bold text-red"
                                >Die Miete ist zu kurz.</span>
                                <span
                                    v-show="rentableVehiclesEmpty"
                                    class="font-bold text-red"
                                >Im gegebenen Zeitraum wurde kein verfügbares Fahrzeug gefunden.</span>
                            </div>
                        </div>
                        <div>
                            <label class="font-bold text-base inline-block mb-1.5 mt-4">Geschätzte
                                Nutzung in Stunden/Woche</label>
                            <select
                                v-model="selectedHours"
                                class="w-full p-3 border border-gray-200 mt-1/2 rounded-md"
                            >
                                <option
                                    v-for="value in hours"
                                    :key="value"
                                    :value="value"
                                >
                                    {{ value }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <spin-button
                            :loading="loading"
                            class="py-3 px-5 text-base bg-red text-white h-12 w-full"
                            text="Angebot anfordern"
                            @btnClick="sendRequest"
                        />
                        <div class="flex justify-center py-2 text-gray-500">
                            <span class="dashboard-btn px-2 text-sm">
                                oder
                            </span>
                        </div>
                        <router-link :to="{name: 'dashboard'}">
                            <button
                                class="py-3 px-5 text-base bg-gray-400 text-white h-12 w-full inline-flex items-center justify-center"
                            >
                                <span class="pr-2">Zur Anmeldung</span><svg
                                    viewBox="0 0 24 24"
                                    class="w-4 h-4 stroke-current text-gray-600"
                                ><path
                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="3"
                                /></svg>
                            </button>
                        </router-link>
                    </div>
                </div>
                <div
                    v-show="rentableVehicles.length > 0"
                    class="flex flex-col"
                >
                    <table class="mb-4">
                        <tr class="font-bold">
                            <td>Fahrzeugname</td>
                            <td class="text-right">
                                Grundpreis pro Stunde
                            </td>
                            <td />
                        </tr>
                        <tr
                            v-for="vehicle in rentableVehicles"
                            :key="vehicle.id"
                            class="hover:bg-red hover:bg-opacity-10"
                        >
                            <td class="pl-2">
                                {{ vehicle.name }}
                            </td>
                            <td
                                class="text-right"
                                v-html="formatPrice(vehicle.rent_price[0].heavy_use)"
                            />
                            <td class="text-right py-2 pl-4 pr-2 flex justify-end w-full h-full">
                                <router-link
                                    :to="{ name: 'selection', params: {id: vehicle.id}, query: {start: rentRange.start.toString(), end: rentRange.end.toString(), hours: selectedHours}}"
                                    class="bg-red p-1 rounded focus:outline-none"
                                >
                                    <img
                                        class="w-8"
                                        src="/img/icons/edit.png"
                                    >
                                </router-link>
                            </td>
                        </tr>
                    </table>

                    <button
                        class="py-3 px-5 bg-red text-white text-base font-roboto"
                        @click="rentableVehicles = []"
                    >
                        Zurück
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getPublicVehicleRents, getVehicles } from '@/js/services/vehicle.service';
import { formatPrice, isValidDate } from '@/js/services/helper.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';

export default {
    name: 'Search',
    components: {
        SpinButton,
    },
    data() {
        return {
            vehicles: [],
            vehicleProperties: [],
            selectedVehicle: 'undefined',
            startDate: null,
            endDate: null,
            selectedHours: 10,
            isInvalid: false,
            loading: false,
            rentableVehicles: [],
            rentableVehiclesEmpty: false,
            vehicleRents: [],
            RENT_PREDAYS: 3,
            vehiclePropertyLimit: 5,
        };
    },
    computed: {
        filteredVehicles() {
            return this.vehicles.filter((vehicle) => {
                const properties = vehicle.details.properties.map((e) => e.name);

                // Return true if every selected property is a vehicle property (not selected -> !property.active)
                return this.vehicleProperties.every((property) => properties.includes(property.name) || !property.active);
            })
                .map((vehicle) => ([
                    vehicle.name,
                    vehicle.id,
                ]));
        },
        hours() {
            return Array.from({ length: 7 }, (i, value) => 10 + (value * 5));
        },
        isStartDateValid() {
            return this.isValidDate(this.rentRange.start);
        },
        isEndDateValid() {
            return this.isValidDate(this.rentRange.end);
        },
        isRentDurationShort() {
            // TODO: Move checks to api endpoint (e.g. by pulling a configuration)
            if (!this.isStartDateValid || !this.isEndDateValid) return false; // Return false as we don't want to mark both fields if one is not yet set

            const start = new Date(this.rentRange.start);
            const end = new Date(this.rentRange.end);

            // Rent lasts at least one day
            if (start >= end) return true;

            return false;
        },
        isRentDurationQuick() {
            // TODO: Move checks to api endpoint (e.g. by pulling a configuration)
            if (!this.isStartDateValid || !this.isEndDateValid) return false;

            const start = new Date(this.rentRange.start);

            // Check if at least 3 days until start
            return (((start - (new Date())) / (1000 * 60 * 60 * 24)) < this.RENT_PREDAYS);
        },
        isTestEnv() {
            return process.env.MIX_VUE_APP_ENV === 'local';
        },
        minStartDate() {
            const date = new Date();

            // add 1 to RENT_PREDAYS to flip to next day
            return new Date(date.getTime() + (this.RENT_PREDAYS + 1) * 24 * 60 * 60 * 1000);
        },
        minEndDate() {
            const date = new Date();

            // +2 to RENT_PREDAYS to have a minlength of 1 day
            return new Date(date.getTime() + (this.RENT_PREDAYS + 2) * 24 * 60 * 60 * 1000);
        },
        rentRange: {
            set(val) {
                this.startDate = val.start;
                this.endDate = val.end;
            },
            get() {
                return {
                    start: (this.startDate === null) ? this.minStartDate : this.startDate,
                    end: (this.endDate === null) ? this.minEndDate : this.endDate,
                    mask: {
                        input: 'DD.MM.YYYY',
                    },
                };
            },
        },
    },
    watch: {
        filteredVehicles: {
            handler(newValue) {
                // If computed property is rerendered, reset this.selectedVehicle if old value got filtered
                const ids = newValue.map((e) => e[1]);
                if (!ids.includes(this.selectedVehicle)) {
                    this.selectedVehicle = 'undefined';
                }
            },
        },
    },
    mounted() {
        getVehicles({
            isDeleted: false,
        })
            .then((result) => {
                this.vehicles = result;

                const nestedPropertyArray = result.map((x) => x.details.properties);
                const uniquePropertyNames = [...new Set(nestedPropertyArray.flat()
                    .map((x) => x.name))];

                this.vehicleProperties = uniquePropertyNames.map((e) => ({
                    name: e,
                    active: false,
                }));
            });
    },
    methods: {
        sendRequest() {
            this.rentableVehiclesEmpty = false;
            this.isInvalid = (this.selectedVehicle === 'undefined') || !this.isStartDateValid || !this.isEndDateValid || this.isRentDurationShort || this.isRentDurationQuick;
            if (this.isInvalid || this.loading) return;
            this.loading = true;

            const inputCopy = {
                start: this.rentRange.start.toISOString(),
                end: this.rentRange.end.toISOString(),
                hours: this.selectedHours,
            };

            const params = {
                id: this.selectedVehicle,
                'rent[start]': inputCopy.start,
                'rent[end]': inputCopy.end,
                isDeleted: false,
            };

            // Query available vehicles
            getVehicles(params)
                .then((result) => {
                    if (result.length === 1) {
                        // Skip selection
                        this.$router.push({
                            name: 'selection',
                            params: {
                                id: result[0].id,
                            },
                            query: inputCopy,
                        });
                    }

                    this.rentableVehicles = result;
                    if (this.rentableVehicles.length === 0) {
                        this.rentableVehiclesEmpty = true;
                    }
                    this.loading = false;
                });
        },
        isValidDate(date) {
            return isValidDate(date, true);
        },
        formatPrice(price) {
            return formatPrice(price);
        },
        vehicleChanged(event) {
            const selection = event.target.value;
            if (selection !== 'undefined') {
                getPublicVehicleRents(selection)
                    .then((result) => {
                        const rents = [];
                        result.map((rent) => (
                            rents.push({
                                start: new Date(rent.active_from),
                                end: new Date(rent.active_to),
                            })
                        ));
                        this.vehicleRents = rents;
                    });
            }
        },
    },
};
</script>

<style>
.dashboard-btn:after,
.dashboard-btn:before{
    content:"\00a0\00a0\00a0\00a0\00a0";
    text-decoration:line-through;
}
</style>
