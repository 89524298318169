<template>
    <div>
        <p class="text-red font-bold text-xl text-center lg:text-left">
            Anbaugeräte
        </p>
        <p
            v-show="availableEquipment.length === 0"
            class="mb-4 text-center lg:text-left"
        >
            Leider steht Ihnen derzeit kein kompatibles Anbaugerät zur Verfügung.
        </p>
        <div v-show="availableEquipment.length > 0">
            <p class="mb-4 text-center lg:text-left">
                Bitte wählen Sie Ihre gewünschten Anbaugeräte aus.
            </p>
            <div class="mx-10 hidden md:block">
                <table class="w-full">
                    <tr>
                        <td
                            class="font-bold text-right"
                            colspan="5"
                        >
                            Preis / Woche für <span class="text-red">{{ hours }}</span> Stunden
                        </td>
                    </tr>
                    <tr
                        v-for="(equipment, index) in availableEquipment"
                        :key="index"
                    >
                        <td width="30">
                            <input
                                v-model="selectedEquipment"
                                :value="equipment"
                                type="checkbox"
                                @change="$emit('update', selectedEquipment)"
                            >
                        </td>
                        <td class="hidden md:table-cell">
                            <img
                                :src="equipment.details.image"
                                width="80"
                            >
                        </td>
                        <td>
                            <b>{{ equipment.name }}</b><br>
                            <a
                                :href="equipment.details.product_page"
                                class="text-white bg-red py-0.5 px-2 text-xs font-roboto"
                            >
                                Details
                            </a>
                        </td>
                        <td class="text-red hidden lg:table-cell text-right">
                            <span
                                class="font-bold"
                                v-html="formatPrice(equipment.rent_price.price)"
                            /> pro Stunde
                        </td>
                        <td
                            class="text-right font-bold"
                            v-html="formatPrice(equipment.rent_price.price * hours)"
                        />
                    </tr>
                </table>
            </div>
            <div class="mx-2 block md:hidden">
                <button
                    v-for="(equipment, index) in availableEquipment"
                    :key="index"
                    :class="[(selectedEquipment.includes(equipment)) ? 'border-red border-opacity-30 bg-red bg-opacity-10' : 'border-gray-200 bg-white bg-opacity-70']"
                    class="rounded-lg w-full border p-6 mb-6 focus:outline-none"
                    @click="handleSelection(equipment)"
                >
                    <div class="text-center font-bold mb-2">
                        <img
                            :src="equipment.details.image"
                            class="mx-auto"
                            width="80"
                        >
                        <span>{{ equipment.name }}</span>
                    </div>
                    <hr class="my-2">
                    <div class="text-center">
                        Preis für <span class="font-bold text-red">{{ hours }}</span> Stunden:<br>
                        <span
                            class="font-bold text-xl"
                            v-html="formatPrice(equipment.rent_price.price * hours)"
                        />
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { formatPrice } from '@/js/services/helper.service';

export default {
    name: 'EquipmentSelection',
    props: {
        hours: {
            type: Number,
            required: true,
        },
        availableEquipment: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedEquipment: [],
        };
    },
    methods: {
        handleSelection(equipment) {
            if (this.selectedEquipment.includes(equipment)) {
                this.selectedEquipment = this.selectedEquipment.filter((entry) => entry !== equipment);
            } else {
                this.selectedEquipment.push(equipment);
            }
            this.$emit('update', this.selectedEquipment);
        },
        formatPrice(price) {
            return formatPrice(price);
        },
    },
};
</script>
