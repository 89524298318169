import axios from 'axios';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/equipment`;

/**
 * Fetches equipment from database
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getAllEquipment(params = {}) {
    return axios.get(`${BASE_URL}`, { params })
        .then((response) => response.data.data);
}

/**
 * Fetches equipment by id
 * @param {number} equipmentId
 * @returns {Promise} API Request
 */
export function getEquipment(equipmentId) {
    return axios.get(`${BASE_URL}/${equipmentId}`)
        .then((response) => response.data.data);
}

/**
 * Fetches equipment prices by id
 * @param {number} equipmentId
 * @returns {Promise} API Request
 */
export function getEquipmentPrices(equipmentId) {
    return axios.get(`${BASE_URL}/${equipmentId}/prices`)
        .then((response) => response.data.data);
}

/**
 * Fetches equipment prices by id
 * @param {number} equipmentId
 * @param {Object} params
 * @returns {Promise} API Request
 */
export function getEquipmentRents(equipmentId, params = {}) {
    return axios.get(`${BASE_URL}/${equipmentId}/rents`, { params })
        .then((response) => response.data.data);
}

/**
 * Creates a new equipment price
 * @param {number} equipmentId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function createEquipmentPrice(equipmentId, body = {}) {
    return axios.post(`${BASE_URL}/${equipmentId}/prices`, body)
        .then((response) => response.data.data);
}

/**
 * Deletes an equipment price
 * @param {number} equipmentId
 * @param {number} priceId
 * @returns {Promise} API Request
 */
export function deleteEquipmentPrice(equipmentId, priceId) {
    return axios.delete(`${BASE_URL}/${equipmentId}/prices/${priceId}`)
        .then((response) => response.data.data);
}

/**
 * Deletes an equipment
 * @param {number} equipmentId
 * @returns {Promise} API Request
 */
export function deleteEquipment(equipmentId) {
    return axios.delete(`${BASE_URL}/${equipmentId}`)
        .then((response) => response.data.data);
}

/**
 * Updates an equipment by id
 * @param {number} equipmentId
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function updateEquipment(equipmentId, body = {}) {
    return axios.patch(`${BASE_URL}/${equipmentId}`, body)
        .then((response) => response.data.data);
}

/**
 * Creates an equipment by id
 * @param {Object} body
 * @returns {Promise} API Request
 */
export function createEquipment(body) {
    return axios.post(`${BASE_URL}`, body)
        .then((response) => response.data.data);
}
