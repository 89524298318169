<template>
    <div
        v-if="!loading"
        class="absolute border border-black bg-white top-8 left-2 right-2 sm:left-8 sm:right-8 md:top-24 md:left-24 md:right-24 lg:top-48 lg:left-48 lg:right-48"
    >
        <div class="relative">
            <svg
                class="absolute right-4 top-4 cursor-pointer"
                height="24"
                version="1.0"
                viewBox="0 0 64 64"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                @click="forgetRequest"
            >
                <g
                    fill="#000000"
                    stroke="none"
                    transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                >
                    <path
                        d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                    />
                </g>
            </svg>
        </div>
        <div class="p-4 py-8 sm:p-8 md:p-16 lg:p-24">
            <p class="text-red text-3xl text-center w-full font-thin mb-8 md:mb-16 xl:text-4xl">
                Ihre Anfrage:
            </p>
            <table class="text-gray-400 text-xl mx-auto xl:text-2xl">
                <tr>
                    <td>Zeitraum der Miete:</td>
                    <td>
                        {{ formatDate(new Date(startDate)) }} - {{
                            formatDate(new Date(endDate))
                        }}
                    </td>
                </tr>
                <tr>
                    <td>Fahrzeug:</td>
                    <td>{{ vehicle.name }}</td>
                </tr>
                <tr v-show="equipment.length > 0">
                    <td>Anbaugeräte:</td>
                    <td v-html="equipment.map(equip => equip.name).join('<br/>')" />
                </tr>
                <tr>
                    <td class="pr-6">
                        Geschätzte Nutzung pro Woche:
                    </td>
                    <td>{{ hours }} Stunden</td>
                </tr>
                <tr>
                    <td>Versicherung:</td>
                    <td v-html="insurance.name" />
                </tr>
                <tr>
                    <td>Lieferart:</td>
                    <td v-html="deliveryType.name" />
                </tr>
                <tr v-if="coupon !== null">
                    <td>Gutschein ({{ coupon.code }}):</td>
                    <td v-html="coupon.type === 'BUDGET' ? formatPrice(coupon.value) : `${coupon.value} Stunden`" />
                </tr>
                <tr>
                    <td>Gesamtpreis:</td>
                    <td v-html="formatPrice(totalPrice)" />
                </tr>
            </table>
            <div
                v-show="!limitReached"
                class="flex justify-center mt-8 md:mt-16"
            >
                <spin-button
                    :loading="sendingRequest"
                    class="bg-red text-white w-52 h-12"
                    text="Anfrage senden"
                    @btnClick="sendRequest"
                />
            </div>
            <div
                v-if="limitReached"
                class="font-bold text-red mt-4 px-0 lg:px-32 text-center"
            >
                Maximale Anzahl an Mietanfragen erreicht.
            </div>
        </div>
    </div>
</template>
<script>
import { formatDate, formatPrice } from '@/js/services/helper.service';
import { getVehicle } from '@/js/services/vehicle.service';
import { getEquipment } from '@/js/services/equipment.service';
import { createRent } from '@/js/services/rent.service';
import { getInsurance } from '@/js/services/insurance.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';
import { getCurrentUser } from '@/js/services/auth.service';

const { DateTime } = require('luxon');

export default {
    name: 'RequestConfirmation',
    components: {
        SpinButton,
    },
    data() {
        return {
            vehicle: {},
            insurance: {},
            deliveryType: {},
            equipment: [],
            hours: 0,
            startDate: null,
            endDate: null,
            loading: true,
            totalPrice: 0,
            sendingRequest: false,
            limitReached: false,
            coupon: null,
        };
    },
    mounted() {
        const request = JSON.parse(localStorage.getItem('unsent_request'));

        // Set request data
        this.hours = request.rent.estimated_hours;
        this.startDate = request.rent.start_date;
        this.endDate = request.rent.end_date;
        this.totalPrice = request.rent.total_price;
        this.deliveryType = request.rent.delivery_type;
        if (request.coupon !== null) {
            this.coupon = request.coupon;
        }

        // Get vehicle data
        const vehiclePromise = getVehicle(request.rent.vehicle_id);
        const insurancePromise = getInsurance(request.rent.insurance_id);
        const equipmentPromises = [];

        request.equipment.forEach((equip) => {
            equipmentPromises.push(getEquipment(equip.id));
        });

        Promise.all([vehiclePromise, insurancePromise, ...equipmentPromises])
            .then((result) => {
                [this.vehicle, this.insurance, ...this.equipment] = result;
                this.loading = false;
            })
            .catch(() => {
                this.forgetRequest();
            });
    },
    methods: {
        formatPrice(value) {
            return formatPrice(value);
        },
        formatDate(date) {
            return formatDate(date);
        },
        sendRequest() {
            if (this.loading || this.sendingRequest) return;
            this.sendingRequest = true;

            const body = {
                vehicle_id: this.vehicle.id,
                active_from: DateTime.fromISO(this.startDate)
                    .startOf('day')
                    .toISO(), // add timezone and startOfDay
                active_to: DateTime.fromISO(this.endDate)
                    .endOf('day')
                    .toISO(), // add timezone and endOfDay
                estimated_hours: this.hours,
                insurance_id: this.insurance.id,
                total_price: this.totalPrice,
                delivery_type: this.deliveryType.id,
            };

            if (this.coupon !== null) {
                body.code = this.coupon.code;
            }

            // Do api request
            createRent(body, this.equipment)
                .then(() => {
                    this.forgetRequest();
                    getCurrentUser().then((user) => {
                        this.$store.dispatch('user/setUser', user);
                    });
                })
                .catch((error) => {
                    if (error.response.data.error.includes('You may have max')) {
                        this.limitReached = true;
                        localStorage.removeItem('unsent_request');
                    } else {
                        this.forgetRequest();
                    }
                }).finally(() => {
                    this.sendingRequest = false;
                });
        },
        forgetRequest() {
            localStorage.removeItem('unsent_request');
            this.loading = true;
        },
    },
};
</script>
