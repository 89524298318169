<template>
    <transition name="fade">
        <div
            v-if="show"
            class="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-20 p-4 overflow-y-auto"
            @click="$emit('close')"
        >
            <div
                class="bg-white mt-20 rounded-xl max-w-3xl p-8 mx-auto"
                @click.stop=""
            >
                <div class="relative">
                    <svg
                        class="absolute right-0 top-0 cursor-pointer"
                        height="24"
                        version="1.0"
                        viewBox="0 0 64 64"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="$emit('close')"
                    >
                        <g
                            fill="#000000"
                            stroke="none"
                            transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                        >
                            <path
                                d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                            />
                        </g>
                    </svg>
                </div>
                <span
                    class="font-bold text-lg"
                >{{
                    isCreationForm ? 'Erstelle Gutschein' : `Bearbeite Gutschein ${coupon.id}`
                }}</span>
                <div class="mt-4">
                    <div class="space-y-2">
                        <div>
                            <label
                                class="font-bold"
                                for="couponcode"
                            >Code:</label>
                            <input
                                id="couponcode"
                                v-model="form.code"
                                :class="[(!validations.codeEmpty || !validations.codeExists) ? 'border-red' : 'border-gray-200']"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                placeholder="LIN-C163"
                                type="text"
                            >
                            <span
                                v-show="!validations.codeEmpty"
                                class="font-bold text-red"
                            >Der Code darf nicht leer sein!</span>
                            <span
                                v-show="!validations.codeExists"
                                class="font-bold text-red"
                            >Der Code ist bereits vergeben!</span>
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="couponvaliduntil"
                            >Gültig bis:</label>
                            <input
                                id="couponvaliduntil"
                                v-model="form.valid_until"
                                :class="[!validations.validUntil ? 'border-red' : 'border-gray-200']"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                type="date"
                                value=""
                            >
                            <span
                                v-show="!validations.validUntil"
                                class="font-bold text-red"
                            >Der Zeitstempel liegt in der Vergangenheit!</span>
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="status"
                            >Typ</label>
                            <div
                                class="dropdown inline-block relative h-full border-2 w-full rounded-md border-gray-200"
                            >
                                <button
                                    class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center justify-between w-full"
                                    type="button"
                                >
                                    <span class="mr-1">{{ localizeType(form.type) }}</span>
                                    <svg
                                        class="fill-current h-4 w-4"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                        />
                                    </svg>
                                </button>
                                <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 w-full cursor-pointer">
                                    <li
                                        v-for="(type,index) in couponTypes"
                                        :key="index"
                                    >
                                        <a
                                            :class="{'rounded-t': (index === 0), 'rounded-b': (index === (couponTypes.length-1))}"
                                            class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap w-full"
                                            @click="form.type = type"
                                        >{{ localizeType(type) }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <label
                                class="font-bold"
                                for="couponvalue"
                            >Wert:</label>
                            <input
                                id="couponvalue"
                                v-model.number="form.value"
                                :class="[(!validations.value) ? 'border-red' : 'border-gray-200']"
                                class="w-full p-3 mt-1/2 rounded-md border-2 bg-white"
                                placeholder="0.00"
                                value="0.00"
                                type="number"
                                step="0.01"
                                min="0"
                            >
                            <span
                                v-show="!validations.value"
                                class="font-bold text-red"
                            >Der Wert muss größer als 0 sein!</span>
                        </div>
                    </div>
                    <div class="flex justify-center items-center mt-4 flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
                        <spin-button
                            v-show="!isCreationForm && coupon.activated_at === null"
                            :loading="sendingRequest"
                            text="Gutschein löschen"
                            class="bg-gray-400 text-white w-52 h-12"
                            @btnClick="deleteRequest"
                        />
                        <spin-button
                            :loading="sendingRequest"
                            :text="isCreationForm ? 'Gutschein erstellen' : 'Änderung speichern'"
                            class="bg-red text-white w-52 h-12"
                            @btnClick="sendRequest"
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { createCoupon, updateCoupon, deleteCoupon } from '@/js/services/coupon.service';
import SpinButton from '@/js/components/generic/buttons/SpinButton.vue';

const { DateTime } = require('luxon');

export default {
    components: {
        SpinButton,
    },
    props: {
        coupon: {
            type: Object,
            default: () => {
            },
        },
        show: {
            type: Boolean,
            required: true,
            default: () => false,
        },
    },
    data() {
        return {
            couponTypes: ['HOURS', 'BUDGET'],
            form: {
                code: '',
                valid_until: '',
                type: 'HOURS',
                value: 0,
            },
            oldForm: {},
            validations: {
                codeExists: true,
                codeEmpty: true,
                validUntil: true,
                value: true,
            },
            sendingRequest: false,
        };
    },
    computed: {
        isCreationForm() {
            return Object.keys(this.coupon).length === 0;
        },
    },
    watch: {
        show(val, oldVal) {
            if (oldVal && !val) {
                this.form = {
                    code: '',
                    valid_until: '',
                    type: 'HOURS',
                    value: 0,
                };

                this.resetValidations();
            } else if (!oldVal && val && !this.isCreationForm) {
                this.form = {
                    code: this.coupon.code,
                    valid_until: this.coupon.valid_until === null ? null : DateTime.fromISO(this.coupon.valid_until).toISODate(),
                    type: this.coupon.type,
                    value: this.coupon.value,
                };
                this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy
            }
        },
    },
    methods: {
        resetValidations() {
            Object.keys(this.validations).forEach((key) => { this.validations[key] = true; });
        },
        localizeType(type) {
            return { HOURS: 'Stunden', BUDGET: 'Guthaben' }[type];
        },
        deleteRequest() {
            if (this.coupon.activated_at !== null || this.sendingRequest) return;
            this.sendingRequest = true;
            deleteCoupon(this.coupon.id).finally(() => {
                this.sendingRequest = false;
                this.$emit('delete', this.coupon);
                this.$emit('close');
            });
        },
        sendRequest() {
            this.validations.codeEmpty = this.form.code !== '';
            this.validations.value = this.form.value > 0;

            // define the parsed variant, as date input does not return ISO8601
            let parsedValidUntil = null;

            if (this.form.valid_until === '' || this.form.valid_until === null) {
                parsedValidUntil = null;
                this.validations.validUntil = true;
            } else {
                const validUntil = DateTime.fromISO(this.form.valid_until).endOf('day');
                parsedValidUntil = validUntil.toISO();

                this.validations.validUntil = validUntil > DateTime.now();
            }

            if (!this.validations.codeEmpty || !this.validations.value || !this.validations.validUntil) return;
            if (this.sendingRequest) return;
            this.sendingRequest = true;
            this.codeExists = true;

            const sendForm = { ...this.form };
            sendForm.valid_until = parsedValidUntil;

            let fnc;

            if (this.isCreationForm) {
                fnc = createCoupon(sendForm)
                    .then((result) => {
                        this.$emit('create', result);
                    });
            } else {
                if (JSON.stringify(this.form) === JSON.stringify(this.oldForm)) {
                    this.$emit('close');
                    return;
                }
                this.oldForm = JSON.parse(JSON.stringify(this.form)); // Deep shallow copy

                fnc = updateCoupon(this.coupon.id, sendForm)
                    .then((result) => {
                        Object.keys(this.validations)
                            .forEach((key) => {
                                this.validations[key] = true;
                            });
                        this.$emit('update', result);
                    });
            }
            fnc.then(() => {
                this.resetValidations();
                this.$emit('close');
            });
            fnc.catch((error) => {
                const errors = error.response.data.error;
                if (Object.keys(errors).includes('code') && errors.code.includes('The code has already been taken.')) {
                    this.validations.codeExists = false;
                }
            });
            fnc.finally((() => {
                this.sendingRequest = false;
            }));
        },
    },
};
</script>
