<template>
    <div>
        <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
            Nutzung
        </p>
        <table
            v-show="data.length > 0"
            class="w-full md:w-4/5 xl:2/3 mx-auto text-center"
        >
            <tr>
                <th>ID</th>
                <th>Datum</th>
                <th class="hidden lg:table-cell">
                    Transporttarif
                </th>
                <th class="hidden lg:table-cell">
                    Einsatztarif
                </th>
                <th>Angefallene Kosten</th>
            </tr>
            <tr
                v-for="usage in data"
                :key="usage.relativeIndex"
            >
                <td>{{ usage.relativeIndex + 1 }}</td>
                <td>{{ formatDate(usage.created_at) }}</td>
                <td class="hidden lg:table-cell">
                    {{ formatNumber(usage.light_use.units) }}h
                </td>
                <td class="hidden lg:table-cell">
                    {{ formatNumber(usage.heavy_use.units) }}h
                </td>
                <td v-html="formatPrice(usage.light_use.amount + usage.heavy_use.amount)" />
            </tr>
        </table>
        <div
            v-show="data.length === 0"
            class="font-bold text-center lg:text-left"
        >
            Derzeit ist keine Nutzung vorhanden
        </div>
    </div>
</template>
<script>
import { formatDate, formatNumber, formatPrice } from '@/js/services/helper.service';

export default {
    name: 'UsageTable',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        formatPrice(value) {
            return formatPrice(value);
        },
        formatNumber(value) {
            return formatNumber(value);
        },
    },
};
</script>
