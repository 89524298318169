<template>
    <div class="px-8 lg:px-32 py-14 mb-auto">
        <p class="text-red text-3xl font-bold mb-8 flex justify-between">
            Anbaugerätverwaltung
            <button
                class="items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4 hidden sm:flex"
                @click="showEquipmentForm = true"
            >
                <div class="bg-red rounded">
                    <img
                        class="w-8"
                        src="/img/icons/add.png"
                    >
                </div>
                <span class="mx-4 font-bold text-sm">Anbaugerät erstellen</span>
            </button>
        </p>
        <form
            class="flex items-center flex-col w-full mb-14"
            @submit.prevent="fetchEquipment"
        >
            <div class="mb-8 flex w-full flex-col md:flex-row">
                <div class="md:pr-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="equipmentid"
                        >ID</label>
                        <div class="flex flex-row items-center">
                            <div>
                                <div
                                    class="dropdown inline-block relative h-full border-2 border-r-0 rounded-l-md"
                                >
                                    <button
                                        class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
                                        type="button"
                                    >
                                        <span class="mr-1">{{ filter.id.operator }}</span>
                                        <svg
                                            class="fill-current h-4 w-4"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                                        <li>
                                            <a
                                                class="rounded-t bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.id.operator = '<'"
                                            >&#60;</a>
                                        </li>
                                        <li>
                                            <a
                                                class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.id.operator = '>'"
                                            >&#62;</a>
                                        </li>
                                        <li>
                                            <a
                                                class="rounded-b bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.id.operator = '='"
                                            >=</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <input
                                id="equipmentid"
                                v-model.number="filter.id.value"
                                class="w-full p-2 border-2 rounded-r-md my-1"
                                placeholder="ID"
                                type="number"
                            >
                        </div>
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="serial_id"
                        >Seriennr.</label>
                        <input
                            id="serial_id"
                            v-model="filter.serial_id"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="Seriennr."
                            type="text"
                        >
                    </div>
                </div>
                <div class="md:pl-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="name"
                        >Name</label>
                        <input
                            id="name"
                            v-model="filter.name"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="Name"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="production_year"
                        >Produktionsjahr</label>
                        <div class="flex flex-row items-center">
                            <div>
                                <div
                                    class="dropdown inline-block relative h-full border-2 border-r-0 rounded-l-md"
                                >
                                    <button
                                        class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
                                        type="button"
                                    >
                                        <span class="mr-1">{{
                                            filter.production_year.operator
                                        }}</span>
                                        <svg
                                            class="fill-current h-4 w-4"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                                        <li>
                                            <a
                                                class="rounded-t bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.production_year.operator = '<'"
                                            >&#60;</a>
                                        </li>
                                        <li>
                                            <a
                                                class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.production_year.operator = '>'"
                                            >&#62;</a>
                                        </li>
                                        <li>
                                            <a
                                                class="rounded-b bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                                @click="filter.production_year.operator = '='"
                                            >=</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <input
                                id="production_year"
                                v-model.number="filter.production_year.value"
                                class="w-full p-2 border-2 rounded-r-md my-1"
                                placeholder="Produktionsjahr"
                                type="number"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <button class="bg-red text-white px-20 py-2 text-lg">
                Suchen
            </button>
        </form>
        <loading-spinner
            v-show="loading"
            class="h-40"
        />
        <div
            v-show="!loading"
            class="flex items-center flex-col"
        >
            <equipment-table
                :data="paginatedEquipment"
            />
            <pagination
                :data="equipment"
                :show-per-page="20"
                class="mt-12"
                @update="pageChanged"
            />
        </div>
        <div v-if="!loading">
            <equipment-form
                :equipment="{}"
                :show="showEquipmentForm"
                @close="showEquipmentForm = false"
                @create="addEquipment"
            />
        </div>
    </div>
</template>
<script>
import { getAllEquipment } from '@/js/services/equipment.service';
import Pagination from '@/js/components/generic/Pagination.vue';
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import EquipmentTable from '@/js/components/page/EquipmentTable.vue';
import EquipmentForm from '@/js/components/forms/equipment/EquipmentForm.vue';

export default {
    name: 'AdminEquipment',
    components: {
        Pagination,
        LoadingSpinner,
        EquipmentTable,
        EquipmentForm,
    },
    data() {
        return {
            equipment: [],
            pagination: {
                page: 1,
                lowerIndex: 0,
                upperIndex: 0,
            },
            filter: {
                id: {
                    operator: '>',
                    value: 0,
                },
                serial_id: '',
                name: '',
                production_year: {
                    operator: '>',
                    value: 2000,
                },
            },
            showEquipmentForm: false,
            loading: false,
        };
    },
    computed: {
        paginatedEquipment() {
            return this.equipment.slice(
                this.pagination.lowerIndex,
                this.pagination.upperIndex,
            );
        },
    },
    mounted() {
        this.fetchEquipment();
    },
    methods: {
        fetchEquipment() {
            if (this.loading) return;
            this.loading = true;

            const params = {
                ...((this.filter.id.value !== 0) && {
                    'id[value]': this.filter.id.value,
                    'id[operator]': this.filter.id.operator,
                }),
                ...((this.filter.name !== '') && { 'name[like]': `%${this.filter.name}%` }),
                ...((this.filter.serial_id !== '') && { 'serial_id[like]': `%${this.filter.serial_id}%` }),
                ...((this.filter.production_year !== 2000) && {
                    'production_year[value]': this.filter.production_year.value,
                    'production_year[operator]': this.filter.production_year.operator,
                }),
            };
            getAllEquipment(params)
                .then((result) => {
                    this.equipment = result;
                    this.loading = false;
                });
        },
        pageChanged(obj) {
            this.pagination = obj;
        },
        addEquipment(equipment) {
            this.$router.push({
                name: 'adminEquipmentDetail',
                params: {
                    id: equipment.id,
                },
            });
        },
    },
};
</script>
