<template>
    <div class="px-8 lg:px-32 py-14 mb-auto">
        <p class="text-red text-3xl font-bold mb-8">
            Fahrzeugverwaltung
        </p>
        <form
            class="flex items-center flex-col w-full mb-14"
            @submit.prevent="fetchVehicles"
        >
            <div class="mb-8 flex w-full flex-col md:flex-row">
                <div class="md:pr-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="plate"
                        >Kennzeichen</label>
                        <input
                            id="plate"
                            v-model="filter.plate"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="Kennzeichen"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="vin"
                        >FIN</label>
                        <input
                            id="vin"
                            v-model="filter.vin"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="FIN"
                            type="text"
                        >
                    </div>
                </div>
                <div class="md:pl-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="projectid"
                        >Projektnummer</label>
                        <input
                            id="projectid"
                            v-model="filter.projectid"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="Projektnummer"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="vehicle_name"
                        >Fahrzeugname</label>
                        <input
                            id="vehicle_name"
                            v-model="filter.vehicle_name"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="Fahrzeugname"
                            type="text"
                        >
                    </div>
                </div>
                <div class="md:pl-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="m3status"
                        >M3 Status</label>
                        <select
                            v-model="filter.m3status"
                            class="w-full p-2 my-1 border-2 rounded-md border-gray-200"
                        >
                            <option :value="true">
                                Mietbar
                            </option>
                            <option :value="null">
                                Alle
                            </option>
                            <option :value="false">
                                Gelöscht
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <button class="bg-red text-white px-20 py-2 text-lg">
                Suchen
            </button>
        </form>
        <loading-spinner
            v-show="loading"
            class="h-40"
        />
        <div
            v-show="!loading"
            class="flex items-center flex-col"
        >
            <vehicle-table
                :data="paginatedVehicles"
                type="admin"
            />
            <pagination
                :data="filteredVehicles"
                :show-per-page="20"
                class="mt-12"
                @update="pageChanged"
            />
        </div>
    </div>
</template>
<script>
import { getVehicles } from '@/js/services/vehicle.service';
import Pagination from '@/js/components/generic/Pagination.vue';
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import VehicleTable from '@/js/components/page/VehicleTable.vue';

export default {
    name: 'AdminVehicles',
    components: {
        Pagination,
        LoadingSpinner,
        VehicleTable,
    },
    data() {
        return {
            vehicles: [],
            pagination: {
                page: 1,
                lowerIndex: 0,
                upperIndex: 0,
            },
            filter: {
                vin: '',
                vehicle_name: '',
                plate: '',
                projectid: '',
                m3status: true,
            },
            loading: false,
        };
    },
    computed: {
        filteredVehicles() {
            if (this.filter.m3status === null) {
                return this.vehicles;
            }
            if (this.filter.m3status) {
                return this.vehicles.filter((vehicle) => vehicle.details.is_m3_rentable);
            }
            return this.vehicles.filter((vehicle) => !vehicle.details.is_m3_rentable);
        },
        paginatedVehicles() {
            return this.filteredVehicles.slice(
                this.pagination.lowerIndex,
                this.pagination.upperIndex,
            );
        },

    },
    mounted() {
        this.fetchVehicles();
    },
    methods: {
        fetchVehicles() {
            if (this.loading) return;
            this.loading = true;

            const params = {
                ...((this.filter.vehicle_name !== '') && { 'name[like]': `%${this.filter.vehicle_name}%` }),
                ...((this.filter.vin !== '') && { 'vin[like]': `%${this.filter.vin}%` }),
                ...((this.filter.plate !== '') && { 'plate[like]': `%${this.filter.plate}%` }),
                ...((this.filter.project_id !== '') && { project_id: this.filter.project_id }),
            };
            getVehicles(params)
                .then((result) => {
                    this.vehicles = result;
                    this.loading = false;
                });
        },
        pageChanged(obj) {
            this.pagination = obj;
        },
    },
};
</script>
