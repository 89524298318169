<template>
    <div class="px-8 lg:px-32 py-14 mb-auto">
        <p class="text-red text-3xl font-bold mb-8 flex flex-col sm:flex-row justify-between">
            Gutscheinverwaltung
            <button
                class="items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4 flex"
                @click="showCouponForm = true"
            >
                <div class="bg-red rounded">
                    <img
                        class="w-8"
                        src="/img/icons/add.png"
                    >
                </div>
                <span class="mx-4 font-bold text-sm">Gutschein erstellen</span>
            </button>
        </p>
        <form
            class="flex items-center flex-col w-full mb-14"
            @submit.prevent="fetchCoupons"
        >
            <div class="mb-8 flex w-full flex-col md:flex-row">
                <div class="md:pr-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="serial_id"
                        >Code</label>
                        <input
                            id="code"
                            v-model="filter.code"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="Code"
                            type="text"
                        >
                    </div>
                </div>
            </div>
            <button class="bg-red text-white px-20 py-2 text-lg">
                Suchen
            </button>
        </form>
        <loading-spinner
            v-show="loading"
            class="h-40"
        />
        <div
            v-show="!loading"
            class="flex items-center flex-col"
        >
            <coupon-table
                :data="paginatedCoupons"
                @select="selectCoupon"
            />
            <pagination
                :data="coupons"
                :show-per-page="20"
                class="mt-12"
                @update="pageChanged"
            />
        </div>
        <div v-if="!loading">
            <coupon-form
                :coupon="selectedCoupon"
                :show="showCouponForm"
                @close="showCouponForm = false"
                @create="addCoupon"
                @update="updateCoupon"
                @delete="deleteCoupon"
            />
        </div>
    </div>
</template>
<script>
import { getAllCoupons } from '@/js/services/coupon.service';
import Pagination from '@/js/components/generic/Pagination.vue';
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';
import CouponTable from '@/js/components/page/CouponTable.vue';
import CouponForm from '@/js/components/forms/CouponForm.vue';

export default {
    name: 'AdminCoupon',
    components: {
        Pagination,
        LoadingSpinner,
        CouponTable,
        CouponForm,
    },
    data() {
        return {
            coupons: [],
            pagination: {
                page: 1,
                lowerIndex: 0,
                upperIndex: 0,
            },
            filter: {
                code: '',
            },
            showCouponForm: false,
            loading: false,
            selectedCoupon: {},
        };
    },
    computed: {
        paginatedCoupons() {
            return this.coupons.slice(
                this.pagination.lowerIndex,
                this.pagination.upperIndex,
            );
        },
    },
    mounted() {
        this.fetchCoupons();
    },
    methods: {
        fetchCoupons() {
            if (this.loading) return;
            this.loading = true;

            const params = {
                'code[like]': `%${this.filter.code}%`,
            };
            getAllCoupons(params)
                .then((result) => {
                    this.coupons = result.toSorted((a, b) => b.id - a.id);
                    this.loading = false;
                });
        },
        pageChanged(obj) {
            this.pagination = obj;
        },
        addCoupon(coupon) {
            this.coupons.push(coupon);
            this.coupons.sort((a, b) => b.id - a.id);
        },
        updateCoupon(coupon) {
            const indices = this.coupons.map((c) => c.id);
            const index = indices.indexOf(coupon.id);

            this.$set(this.coupons, index, coupon);
        },
        deleteCoupon(coupon) {
            this.coupons = this.coupons.filter((c) => c.id !== coupon.id);
        },
        selectCoupon(coupon) {
            this.selectedCoupon = coupon;
            this.showCouponForm = true;
        },
    },
};
</script>
