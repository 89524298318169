<template>
    <div class="flex items-center">
        <div
            class="border border-dashed border-red rounded-lg px-2 py-1 bg-red bg-opacity-5 text-center leading-none"
        >
            <p class="text-sm font-bold">
                Gutschein <span class="italic">{{ coupon.code }}</span> aktiv!
            </p>
            <span class="text-xs">{{ isBudget ? '€&nbsp;': '' }}{{ formatNumber(coupon.remaining_value, isBudget ? 2 : 1) }}/{{ formatNumber(coupon.value, isBudget ? 2 : 1) }} {{ coupon.type === 'HOURS' ? 'Stunden' : 'Budget' }} verbleibend</span>
        </div>
        <a
            v-if="admin && (Math.abs(coupon.value - coupon.remaining_value) < 0.01)"
            class="text-2xl ml-2 font-bold cursor-pointer"
            @click="deleteCoupon"
            v-html="'&times'"
        />
    </div>
</template>
<script>
import { formatNumber } from '@/js/services/helper.service';
import { updateCoupon } from '@/js/services/coupon.service';

export default {
    name: 'CouponBadge',
    props: {
        coupon: {
            type: Object,
            required: true,
        },
        admin: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isBudget() {
            return this.coupon.type === 'BUDGET';
        },
    },
    methods: {
        formatNumber,
        deleteCoupon() {
            updateCoupon(this.coupon.id, {
                rent_id: null,
                user_id: null,
                activated_at: null,
            });
            this.$emit('delete', this.coupon);
        },
    },
};
</script>
