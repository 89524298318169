<template>
    <div class="flex items-center flex-col">
        <usage-table
            :data="paginatedUsages"
            class="w-full"
        />
        <pagination
            v-show="usages.length > 10"
            :data="usages"
            :show-per-page="10"
            class="mt-12"
            @update="pageChanged"
        />
    </div>
</template>
<script>
import { getRentUsages } from '@/js/services/rent.service';
import UsageTable from '@/js/components/page/rentDetail/UsageTable.vue';
import Pagination from '@/js/components/generic/Pagination.vue';

export default {
    name: 'UsageComponent',
    components: {
        Pagination,
        UsageTable,
    },
    props: {
        rentId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            usages: [],
            pagination: {
                page: 1,
                lowerIndex: 0,
                upperIndex: 0,
            },
        };
    },
    computed: {
        paginatedUsages() {
            return this.usages.slice(
                this.pagination.lowerIndex,
                this.pagination.upperIndex,
            );
        },
    },
    mounted() {
        getRentUsages(this.rentId)
            .then((usages) => {
                const sortedUsages = usages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

                sortedUsages.forEach((entry, index) => {
                    // eslint-disable-next-line no-param-reassign
                    entry.relativeIndex = index;
                });

                this.usages = sortedUsages;
            });
    },
    methods: {
        pageChanged(obj) {
            this.pagination = obj;
        },
    },
};

</script>
