<template>
    <div class="px-8 lg:px-32 py-14 mb-auto">
        <p class="text-red text-3xl font-bold mb-8">
            Nutzerverwaltung
        </p>
        <form
            class="flex items-center flex-col w-full mb-14"
            @submit.prevent="fetchUsers"
        >
            <div class="mb-8 flex w-full flex-col md:flex-row">
                <div class="md:pr-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="userid"
                        >ID</label>
                        <div class="flex flex-row items-center">
                            <div
                                class="dropdown inline-block relative h-full border-2 border-r-0 rounded-l-md"
                            >
                                <button
                                    class="bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
                                    type="button"
                                >
                                    <span class="mr-1">{{ filter.id.operator }}</span>
                                    <svg
                                        class="fill-current h-4 w-4"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                        />
                                    </svg>
                                </button>
                                <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                                    <li>
                                        <a
                                            class="rounded-t bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                            @click="filter.id.operator = '<'"
                                        >&#60;</a>
                                    </li>
                                    <li>
                                        <a
                                            class="bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                            @click="filter.id.operator = '>'"
                                        >&#62;</a>
                                    </li>
                                    <li>
                                        <a
                                            class="rounded-b bg-gray-100 hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                                            @click="filter.id.operator = '='"
                                        >=</a>
                                    </li>
                                </ul>
                            </div>
                            <input
                                id="userid"
                                v-model="filter.id.value"
                                class="w-full p-2 border-2 rounded-r-md my-1"
                                placeholder="ID"
                                type="text"
                            >
                        </div>
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="firstname"
                        >Vorname</label>
                        <input
                            id="firstname"
                            v-model="filter.firstname"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="Vorname"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="email"
                        >E-Mail</label>
                        <input
                            id="email"
                            v-model="filter.email"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="E-Mail"
                            type="text"
                        >
                    </div>
                </div>
                <div class="md:pl-4 w-full">
                    <div>
                        <label
                            class="font-bold"
                            for="m3userid"
                        >M3 Kundennummer</label>
                        <input
                            id="m3userid"
                            v-model="filter.m3_user_id"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="M3 Kundenummer"
                            type="text"
                        >
                    </div>
                    <div>
                        <label
                            class="font-bold"
                            for="lastname"
                        >Nachname</label>
                        <input
                            id="lastname"
                            v-model="filter.lastname"
                            class="w-full p-2 border-2 rounded-md my-1"
                            placeholder="Nachname"
                            type="text"
                        >
                    </div>
                </div>
            </div>
            <button class="bg-red text-white px-20 py-2 text-lg">
                Suchen
            </button>
        </form>
        <loading-spinner
            v-show="loading"
            class="h-40"
        />
        <div
            v-show="!loading"
            class="flex items-center flex-col"
        >
            <table class="w-full text-center">
                <tr>
                    <th>ID</th>
                    <th>Vorname</th>
                    <th>Nachname</th>
                    <th class="hidden sm:table-cell">
                        E-Mail
                    </th>
                    <th />
                </tr>
                <tr
                    v-for="user in paginatedUsers"
                    :key="user.id"
                    class="hover:bg-red hover:bg-opacity-10"
                >
                    <td>{{ user.id }}</td>
                    <td>{{ user.firstname }}</td>
                    <td>{{ user.lastname }}</td>
                    <td class="hidden sm:table-cell">
                        {{ user.email }}
                    </td>
                    <td>
                        <router-link :to="{ name: 'adminUserDetail', params: {id: user.id}}">
                            <button class="bg-red p-1 rounded focus:outline-none">
                                <img
                                    class="w-8"
                                    src="/img/icons/pen.png"
                                >
                            </button>
                        </router-link>
                    </td>
                </tr>
            </table>
            <pagination
                :data="users"
                :show-per-page="20"
                class="mt-12"
                @update="pageChanged"
            />
        </div>
    </div>
</template>
<script>
import { getUsers } from '@/js/services/user.service';
import Pagination from '@/js/components/generic/Pagination.vue';
import LoadingSpinner from '@/js/components/generic/LoadingSpinner.vue';

export default {
    name: 'AdminUsers',
    components: {
        Pagination,
        LoadingSpinner,
    },
    data() {
        return {
            users: [],
            pagination: {
                page: 1,
                lowerIndex: 0,
                upperIndex: 0,
            },
            filter: {
                id: {
                    operator: '>',
                    value: 0,
                },
                firstname: '',
                lastname: '',
                email: '',
                m3_user_id: '',
            },
            loading: false,
        };
    },
    computed: {
        paginatedUsers() {
            return this.users.slice(
                this.pagination.lowerIndex,
                this.pagination.upperIndex,
            );
        },

    },
    mounted() {
        this.fetchUsers();
    },
    methods: {
        fetchUsers() {
            if (this.loading) return;
            this.loading = true;

            const params = {
                ...((this.filter.id.value !== '') && {
                    'id[operator]': this.filter.id.operator,
                    'id[value]': this.filter.id.value,
                }),
                'firstname[like]': `%${this.filter.firstname}%`,
                'lastname[like]': `%${this.filter.lastname}%`,
                'email[like]': `%${this.filter.email}%`,
                ...(this.filter.m3_user_id !== '' && { m3_user_id: this.filter.m3_user_id }),
            };
            getUsers(params)
                .then((result) => {
                    this.users = result;
                    this.loading = false;
                });
        },
        pageChanged(obj) {
            this.pagination = obj;
        },
    },
};
</script>
