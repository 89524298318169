<template>
    <table class="w-full text-center">
        <tr>
            <th>ID</th>
            <th>FIN</th>
            <th>Fahrzeug</th>
            <th
                v-if="showName"
                class="hidden lg:table-cell"
            >
                Mieter
            </th>
            <th class="hidden sm:table-cell">
                Zeitraum
            </th>
            <th class="hidden lg:table-cell">
                Angefragt am
            </th>
            <th>Status</th>
            <th class="hidden lg:table-cell">
                Preis
            </th>
            <th />
            <th />
        </tr>
        <tr
            v-for="rent in data"
            :key="rent.id"
            class="hover:bg-red hover:bg-opacity-10"
        >
            <td>{{ rent.id }}</td>
            <td>{{ rent.vehicle.vin }}</td>
            <td>{{ rent.vehicle.name }}</td>
            <td
                v-if="showName"
                class="hidden lg:table-cell"
            >
                {{ rent.user.firstname }} {{ rent.user.lastname }}
            </td>
            <td class="hidden sm:table-cell">
                {{ formatDate(rent.active_from) }} - {{ formatDate(rent.active_to) }}
            </td>
            <td class="hidden lg:table-cell">
                {{ formatDate(rent.created_at) }}
            </td>
            <td
                :class="colorizeStatus(rent.status, 'text')"
                class="font-bold"
            >
                <span class="flex h-3 w-3 sm:hidden m-auto">
                    <span
                        :class="colorizeStatus(rent.status, 'bg')"
                        class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
                    />
                    <span
                        :class="colorizeStatus(rent.status, 'bg')"
                        class="relative inline-flex rounded-full h-3 w-3"
                    />
                </span>
                <span class="ml-2 hidden sm:block">{{ localizeStatus(rent.status) }}</span>
            </td>
            <td
                class="hidden lg:table-cell"
                v-html="formatPrice(rent.total_price)"
            />
            <td>
                <router-link
                    :to="{ name: ( type === 'admin' ? 'adminRentDetail' : 'rentDetail'), params: {id: rent.id}}"
                >
                    <button class="bg-red p-1 rounded focus:outline-none">
                        <img
                            class="w-8"
                            src="/img/icons/pen.png"
                        >
                    </button>
                </router-link>
            </td>
        </tr>
    </table>
</template>
<script>
import { formatDate, formatPrice } from '@/js/services/helper.service';
import { colorizeStatus, localizeStatus } from '@/js/services/rent.service';

export default {
    name: 'RentTable',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: () => 'user',
        },
        showName: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        localizeStatus(status) {
            return localizeStatus(status);
        },
        colorizeStatus(status, prefix) {
            return colorizeStatus(status, prefix);
        },
        formatPrice(value) {
            return formatPrice(value);
        },
    },
};
</script>
