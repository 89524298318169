<template>
    <table class="w-full text-center">
        <tr>
            <th>ID</th>
            <th>Name</th>
            <th class="hidden lg:table-cell">
                Seriennr.
            </th>
            <th class="hidden sm:table-cell">
                Aktiv
            </th>
            <th class="hidden sm:table-cell">
                Erstellt am
            </th>
            <th />
        </tr>
        <tr
            v-for="equipment in data"
            :key="equipment.id"
            class="hover:bg-red hover:bg-opacity-10"
        >
            <td>{{ equipment.id }}</td>
            <td>{{ equipment.name }}</td>
            <td class="hidden lg:table-cell">
                {{ equipment.serial_id }}
            </td>
            <td class="hidden sm:table-cell">
                <span class="flex h-3 w-3 m-auto">
                    <span
                        :class="(equipment.deleted_at === null) ? 'bg-green' : 'bg-red'"
                        class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
                    />
                    <span
                        :class="(equipment.deleted_at === null) ? 'bg-green' : 'bg-red'"
                        class="relative inline-flex rounded-full h-3 w-3"
                    />
                </span>
            </td>
            <td class="hidden sm:table-cell">
                {{ formatDate(equipment.created_at) }}
            </td>
            <td>
                <router-link :to="{ name: 'adminEquipmentDetail', params: {id: equipment.id}}">
                    <button class="bg-red p-1 rounded focus:outline-none">
                        <img
                            class="w-8"
                            src="/img/icons/pen.png"
                        >
                    </button>
                </router-link>
            </td>
        </tr>
    </table>
</template>
<script>
import { formatDate } from '@/js/services/helper.service';

export default {
    name: 'EquipmentTable',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
    },
};
</script>
