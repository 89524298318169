import axios from 'axios';
import store from '@/js/store';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/oauth2`;

/**
 * Fetches initial access token
 * @param {string} code
 * @returns {Promise} API Request
 */
export function callback(code) {
    return axios.get(`${BASE_URL}/token`, {
        params: {
            grant_type: 'access_token',
            code,
        },
    })
        .then((response) => store.dispatch('auth/setToken', response.data.data));
}

/**
 * Fetches refresh token
 * @returns {Promise} API Request
 */
export function refresh() {
    if (store.getters['auth/getRefreshToken'] === '') return Promise.reject('No refresh token provided');
    return axios.get(`${BASE_URL}/token`, {
        params: {
            grant_type: 'refresh_token',
            refresh_token: store.getters['auth/getRefreshToken'],
        },
    })
        .then((response) => store.dispatch('auth/setToken', response.data.data));
}

/**
 * Fetches current signed in user
 * @returns {Promise} API Request
 */
export function getCurrentUser() {
    if (store.getters['auth/getAuthToken'] === '') return Promise.reject('Not authenticated');
    return axios.get(`${BASE_URL}/me`)
        .then((response) => response.data.data);
}
