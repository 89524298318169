<template>
    <div class="w-full">
        <table class="w-full text-center">
            <tr>
                <th>ID</th>
                <th>Mietnummer</th>
                <th class="hidden lg:table-cell">
                    Kunde
                </th>
                <th>
                    Lieferung am
                </th>
                <th>Status</th>
                <th class="hidden lg:table-cell">
                    Preis
                </th>
                <th />
                <th />
            </tr>
            <tr
                v-for="delivery in data"
                :key="delivery.id"
                class="hover:bg-red hover:bg-opacity-10"
            >
                <td>{{ delivery.id }}</td>
                <td>{{ delivery.rent_id }}</td>
                <td class="hidden lg:table-cell">
                    {{ delivery.user.firstname }} {{ delivery.user.lastname }}
                </td>
                <td>
                    {{ formatDate(delivery.delivery_at) }}
                </td>
                <td
                    :class="colorizeStatus(delivery.status, 'text')"
                    class="font-bold"
                >
                    <span class="flex h-3 w-3 sm:hidden m-auto">
                        <span
                            :class="colorizeStatus(delivery.status, 'bg')"
                            class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
                        />
                        <span
                            :class="colorizeStatus(delivery.status, 'bg')"
                            class="relative inline-flex rounded-full h-3 w-3"
                        />
                    </span>
                    <span class="ml-2 hidden sm:block">{{ localizeStatus(delivery.status) }}</span>
                </td>
                <td
                    class="hidden lg:table-cell"
                    v-html="formatPrice(delivery.price)"
                />
                <td>
                    <router-link :to="{ name: 'adminDeliveryDetail', params: {id: delivery.id}}">
                        <button class="bg-red p-1 rounded focus:outline-none">
                            <img
                                class="w-8"
                                src="/img/icons/pen.png"
                            >
                        </button>
                    </router-link>
                </td>
            </tr>
        </table>
        <p
            v-show="data.length === 0"
            class="font-bold text-red text-center"
        >
            Es wurden keine Lieferungen gefunden!
        </p>
    </div>
</template>
<script>
import { formatDate, formatPrice } from '@/js/services/helper.service';
import { colorizeStatus, localizeStatus } from '@/js/services/delivery.service';

export default {
    name: 'DeliveryTable',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: () => 'user',
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        localizeStatus(status) {
            return localizeStatus(status);
        },
        colorizeStatus(status, prefix) {
            return colorizeStatus(status, prefix);
        },
        formatPrice(value) {
            return formatPrice(value);
        },
    },
};
</script>
