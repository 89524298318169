<template>
    <div>
        <div>
            <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
                Mietpausen
            </p>

            <div class="flex justify-start">
                <button
                    v-show="isAdmin && (rent.status <= 3)"
                    class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
                    @click="showPauseForm = true"
                >
                    <div class="bg-red rounded">
                        <img
                            class="w-8"
                            src="/img/icons/add.png"
                        >
                    </div>
                    <span class="mx-4 font-bold text-sm">Pause erstellen</span>
                </button>
            </div>
            <div
                v-show="pauses.length === 0"
                class="font-bold text-center lg:text-left mb-4"
            >
                Derzeit sind keine Pausen vorhanden
            </div>
            <table
                v-show="pauses.length > 0"
                class="w-full md:w-4/5 xl:2/3 mx-auto text-center"
            >
                <tr>
                    <th class="hidden sm:table-cell">
                        ID
                    </th>
                    <th>
                        Zeitraum
                    </th>
                    <th>Begründung</th>
                    <th v-if="isAdmin" />
                </tr>
                <tr
                    v-for="pause in pauses"
                    :key="pause.id"
                >
                    <td class="hidden sm:table-cell">
                        {{ pause.id }}
                    </td>
                    <td>
                        <span v-show="pause.active_to !== null">{{
                            formatDate(pause.active_from)
                        }} - {{ formatDate(pause.active_to) }}</span>
                        <span v-show="pause.active_to === null">Seit {{
                            formatDate(pause.active_from)
                        }}</span>
                    </td>
                    <td>{{ pause.reason || 'Kein Grund angegeben' }}</td>
                    <td v-if="isAdmin">
                        <button
                            v-show="pause.active_to === null"
                            v-tooltip.bottom="'Pause beenden'"
                            class="bg-red py-1 px-0.5 rounded focus:outline-none"
                            @click="selectedRentPause = pause; showUpdatePauseForm = true"
                        >
                            <img
                                class="w-7"
                                src="/img/icons/pen.png"
                            >
                        </button>
                        <button
                            v-show="pauseCalculated(pause)"
                            v-tooltip.bottom="'Pause löschen'"
                            class="bg-red p-1 rounded focus:outline-none pt-1"
                            @click="deletePause(pause.id)"
                        >
                            <svg
                                class="fill-current text-white"
                                height="24.5"
                                version="1.0"
                                viewBox="0 0 64 64"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="$emit('close')"
                            >
                                <g
                                    stroke="none"
                                    transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                                >
                                    <path
                                        d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                    />
                                </g>
                            </svg>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
        <rent-pause-creation-form
            :rent="rent"
            :show="showPauseForm"
            @close="showPauseForm = false"
            @create="$emit('create', $event)"
        />
        <rent-pause-update-form
            :rent="rent"
            :rent-pause="selectedRentPause"
            :show="showUpdatePauseForm"
            @close="showUpdatePauseForm = false"
            @update="$emit('update', $event)"
        />
    </div>
</template>
<script>
import { formatDate } from '@/js/services/helper.service';
import RentPauseCreationForm from '@/js/components/forms/RentPauseCreationForm.vue';
import RentPauseUpdateForm from '@/js/components/forms/RentPauseUpdateForm.vue';

import { deleteRentPause } from '@/js/services/rent.service';

export default {
    name: 'PauseTable',
    components: { RentPauseCreationForm, RentPauseUpdateForm },
    props: {
        isAdmin: {
            type: Boolean,
            default: () => false,
        },
        data: {
            type: Array,
            default: () => [],
        },
        rent: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            showPauseForm: false,
            showUpdatePauseForm: false,
            selectedRentPause: null,
        };
    },
    computed: {
        pauses() {
            const pauses = this.data;
            pauses.sort((a, b) => (a.id - b.id));
            return pauses;
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        pauseCalculated(pause) {
            if (this.rent.last_calculation_at === null) return true;

            return (new Date(this.rent.last_calculation_at)).valueOf() < (new Date(pause.active_from)).valueOf();
        },
        deletePause(pauseId) {
            deleteRentPause(this.rent.id, pauseId);

            this.$emit('delete', pauseId);
        },
    },
};
</script>
