<template>
    <div class="w-full">
        <div class="flex lg:justify-start flex-col sm:flex-row justify-center items-center">
            <button
                class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
                @click="showVehicleForm = true"
            >
                <div class="bg-red rounded">
                    <img
                        class="w-8"
                        src="/img/icons/add.png"
                    >
                </div>
                <span class="mx-4 font-bold text-sm">Fahrzeug hinzufügen</span>
            </button>
            <button
                class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
                @click="showEquipmentForm = true"
            >
                <div class="bg-red rounded">
                    <img
                        class="w-8"
                        src="/img/icons/add.png"
                    >
                </div>
                <span class="mx-4 font-bold text-sm">Anbaugerät hinzufügen</span>
            </button>
        </div>
        <table
            v-show="vehicles.length > 0 || equipment.length > 0"
            class="w-full sm:w-1/2 xl:w-1/3 3xl:w-1/4 mx-auto"
        >
            <tr
                v-for="vehicle in vehicles"
                :key="vehicle.id"
            >
                <td class="hidden sm:block">
                    <img
                        :src="vehicle.details.image"
                        class="w-20"
                    >
                </td>
                <td class="font-bold">
                    {{ vehicle.name }}
                </td>
                <td>
                    <button
                        class="bg-red p-1 rounded focus:outline-none"
                        @click="removeDeliveryVehicle(vehicle.id)"
                    >
                        <svg
                            class="fill-current text-white"
                            height="24"
                            version="1.0"
                            viewBox="0 0 64 64"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                stroke="none"
                                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            >
                                <path
                                    d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                />
                            </g>
                        </svg>
                    </button>
                </td>
            </tr>
            <tr
                v-for="eq in equipment"
                :key="eq.id"
            >
                <td class="hidden sm:block">
                    <img
                        :src="eq.equipment.details.image"
                        class="w-20"
                    >
                </td>
                <td class="font-bold">
                    {{ eq.equipment.name }}
                </td>
                <td>
                    <button
                        class="bg-red p-1 rounded focus:outline-none"
                        @click="removeDeliveryEquipment(eq.id)"
                    >
                        <svg
                            class="fill-current text-white"
                            height="24"
                            version="1.0"
                            viewBox="0 0 64 64"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                stroke="none"
                                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            >
                                <path
                                    d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                />
                            </g>
                        </svg>
                    </button>
                </td>
            </tr>
        </table>
        <div
            v-show="vehicles.length === 0 && equipment.length === 0"
            class="font-bold text-center lg:text-left"
        >
            Es sind derzeit keine Lieferpositionen zugewiesen.
        </div>

        <add-equipment-form
            :available-equipment="filteredAvailableEquipment"
            :delivery-id="deliveryId"
            :rent-id="rentId"
            :show="showEquipmentForm"
            @close="showEquipmentForm = false"
            @equipmentCreate="$emit('equipmentCreate',$event)"
        />
        <add-vehicle-form
            :delivery-id="deliveryId"
            :show="showVehicleForm"
            @close="showVehicleForm = false"
            @vehicleCreate="$emit('vehicleCreate',$event)"
        />
    </div>
</template>
<script>
import { colorizeStatus, localizeStatus } from '@/js/services/delivery.service';
import { getRentEquipment } from '@/js/services/rent.service';
import AddEquipmentForm from '@/js/components/forms/delivery/AddEquipmentForm.vue';
import AddVehicleForm from '@/js/components/forms/delivery/AddVehicleForm.vue';

export default {
    name: 'DeliveryPositionTable',
    components: {
        AddEquipmentForm,
        AddVehicleForm,
    },
    props: {
        rentId: {
            type: Number,
            default: () => -1,
        },
        deliveryId: {
            type: Number,
            default: () => -1,
        },
        vehicles: {
            type: Array,
            default: () => [],
        },
        equipment: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showEquipmentForm: false,
            showVehicleForm: false,
            availableEquipment: [],
        };
    },
    computed: {
        filteredAvailableEquipment() {
            const ids = this.equipment.map((entry) => entry.id);

            // Only return rent equipment which is not yet added
            return this.availableEquipment.filter((entry) => !ids.includes(entry.id));
        },
    },
    mounted() {
        this.fetchRentEquipment();
    },
    methods: {
        fetchRentEquipment() {
            getRentEquipment(this.rentId)
                .then((result) => {
                    this.availableEquipment = result;
                });
        },
        removeDeliveryVehicle(id) {
            this.$emit('vehicleDelete', id);
        },
        removeDeliveryEquipment(id) {
            this.$emit('equipmentDelete', id);
        },
        localizeStatus(status) {
            return localizeStatus(status);
        },
        colorizeStatus(status, prefix) {
            return colorizeStatus(status, prefix);
        },
    },
};
</script>
