<template>
    <div class="flex flex-col justify-between min-h-screen">
        <nav-bar v-show="showNavbar" />
        <router-view />
        <Footer v-show="showFooter" />
    </div>
</template>
<script>
import NavBar from '@/js/components/generic/NavBar.vue';
import Footer from '@/js/components/generic/Footer.vue';

export default {
    name: 'Wrapper',
    components: {
        NavBar,
        Footer,
    },
    computed: {
        showNavbar() {
            return this.$route.meta.navbar == null ? true : this.$route.meta.navbar;
        },
        showFooter() {
            return this.$route.meta.footer == null ? true : this.$route.meta.footer;
        },
    },
    watch: {
        $route: {
            handler(to) {
                document.title = to.meta.title || 'Lindner - TracRent';
            },
            immediate: true,
        },
    },
};
</script>
