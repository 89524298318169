import axios from 'axios';

const BASE_URL = `${process.env.MIX_VUE_API_BASE}/vehicle-properties`;

/**
 * Fetches vehicleProperties from database
 * @returns {Promise} API Request
 */
export function getVehicleProperties() {
    return axios.get(`${BASE_URL}`)
        .then((response) => response.data.data);
}
