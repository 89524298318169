<template>
    <table class="w-full text-center">
        <tr>
            <th>ID</th>
            <th>Code</th>
            <th class="hidden sm:table-cell">
                Typ
            </th>
            <th class="hidden sm:table-cell">
                Wert
            </th>
            <th>Status</th>
            <th class="hidden lg:table-cell">
                Gültig bis
            </th>
            <th />
        </tr>
        <tr
            v-for="coupon in data"
            :key="coupon.id"
            class="hover:bg-red hover:bg-opacity-10"
        >
            <td>{{ coupon.id }}</td>
            <td>{{ coupon.code }}</td>
            <td class="hidden sm:table-cell">
                {{ formatType(coupon) }}
            </td>
            <td class="hidden sm:table-cell">
                <span
                    v-tooltip.top="formatRemainingValue(coupon)"
                    v-html="formatValue(coupon)"
                />
            </td>
            <td>
                <span
                    v-tooltip.top="statusText(coupon)"
                    class="flex h-3 w-3 m-auto"
                >
                    <span
                        :class="statusColor(coupon)"
                        class="animate-ping absolute inline-flex h-3 w-3 rounded-full opacity-75"
                    />
                    <span
                        :class="statusColor(coupon)"
                        class="relative inline-flex rounded-full h-3 w-3"
                    />
                </span>
            </td>
            <td class="hidden lg:table-cell">
                {{ coupon.valid_until === null ? "Unbegrenzt" : formatDate(coupon.valid_until) }}
            </td>
            <td>
                <button
                    class="bg-red p-1 rounded focus:outline-none"
                    @click="$emit('select', coupon)"
                >
                    <img
                        class="w-8"
                        src="/img/icons/pen.png"
                    >
                </button>
            </td>
        </tr>
    </table>
</template>
<script>
import { formatDate, formatPrice, formatNumber } from '@/js/services/helper.service';

const { DateTime } = require('luxon');

export default {
    name: 'CouponTable',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        formatPrice(value) {
            return formatPrice(value);
        },
        formatNumber(value) {
            return formatNumber(value);
        },
        statusText(coupon) {
            if (coupon.valid_until !== null && DateTime.fromISO(coupon.valid_until) < DateTime.now()) return 'Abgelaufen';
            if (coupon.activated_at === null) return 'Verfügbar';
            if (coupon.remaining_value < 0.01) return 'Verbraucht';
            return 'Aktiv';
        },
        statusColor(coupon) {
            if (coupon.valid_until !== null && DateTime.fromISO(coupon.valid_until) < DateTime.now()) return 'bg-red';
            if (coupon.activated_at === null) return 'bg-green';
            if (coupon.remaining_value < 0.01) return 'bg-orange';
            return 'bg-yellow';
        },
        formatType(coupon) {
            return coupon.type === 'HOURS' ? 'Stunden' : 'Guthaben';
        },
        formatValue(coupon) {
            return coupon.type === 'HOURS' ? formatNumber(coupon.value) : formatPrice(coupon.value);
        },
        formatRemainingValue(coupon) {
            if (coupon.activated_at === null || coupon.remaining_value < 0.01) return '';
            return `${coupon.remaining_value} ${this.formatType(coupon)} verfügbar`;
        },
    },
};
</script>
