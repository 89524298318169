<template>
    <div>
        <p class="text-red font-bold text-xl text-center lg:text-left mb-4">
            Einmalige Pauschalpreise
        </p>
        <button
            v-if="rent.status < 5"
            class="flex items-center hover:bg-red hover:bg-opacity-10 focus:outline-none p-1 rounded mb-4"
            @click="showForm = true"
        >
            <div class="bg-red rounded">
                <img
                    class="w-8"
                    src="/img/icons/add.png"
                >
            </div>
            <span class="mx-4 font-bold text-sm">Pauschalpreis hinzufügen</span>
        </button>
        <table
            v-show="data.length > 0"
            class="w-full xl:w-4/5 mx-auto text-center"
        >
            <tr>
                <th>ID</th>
                <th class="hidden md:table-cell">
                    Beschreibung
                </th>
                <th>Betrag</th>
                <th>Datum</th>
                <th v-if="isAdmin" />
            </tr>
            <tr
                v-for="singleCost in data"
                :key="singleCost.id"
            >
                <td>
                    {{ singleCost.id }}
                </td>
                <td class="hidden md:table-cell">
                    {{ singleCost.description || 'Keine Beschreibung angegeben' }}
                </td>
                <td v-html="formatPrice(singleCost.value)" />
                <td>
                    {{ formatDate(singleCost.created_at) }}
                </td>
                <td v-if="isAdmin">
                    <button
                        v-show="singleCost.invoice_id === null"
                        v-tooltip.bottom="'Pauschalpreis löschen'"
                        class="bg-red p-1 rounded focus:outline-none pt-1"
                        @click="deleteRentSingleCost(singleCost.id)"
                    >
                        <svg
                            class="fill-current text-white"
                            height="24.5"
                            version="1.0"
                            viewBox="0 0 64 64"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                            @click="$emit('close')"
                        >
                            <g
                                stroke="none"
                                transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            >
                                <path
                                    d="M107 532 c-15 -15 -27 -32 -27 -37 0 -5 37 -47 82 -92 l83 -83 -83 -83 c-45 -45 -82 -87 -82 -93 0 -13 52 -64 65 -64 5 0 47 37 92 82 l83 83 83 -83 c45 -45 87 -82 92 -82 13 0 65 52 65 65 0 6 -38 48 -84 94 l-84 84 84 80 c46 43 84 85 84 91 0 14 -52 66 -65 66 -5 0 -47 -37 -92 -82 l-83 -83 -83 83 c-45 45 -87 82 -93 82 -6 0 -22 -13 -37 -28z"
                                />
                            </g>
                        </svg>
                    </button>
                </td>
            </tr>
        </table>
        <div
            v-show="data.length === 0"
            class="font-bold text-center lg:text-left"
        >
            Derzeit sind keine Pauschalpreise vorhanden
        </div>
        <rent-single-cost-form
            :rent-id="rent.id"
            :show="showForm"
            @close="showForm = false"
            @create="$emit('create',$event)"
        />
    </div>
</template>
<script>
import { formatDate, formatPrice } from '@/js/services/helper.service';
import RentSingleCostForm from '@/js/components/forms/RentSingleCostForm.vue';
import { deleteRentSingleCost } from '@/js/services/rent.service';

export default {
    name: 'SingleCostTable',
    components: {
        RentSingleCostForm,
    },
    props: {
        rent: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
        isAdmin: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            showForm: false,
        };
    },
    methods: {
        formatDate,
        formatPrice,
        deleteRentSingleCost(rentSingleCostId) {
            deleteRentSingleCost(this.rent.id, rentSingleCostId);

            this.$emit('delete', rentSingleCostId);
        },
    },
};
</script>
