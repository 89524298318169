<template>
    <div>
        <p class="text-red font-bold text-xl text-center lg:text-left">
            Lieferart
        </p>
        <p class="mb-4 text-center lg:text-left">
            Bitte wählen Sie Ihre gewünschte Lieferart aus.
        </p>
        <div class="mx-10 hidden md:block">
            <table class="w-full table-fixed">
                <tr class="hidden lg:table-row">
                    <th width="30" />
                    <th />
                    <th
                        class="font-bold text-right w-1/5"
                    >
                        Gesamtlieferkosten
                    </th>
                </tr>
                <tr
                    v-for="(deliveryType, index) in deliveryTypes"
                    :key="index"
                    @click="updateDeliveryType(deliveryType)"
                >
                    <td width="30">
                        <input
                            v-model="selectedDeliveryType"
                            :value="deliveryType"
                            type="radio"
                        >
                    </td>
                    <td>
                        <!-- eslint-disable vue/no-v-html -->
                        <span
                            class="font-bold"
                            v-html="deliveryType.name"
                        /><br>
                        <!--eslint-enable-->
                        <span v-show="deliveryType.description !== null">{{
                            deliveryType.description
                        }}</span>
                    </td>
                    <td
                        class="text-right w-1/5 font-bold"
                        v-html="formatPrice(deliveryType.price * 2)"
                    />
                </tr>
            </table>
        </div>
        <div class="mx-2 block md:hidden">
            <button
                v-for="(deliveryType, index) in deliveryTypes"
                :key="index"
                :class="[selectedDeliveryType.id === deliveryType.id ? 'border-red border-opacity-30 bg-red bg-opacity-10' : 'border-gray-200 bg-gray-100 bg-opacity-50']"
                class="w-full rounded-lg border p-6 mb-6 focus:outline-none"
                @click="updateDeliveryType(deliveryType)"
            >
                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="text-center font-bold mb-2"
                    v-html="deliveryType.name"
                />
                <!--eslint-enable-->
                <div
                    v-show="deliveryType.description !== null"
                    class="text-justify"
                >
                    {{ deliveryType.description }}
                </div>
                <hr class="my-2">
                <div class="text-center">
                    Gesamtlieferkosten:
                    <p
                        class="font-bold text-xl"
                        v-html="formatPrice(deliveryType.price * 2)"
                    />
                </div>
            </button>
        </div>
    </div>
</template>
<script>
import { formatPrice } from '@/js/services/helper.service';

export default {
    name: 'DeliveryTypeSelection',
    data() {
        return {
            selectedDeliveryType: {},
            deliveryTypes: [
                {
                    id: 0,
                    name: 'Selbstabholung in Kundl',
                    description: null,
                    price: 0,
                },
                {
                    id: 1,
                    name: 'Selbstabholung bei Händler',
                    description: 'Ihr Fahrzeug inkl. Anbaugeräte wird zu einem Händler in Ihrer Nähe geliefert.',
                    price: 150,
                },
                {
                    id: 2,
                    name: 'Individuelle Zustellung',
                    description: 'Ihr Fahrzeug inkl. Anbaugeräte wird an Ihre Adresse bis 400km geliefert und ist direkt einsatzbereit.',
                    price: 400,
                },
            ],
        };
    },
    created() {
        // Set the default value
        this.updateDeliveryType(this.deliveryTypes[0]);
    },
    methods: {
        updateDeliveryType(deliveryType) {
            this.selectedDeliveryType = deliveryType;
            this.$emit('update', this.selectedDeliveryType);
        },
        formatPrice(price) {
            return formatPrice(price);
        },
    },
};
</script>
